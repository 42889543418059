import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
   selector: 'app-okDialog',
   templateUrl: './okDialog.component.html',
   styleUrls: ['./okDialog.component.scss'],
   encapsulation: ViewEncapsulation.None
})
export class OkDialogComponent implements OnInit {
  public isTooltip: boolean = false;
  public message: string;
  public title: string;

  constructor(public dialogRef: MatDialogRef<OkDialogComponent>,
      public sanitizer: DomSanitizer,
      @Inject(MAT_DIALOG_DATA) public data: OkDialogModel) {
      // Update view with given values
      this.title = data.title;
      this.message = data.message;
      this.isTooltip = data.isTooltip;
   }

  public getClass() {
      if (this.isTooltip)
         return "mat-card-tooltip-color popupDimensionOkDialog";
      else
         return "mat-card-color popupDimensionOkDialog";
   }

  public ngOnInit() {
   }

  public onClose(): void {
      // Close the dialog, return true
      this.dialogRef.close(true);
   }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class OkDialogModel {
  constructor(public title: string, public message: string, public isTooltip: boolean) {
   }
}
