import { Injectable, OnInit } from '@angular/core';
import { enumFormAction, enumRoles, enumWorkgroup } from '../enum/Enum';
import { AccessRights } from '../models/system/accessRights';
import { TranslateService } from '@ngx-translate/core';
import { user } from '../models/user/user';
import { StorageService } from 'src/sites/vabourlettis/authentication/storage.service';
import { infoRoleOfUser } from 'src/sites/vabourlettis/models/infoRoleOfUser';
import { SubjectService } from './subject.service';

@Injectable({ providedIn: 'root' })
//-- AccessRights service
export class AccessRightsService implements OnInit  {
   private workgroupOfUserConnected = enumWorkgroup.None;
   public isUserConnected: boolean;
   public isInformationEditable: boolean;
   public _isUserConnectedAdministratorOrSupervisor: boolean;
   public _isUserConnectedAssignedToATeam: boolean;

   constructor(
      private translateService: TranslateService, 
      private subjectService: SubjectService,
      private storageService: StorageService) {
      this.subjectService.UserConnectedSubject.subscribe(x => {
         this.initlialize();
      });

      this.initlialize();
      this.refresh();
   }

   //TODO add others variables in cache
   initlialize() {
      this.isUserConnected = this._isUserConnected();
      this.isInformationEditable = this._isInformationEditable();
 
      //Admin or supervisor
      if (!this.isUserConnected)
         this._isUserConnectedAdministratorOrSupervisor = false;
      else
         this._isUserConnectedAdministratorOrSupervisor = this._getUserConnected().workgroupSysId == enumWorkgroup.Administrator ||  this._getUserConnected().workgroupSysId == enumWorkgroup.Supervisor; //Only admin or supervisor

      //Assigned to a team
      if (this.isUserConnected)
         this._isUserConnectedAssignedToATeam = (this._getUserConnected().team?.teamCategory != "");
      else
         this._isUserConnectedAssignedToATeam = false; 
   }

   ngOnInit(): void {
   }

   public refresh() {
      this.workgroupOfUserConnected = this.getWorkGroupOfUserConnected();
   }

   //-> Local storage   ------------------------------------------------------------------------------------------------
   private _isInformationEditable() {
      return this._isUserConnected() && this.workgroupOfUserConnected != enumWorkgroup.None &&
         this.workgroupOfUserConnected != enumWorkgroup.UserReadOnly;
   }

   private _isUserConnected() : boolean {
      return this._getUserConnected() != null;
   }

   private _getUserConnected() : user {
      return this.storageService.getUserConnected();
   }

   public isUserConnectedAdministrator() : boolean {
      if (!this.isUserConnected)
         return false;
      else
         return this._getUserConnected().workgroupSysId == enumWorkgroup.Administrator; //Only admin
   }

   public isUserConnectedAdministratorOrSupervisor() : boolean {
      return this._isUserConnectedAdministratorOrSupervisor;
   }

   public isUserConnectedSecretary() : boolean {
      if (!this.isUserConnected)
         return false;
      else
      return this.isUserSecretary(this._getUserConnected());
   }

   public isUserConnectedPresident() : boolean {
      return this.getMemberIsPresident(this._getUserConnected());
   }

   public isUserConnectedInComitee() : boolean {
      if (!this.isUserConnected)
         return false;
      else
         return this.isUserInComitee(this._getUserConnected());
   }

   public isUserConnectedAssignedToATeam(): boolean {
      return this._isUserConnectedAssignedToATeam;
   }

   public isUserConnectedInWorkGroups(workGroups: string) {
      const user = this._getUserConnected();
      return workGroups.includes(user.workgroupSysId.toString());
   }

   public isUserRoleAssistantCoach(role) {
      return role == this.translateService.instant(enumRoles[enumRoles.AssistantCoach]);
   }

   public isUserRoleCoach(role) {
      return role == this.translateService.instant(enumRoles[enumRoles.Coach]);
   }

   public isUserRoleCoachOrAssistant(role) {
      return role == this.translateService.instant(enumRoles[enumRoles.Coach]) || role == this.translateService.instant(enumRoles[enumRoles.AssistantCoach]);
   }

   public isUserRoleTeamContact(role) {
      return role == this.translateService.instant(enumRoles[enumRoles.TeamContact]) || role == this.translateService.instant(enumRoles[enumRoles.TeamContact]);
   }

   public isUserRoleTeamDelegate(role) {
      return role == this.translateService.instant(enumRoles[enumRoles.TeamDelegate]);
   }

   public isUserCanManageMeeting(user: user): boolean {
      let temp = this.getOfficialMeetingManagerComiteeMembers();
      let found = false;

      temp.forEach(r => {
         if (user.roleInComity?.includes(r.name) || user.roleInClub?.includes(r.name) || user.roleInClubCustom?.includes(r.name)) {
            found = true;
            return found;
         }
      });
      return found;
   }

   public isUserInComitee(user: user): boolean {
      if (user == null)
         return false;
      else {
         let temp = this.getOfficalComiteeMembers().concat(this.getExtraComiteeMembers());
         let found = false;

         temp.forEach(r => {
            if (user.roleInComity?.includes(r.name) || user.roleInClub?.includes(r.name) || user.roleInClubCustom?.includes(r.name)) {
               found = true;
               return found;
            }
         });
         return found;
      }
   }

   public isUserSecretary(user: user): boolean {
      if (user == null)
         return false;
      else {
         return (user.roleInComity?.includes(this.translateService.instant(enumRoles[enumRoles.Secretary])) || 
            user.roleInClubCustom?.includes(this.translateService.instant(enumRoles[enumRoles.Secretary]))
         );
      }
   }

   
   public getMemberIsAssistantCoach(member: user) {
      if (this.getExtraComiteeMembers().filter(i => i.name == this.translateService.instant(enumRoles[enumRoles.AssistantCoach])) != null)
         return member.roleInClubCustom?.includes(this.getExtraComiteeMembers().FirstOrDefault(i => i.name == this.translateService.instant(enumRoles[enumRoles.AssistantCoach]))?.name);
      else
         return false;
   }

   public getMemberIsManager(member: user) {
      if (this.getExtraComiteeMembers().filter(i => i.name.includes("Responsable")) != null)
         return member.roleInClubCustom?.includes(this.getExtraComiteeMembers().FirstOrDefault(i => i.name.includes("Responsable"))?.name);
      else
         return false;
   }

   public isMemberCoachOnly(member: user) {
      if (member == null)
         return false;

      if (this.getRolesOfMembers().filter(i => i.name == this.translateService.instant(enumRoles[enumRoles.Coach])) != null) {
         return this.getAllRoles(member).includes(this.getRolesOfMembers().FirstOrDefault(i => i.name == this.translateService.instant(enumRoles[enumRoles.Coach]))?.name) &&
            !this.getAllRoles(member).includes(this.getRolesOfMembers().FirstOrDefault(i => i.name == this.translateService.instant(enumRoles[enumRoles.AssistantCoach]))?.name);
      }
      else
         return false;
   }

   public getMemberIsPresident(member: user) {
      if (member == null)
         return false;

      if (this.getOfficalComiteeMembers().filter(i => i.name == this.translateService.instant(enumRoles[enumRoles.President])) != null)
         return ((member.roleInComity ?? "") + ", " + (member.roleInClubCustom ?? "")).includes(this.translateService.instant(enumRoles[enumRoles.President]));
      else
         return false;
   }

   public getMemberIsSecretary(member: user) {
      if (member == null)
         return false;

      if (this.getOfficalComiteeMembers().filter(i => i.name == this.translateService.instant(enumRoles[enumRoles.Secretary])) != null)
         return ((member.roleInComity ?? "") + ", " + (member.roleInClubCustom ?? "")).includes(this.translateService.instant(enumRoles[enumRoles.Secretary]));
      else
         return false;
   }

   public getMemberIsTeamDelegateManager(member: user) {
      if (this.getExtraComiteeMembers().filter(i => i.name == this.translateService.instant(enumRoles[enumRoles.TeamDelegate])) != null)
         return member.roleInClubCustom?.includes(this.getExtraComiteeMembers().FirstOrDefault(i => i.name == this.translateService.instant(enumRoles[enumRoles.TeamDelegate]))?.name);
      else
         return false;
   }

   public getMemberIsTreasurer(member: user) {
      if (member == null)
         return false;

      if (this.getOfficalComiteeMembers().filter(i => i.name == this.translateService.instant(enumRoles[enumRoles.Treasurer])) != null)
         return ((member.roleInComity ?? "") + ", " + (member.roleInClubCustom ?? "")).includes(this.translateService.instant(enumRoles[enumRoles.Treasurer]));
      else
         return false;
   }

   public getMemberIsVicePresident(member: user) {
      if (member == null)
         return false;

      if (this.getOfficalComiteeMembers().filter(i => i.name == this.translateService.instant(enumRoles[enumRoles.VicePresident])) != null) {
         return ((member.roleInComity ?? "") + ", " + (member.roleInClubCustom ?? "")).includes(this.translateService.instant(enumRoles[enumRoles.VicePresident]));
      }
      else
         return false;
   }

   
   public getOfficalComiteeMembers(): infoRoleOfUser[] {
      let info: infoRoleOfUser[] = [];

      info.push(new infoRoleOfUser({ code: enumRoles.President.toString(), name: this.translateService.instant(enumRoles[enumRoles.President]), priority: 10 }));
      info.push(new infoRoleOfUser({ code: enumRoles.VicePresident.toString(), name: this.translateService.instant(enumRoles[enumRoles.VicePresident]), priority: 10 }));
      info.push(new infoRoleOfUser({ code: enumRoles.Secretary.toString(), name: this.translateService.instant(enumRoles[enumRoles.Secretary]), priority: 30 }));

      info.push(new infoRoleOfUser({ code: enumRoles.Treasurer.toString(), name: this.translateService.instant(enumRoles[enumRoles.Treasurer]), priority: 50 }));

      return info; //see portal fvwb
   }

   public getOfficialMeetingManagerComiteeMembers(): infoRoleOfUser[] {
      let info: infoRoleOfUser[] = [];

      info.push(new infoRoleOfUser({ code: enumRoles.President.toString(), name: this.translateService.instant(enumRoles[enumRoles.President]), priority: 10 }));
      info.push(new infoRoleOfUser({ code: enumRoles.VicePresident.toString(), name: this.translateService.instant(enumRoles[enumRoles.VicePresident]), priority: 10 }));
      info.push(new infoRoleOfUser({ code: enumRoles.Secretary.toString(), name: this.translateService.instant(enumRoles[enumRoles.Secretary]), priority: 30 }));

      return info;
   }

   
   public getExtraComiteeMembers(): infoRoleOfUser[] {
      let info: infoRoleOfUser[] = [];

      info.push(new infoRoleOfUser({ code: enumRoles.TeamDelegate.toString(), name: this.translateService.instant(enumRoles[enumRoles.TeamDelegate]), priority: 60 }));
      info.push(new infoRoleOfUser({ code: enumRoles.CateringManager.toString(), name: this.translateService.instant(enumRoles[enumRoles.CateringManager]), priority: 70 }));
      info.push(new infoRoleOfUser({ code: enumRoles.MaterialManager.toString(), name: this.translateService.instant(enumRoles[enumRoles.MaterialManager]), priority: 80 }));
      info.push(new infoRoleOfUser({ code: enumRoles.EquipmentManager.toString(), name: this.translateService.instant(enumRoles[enumRoles.EquipmentManager]), priority: 90 }));
      info.push(new infoRoleOfUser({ code: enumRoles.SponsorShipManager.toString(), name: this.translateService.instant(enumRoles[enumRoles.SponsorShipManager]), priority: 100 }));

      return info; //see portal fvwb
   }

   public getExtraComiteeMembersWithoutTeamDelegate(): infoRoleOfUser[] {
      let info: infoRoleOfUser[] = [];

      info.push(new infoRoleOfUser({ code: enumRoles.CateringManager.toString(), name: this.translateService.instant(enumRoles[enumRoles.CateringManager]), priority: 70 }));
      info.push(new infoRoleOfUser({ code: enumRoles.MaterialManager.toString(), name: this.translateService.instant(enumRoles[enumRoles.MaterialManager]), priority: 80 }));
      info.push(new infoRoleOfUser({ code: enumRoles.EquipmentManager.toString(), name: this.translateService.instant(enumRoles[enumRoles.EquipmentManager]), priority: 90 }));
      info.push(new infoRoleOfUser({ code: enumRoles.SponsorShipManager.toString(), name: this.translateService.instant(enumRoles[enumRoles.SponsorShipManager]), priority: 100 }));

      return info; //see portal fvwb
   }

   public getRolesOfMembers(): infoRoleOfUser[] {
      let info: infoRoleOfUser[] = [];

      info.push(new infoRoleOfUser({ code: enumRoles.VicePresident.toString(), name: this.translateService.instant(enumRoles[enumRoles.VicePresident]), priority: 10 }));

      info.push(new infoRoleOfUser({ code: enumRoles.TeamContact.toString(), name: this.translateService.instant(enumRoles[enumRoles.TeamContact]), priority: 110 }));
      info.push(new infoRoleOfUser({ code: enumRoles.TeamDelegate.toString(), name: this.translateService.instant(enumRoles[enumRoles.TeamDelegate]), priority: 60 }));
      info.push(new infoRoleOfUser({ code: enumRoles.CateringManager.toString(), name: this.translateService.instant(enumRoles[enumRoles.CateringManager]), priority: 70 }));
      info.push(new infoRoleOfUser({ code: enumRoles.MaterialManager.toString(), name: this.translateService.instant(enumRoles[enumRoles.MaterialManager]), priority: 80 }));
      info.push(new infoRoleOfUser({ code: enumRoles.EquipmentManager.toString(), name: this.translateService.instant(enumRoles[enumRoles.EquipmentManager]), priority: 90 }));
      info.push(new infoRoleOfUser({ code: enumRoles.SponsorShipManager.toString(), name: this.translateService.instant(enumRoles[enumRoles.SponsorShipManager]), priority: 100 }));

      info.push(new infoRoleOfUser({ code: enumRoles.Coach.toString(), name: this.translateService.instant(enumRoles[enumRoles.Coach]), priority: 40 }));
      info.push(new infoRoleOfUser({ code: enumRoles.AssistantCoach.toString(), name: this.translateService.instant(enumRoles[enumRoles.AssistantCoach]), priority: 40 }));

      return info;
   }

   public getAllRoles(profile) {
      return (profile.rolhelloeInTeam ?? "") + "," + (profile.roleInClub ?? "") + "," + (profile.roleInClubCustom ?? "");
   }

   public getWorkGroupOfUserConnected() {
      if (this._getUserConnected() != undefined)
      {
         const user = this._getUserConnected();
         if (user == null)
            return enumWorkgroup.None;
         else
            return user.workgroupSysId;
      }
      else 
         return enumWorkgroup.None;
   }


   //<- Local storage   ------------------------------------------------------------------------------------------------

   public getUserIcon(userConnectedSubject): string {
      switch (this.workgroupOfUserConnected) {
         case enumWorkgroup.Supervisor:
            return "supervisor.png";
         case enumWorkgroup.Administrator:
            return "admin.png";
         case enumWorkgroup.UserFullEditSoft:
         case enumWorkgroup.UserFullEdit:
         case enumWorkgroup.UserAddEdit:
         case enumWorkgroup.UserEditOnly:
         case enumWorkgroup.UserReadOnly:
            if (userConnectedSubject.gender != null) {
               if (userConnectedSubject.gender)
                  return "user-male.png";
               else
                  return "user-female.png";
            }
            return "user-male.png";
         case enumWorkgroup.UserReadOnly:
            return "user-male.png"; //TODO (User) : get image for user unknown
      }
   }

   public getUserIconFromMember(user): string {
      switch (user.workgroup.sysId) {
         case enumWorkgroup.Supervisor:
            return "supervisor.png";
         case enumWorkgroup.Administrator:
            return "admin.png";
         case enumWorkgroup.UserFullEditSoft:
         case enumWorkgroup.UserFullEdit:
         case enumWorkgroup.UserAddEdit:
         case enumWorkgroup.UserEditOnly:
         case enumWorkgroup.UserReadOnly:
            if (user.gender != null) {
               if (user.gender)
                  return "user-male.png";
               else
                  return "user-female.png";
            }
            return "user-male.png";
         case enumWorkgroup.UserReadOnly:
            return "user-male.png"; //TODO (User) :  get image for user unknown
      }
   }

   getVisibilityOfColumn(isVisibleProperty: string) {
      const isVisiblePropertyEnum = this.getWorkgroupEnumFromName(isVisibleProperty);
      if (isVisiblePropertyEnum == enumWorkgroup.None)
         return false;
      else
         return (this.workgroupOfUserConnected <= isVisiblePropertyEnum)
   }

   getWorkgroupEnumFromName(value: string): number {
      switch (value) {
         case enumWorkgroup[enumWorkgroup.Supervisor]:
            return enumWorkgroup.Supervisor;
         case enumWorkgroup[enumWorkgroup.Administrator]:
            return enumWorkgroup.Administrator;
         case enumWorkgroup[enumWorkgroup.UserFullEditSoft]:
            return enumWorkgroup.UserFullEditSoft;
         case enumWorkgroup[enumWorkgroup.UserFullEdit]:
            return enumWorkgroup.UserFullEdit;
         case enumWorkgroup[enumWorkgroup.UserAddEdit]:
            return enumWorkgroup.UserAddEdit;
         case enumWorkgroup[enumWorkgroup.UserEditOnly]:
            return enumWorkgroup.UserEditOnly;
         case enumWorkgroup[enumWorkgroup.UserReadOnly]:
            return enumWorkgroup.UserReadOnly;
         case enumWorkgroup[enumWorkgroup.None]:
            return enumWorkgroup.None;
      }
   }

   getLastColumnBasedOnAccessRights(columnSelected: string, rowItems: any, listOfColumnHeader: any, isOnMobile: boolean): boolean {
      //Search last column name visible (visible or not hidden and not null)
      let column: string = "";
      for (const [key, value] of Object.entries(listOfColumnHeader)) {
         for (const [keyR, valueR] of Object.entries(rowItems)) {
            if (valueR !== null && valueR !== "") {
               let resume = true;
               if (value[0] != keyR && isOnMobile) {
                  resume = false;
               }

               if (value[1].grisIsInDetailExpanded && !isOnMobile)
                  resume = false;

               if (resume) {
                  let gridColumnIsVisible = (value[1].gridColumnIsVisible != undefined);
                  if (gridColumnIsVisible) {
                     gridColumnIsVisible = this.getVisibilityOfColumn(value[1].gridColumnIsVisible);
                  }
                  if (gridColumnIsVisible) {
                     column = value[0];
                  }
               }
            }
         }
      }
      return columnSelected == column;
   }

   //Get visibility of CRUD edition command of modal form
   getVisibilityFromAccessRights(menu: enumFormAction): boolean {
      const accessRights = this.getAccessRights();
      switch (menu) {
         case enumFormAction.All: //Used for CRUD menu edition
            return accessRights.newCommand ||
               accessRights.editCommand ||
               accessRights.deleteCommand ||
               accessRights.archiveCommand ||
               accessRights.unarchiveCommand;
         case enumFormAction.Add:
            return accessRights.newCommand;
         case enumFormAction.Edit:
            return accessRights.editCommand;
         case enumFormAction.Delete:
            return accessRights.deleteCommand;
         case enumFormAction.Archive:
            return accessRights.archiveCommand;
         case enumFormAction.Unarchive:
            return accessRights.unarchiveCommand;
      }
   }

   public getAccessRights(): AccessRights {
      switch (this.workgroupOfUserConnected) {
         case enumWorkgroup.Supervisor:
            return new AccessRights({
               newCommand: true,
               editCommand: true,
               deleteCommand: true,
               archiveCommand: true,
               unarchiveCommand: true
            });
         case enumWorkgroup.Administrator:
            return new AccessRights({
               newCommand: true,
               editCommand: true,
               deleteCommand: true,
               archiveCommand: true,
               unarchiveCommand: true
            });
         case enumWorkgroup.UserFullEditSoft:
            return new AccessRights({
               newCommand: true,
               editCommand: true,
               deleteCommand: true,
               archiveCommand: true,
               unarchiveCommand: true
            });
         case enumWorkgroup.UserFullEdit:
            return new AccessRights({
               newCommand: true,
               editCommand: true,
               deleteCommand: true,
               archiveCommand: false,
               unarchiveCommand: false
            });
         case enumWorkgroup.UserAddEdit:
            return new AccessRights({
               newCommand: true,
               editCommand: true,
               deleteCommand: false,
               archiveCommand: false,
               unarchiveCommand: false
            });
         case enumWorkgroup.UserEditOnly:
            return new AccessRights({
               newCommand: false,
               editCommand: true,
               deleteCommand: false,
               archiveCommand: false,
               unarchiveCommand: false
            });
         case enumWorkgroup.UserReadOnly:
            return new AccessRights({
               newCommand: false,
               editCommand: false,
               deleteCommand: false,
               archiveCommand: false,
               unarchiveCommand: false
            });
         case enumWorkgroup.None:
            return new AccessRights({
               newCommand: false,
               editCommand: false,
               deleteCommand: false,
               archiveCommand: false,
               unarchiveCommand: false
            });
      }
   }
}