import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { eventClub } from 'src/sites/vabourlettis/models/eventClub';
import { trainingPlayer } from 'src/sites/vabourlettis/models/trainingPlayer';
import { CacheService } from 'src/sites/vabourlettis/services/cache.service';
import { TrainingPlayerService } from 'src/sites/vabourlettis/services/trainingPlayer.service';
import { enumActivityType, enumAvailability, enumAvailabilityReason, enumClubActivityType, enumMatchDisplayType } from '../../enum/Enum';
import { user } from '../../models/user/user';
import { AccessRightsService } from '../../services/accessRights.service';
import { FrameworkService } from '../../services/framework.service';
import { SystemService } from '../../services/system.service';
import { StorageService } from 'src/sites/vabourlettis/authentication/storage.service';
import { encryptedValue } from 'src/sites/vabourlettis/authentication/encryptedValue';
import { EncryptionService } from '../../services/encryption.service';
import 'linq-typed';
import { SubjectService } from '../../services/subject.service';

@Component({
   selector: 'ms',
   templateUrl: './matchStatus.component.html',
   styleUrls: ['./matchStatus.component.scss']
})
export class MatchStatusComponent implements OnInit, OnChanges {
  @Input() public allClubEventsOfUserAuthenticated = new Map<string, eventClub>();
  @Input() public date: Date;
  @Input() public visible: boolean = true;
  @Output() public OnEditTeamAvailability = new EventEmitter<Date>();

  public EnumAvailability = enumAvailability;
  public EnumAvailabilityReason = enumAvailabilityReason;
  public EnumMatchDisplayType = enumMatchDisplayType;
  public allReasons: any[] = [];
  public eventClub: eventClub = null;
  public form: FormGroup = new FormGroup({});
  public isCoachOnlyConnected: boolean = false;
  public isCoachOrAssistantOrSupervisorConnected: boolean = false;
  public isDisplayOnAMobile: boolean = false;
  public reason: string = "";
  public userConnected: user;

  constructor(
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private frameworkService: FrameworkService,
      public accessRightsService: AccessRightsService,
      private trainingPlayerService: TrainingPlayerService,
      public storageService: StorageService,
      private encryptionService: EncryptionService) {
      
      this.form.addControl("allReasons", new FormControl(''));
   }

  public isATrainingDay() {
      return this.cacheService.getAllTrainingDays().FirstOrDefault(i => i.day == new Date(this.date).getDay()) != null;
   }

  public isAnExtraDay(type: string) {
      return (type == enumActivityType[enumActivityType.FRIENDLY_MATCH_DAY] ||
         type == enumActivityType[enumActivityType.TOURNAMENT_DAY] ||
         type == enumActivityType[enumActivityType.EXTRA_TRAINING_DAY]);
   }

  public ngOnChanges(changes: SimpleChanges) {
      if (changes.date?.currentValue != changes.date?.previousValue) {
         this.date = changes.date?.currentValue;
         //this.frameworkService.logInfoDic(this.allClubEventsOfUserAuthenticated, "this.allClubEventsOfUserAuthenticated before");
         this.eventClub = this.cacheService.getEventClubWithTraining(this.allClubEventsOfUserAuthenticated, this.date);
         //this.frameworkService.logInfoDic(this.allClubEventsOfUserAuthenticated, "this.allClubEventsOfUserAuthenticated after");
         this.form.get('allReasons').setValue(this.eventClub?.reason);
      }
   }

  public ngOnInit() {
      this.subjectService.IsWindowResizingSubject.subscribe(value => { this.isDisplayOnAMobile = value; });
      this.subjectService.IsTrainingPlayerCacheLoadedSubject.subscribe(value => {
         if (value) {
            this.refresh();
         }
      });
      this.refresh();
   }

  public async onChangeToGuard() {
      try {
         let isShouldBeInserted = false;
         let tp = this.cacheService.getAllTrainingPlayersOfCurrentLogged().FirstOrDefault(i => i.sysId == this.eventClub.tpSysId);
         let tpOfCoach = this.cacheService.getExtraTrainingOfCoachFromDate(this.date);

         if (tp == null) {
            isShouldBeInserted = true;
            tp = new trainingPlayer();
            tp.day = this.frameworkService.getDateOnlyFormatDatabase(this.date);
            tp.type = this.eventClub.event.type;
            tp.championshipSysId = this.cacheService.getCurrentChampionship().sysId;
            tp.playerSysId = this.storageService.getUserConnected().sysId;
            tp.championship = null;
            tp.player = null;
            tp.present = null;

            if (!this.isAnExtraDay(tp.type) && !this.isATrainingDay()) {
               // It is a championship or hainault cup
               tp.extraTrainingTimeStarting = this.eventClub.event.time;
            }
            else {
               if (this.isAnExtraDay(tp.type)) {
                  tp.extraTrainingBuilding = tpOfCoach.extraTrainingBuilding;
                  tp.extraTrainingIsAtHome = tpOfCoach.extraTrainingIsAtHome;
                  tp.extraTrainingTeam = tpOfCoach.extraTrainingTeam;
                  tp.extraTrainingTimeStarting = tpOfCoach.extraTrainingTimeStarting;
                  tp.extraTrainingTimeEnding = tpOfCoach.extraTrainingTimeEnding;
                  tp.isPresenceNotCounted = tpOfCoach.isPresenceNotCounted;
                  tp.trainingPlayerSysId = tpOfCoach.sysId;
               }
               if (this.isATrainingDay()) {
                  tp.extraTrainingIsAtHome = true;
                  tp.extraTrainingTimeStarting = this.cacheService.getAllTrainingDays().FirstOrDefault(i => i.day == new Date(this.date).getDay()).timeFrom;
                  tp.extraTrainingTimeEnding = this.cacheService.getAllTrainingDays().FirstOrDefault(i => i.day == new Date(this.date).getDay()).timeTo;
               }
            }
         }
         else
            tp.present = null;

         //this.frameworkService.logInfo(tp, "onChangeToGuard(tp)");
         this.saveNotPresent(this.userConnected, isShouldBeInserted, tp, enumAvailabilityReason[enumAvailabilityReason.GUARD]);
      }
      catch (err) {
         this.subjectService.IsButtonInLoadingSubject.next(false);
      }
   }

  public async onChangeToNotPresent() {
      try {
         let isShouldBeInserted = false;
         //this.frameworkService.logInfo(this.cacheService.getAllTrainingPlayers(), "allTrainingPlayers");
         //this.frameworkService.logInfo(this.cacheService.getAllTrainingPlayersOfCurrentLogged(), "allTrainingPlayersOfUserCoachConnected");
         let tp = this.cacheService.getAllTrainingPlayersOfCurrentLogged().FirstOrDefault(i => i.sysId == this.eventClub.tpSysId);
         let tpOfCoach = this.cacheService.getExtraTrainingOfCoachFromDate(this.date);
         //this.frameworkService.logInfo(tpOfCoach, "tpOfCoach");
         //this.frameworkService.logInfo(this.eventClub, "this.eventClub");
         if (tp == null) {
            //this.frameworkService.logInfo(this.eventClub.tpSysId, "onChangeToNotPresent TP NULL");
            isShouldBeInserted = true;
            tp = new trainingPlayer();
            tp.day = this.frameworkService.getDateOnlyFormatDatabase(this.date);
            tp.type = this.eventClub.event.type;
            tp.championshipSysId = this.cacheService.getCurrentChampionship().sysId;
            tp.playerSysId = this.storageService.getUserConnected().sysId;
            tp.championship = null;
            tp.player = null;
            tp.present = 0;

            if (!this.isAnExtraDay(tp.type) && !this.isATrainingDay()) {
               // It is a championship or hainault cup
               tp.extraTrainingTimeStarting = this.eventClub.event.time;
            }
            else {
               if (this.isAnExtraDay(tp.type)) {
                  tp.extraTrainingBuilding = tpOfCoach.extraTrainingBuilding;
                  tp.extraTrainingIsAtHome = tpOfCoach.extraTrainingIsAtHome;
                  tp.extraTrainingTeam = tpOfCoach.extraTrainingTeam;
                  tp.extraTrainingTimeStarting = tpOfCoach.extraTrainingTimeStarting;
                  tp.extraTrainingTimeEnding = tpOfCoach.extraTrainingTimeEnding;
                  tp.trainingPlayerSysId = tpOfCoach.sysId;
                  tp.isPresenceNotCounted = tpOfCoach.isPresenceNotCounted;
               }
               if (this.isATrainingDay()) {
                  tp.extraTrainingIsAtHome = true;
                  tp.extraTrainingTimeStarting = this.cacheService.getAllTrainingDays().FirstOrDefault(i => i.day == new Date(this.date).getDay()).timeFrom;
                  tp.extraTrainingTimeEnding = this.cacheService.getAllTrainingDays().FirstOrDefault(i => i.day == new Date(this.date).getDay()).timeTo;
               }
            }
         }
         else {
            //this.frameworkService.logInfo(this.eventClub.tpSysId, "onChangeToNotPresent TP NOT NULL");
            tp.present = 0;
         }

         //this.frameworkService.logInfo(tp, "onChangeToNotPresent(tp)");
         this.saveNotPresent(this.userConnected, isShouldBeInserted, tp, this.form.get("allReasons").value);
      }
      catch (err) {
         this.subjectService.IsButtonInLoadingSubject.next(false);
      }
   }

  public async onChangeToPresent() {
      try {
         //resume = this.frameworkService.getDateFormatYyyyMmDdHhMmSs(data[0]) <= this.frameworkService.getDateFormatYyyyMmDd(this.date) + match.time.replace(":", "") + "00";
         let tp = this.cacheService.getAllTrainingPlayersOfCurrentLogged().FirstOrDefault(i => i.sysId == this.eventClub.tpSysId);

         if (tp != null) {
            if (this.isATrainingDay()) {
               tp.extraTrainingIsAtHome = true;
               tp.extraTrainingTimeStarting = this.cacheService.getAllTrainingDays().FirstOrDefault(i => i.day == new Date(this.date).getDay()).timeFrom;
               tp.extraTrainingTimeEnding = this.cacheService.getAllTrainingDays().FirstOrDefault(i => i.day == new Date(this.date).getDay()).timeTo;
            }
            //this.frameworkService.logInfo(tp, "onChangeToPresent(tp)");
            tp.present = 1;
            tp.reason = "";
            this.savePresent(this.userConnected, this.eventClub, tp);
         }
         this.subjectService.IsButtonInLoadingSubject.next(false);
      }
      catch (err) {
         this.subjectService.IsButtonInLoadingSubject.next(false);
      }
   }

  public onTrainingPlayersManager() {
      this.OnEditTeamAvailability.emit(this.date);
   }

  public refresh() {
      this.isCoachOrAssistantOrSupervisorConnected = this.frameworkService.IsMemberCoachs(this.storageService.getUserConnected()) || this.accessRightsService.isUserConnectedAdministratorOrSupervisor();
      this.isCoachOnlyConnected = this.accessRightsService.isMemberCoachOnly(this.storageService.getUserConnected());
      this.userConnected = this.storageService.getUserConnected();
      this.allReasons = this.frameworkService.getNonPresenceReason();
      this.eventClub = this.cacheService.getEventClubWithTraining(this.allClubEventsOfUserAuthenticated, this.date);
      this.form.get('allReasons')?.setValue(this.eventClub?.reason);
   }

  public saveNotPresent(userConnected: user, isShouldBeInserted: boolean, tp: trainingPlayer, reason: string) {
      tp.reason = reason == "" ? enumAvailabilityReason[enumAvailabilityReason.OTHER] : reason;

      //this.frameworkService.logInfo(user.lastName, "saveNotPresent user");
      //this.frameworkService.logInfo(isShouldBeInserted, "saveNotPresent isShouldBeInserted");
      if (isShouldBeInserted)
     
         this.trainingPlayerService.insertWithServerDateCheck(tp, !this.isCoachOrAssistantOrSupervisorConnected).subscribe({
            next: data => {


               let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);
               if (dataDecrypted != null) {
                  if (dataDecrypted.reason == "OUTOFDATE")
                     this.frameworkService.displayAlert(false, "Un événement passé ne peut plus être modifié !");
                  else {
                     this.allClubEventsOfUserAuthenticated = this.cacheService.manageIUClubEventsOfUserAuthenticated(this.date, dataDecrypted, this.eventClub, true, userConnected);
                     this.subjectService.IsTrainingPlayerCacheLoadedSubject.next(true);
                     this.frameworkService.displayAlert(true);
                  }
               }
               else
                  this.frameworkService.displayAlert(false);
               this.subjectService.IsButtonInLoadingSubject.next(false);
            },
            error: err => {
               this.frameworkService.displayAlert(null, err);
               this.subjectService.IsButtonInLoadingSubject.next(false);
            }
         });
      else
         this.trainingPlayerService.updateWithServerDateCheck(tp, !this.isCoachOrAssistantOrSupervisorConnected).subscribe({
            next: data => {
               let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);
               if (dataDecrypted != null) {
                  if (dataDecrypted.reason == "OUTOFDATE")
                     this.frameworkService.displayAlert(false, "Un événement passé ne peut plus être modifié !");
                  else {
                     //this.frameworkService.logInfo(true, "savePresent 3 CALL");

                     this.allClubEventsOfUserAuthenticated = this.cacheService.manageIUClubEventsOfUserAuthenticated(this.date, dataDecrypted, this.eventClub, false, userConnected);
                     this.subjectService.IsTrainingPlayerCacheLoadedSubject.next(true);
                     this.frameworkService.displayAlert(true);
                  }
               }
               else
                  this.frameworkService.displayAlert(false);
               this.subjectService.IsButtonInLoadingSubject.next(false);
            },
            error: err => {
               this.frameworkService.displayAlert(null, err);
               this.subjectService.IsButtonInLoadingSubject.next(false);
            }
         });
   }

  public savePresent(userConnected: user, ec: eventClub, tp: trainingPlayer) {
      //this.frameworkService.logInfo(user.lastName, "savePresent user");

      let isResume = this.isCoachOnlyConnected;

      if (isResume && tp.type != enumClubActivityType[enumClubActivityType.EXTRA_TRAINING_DAY] && tp.type != enumClubActivityType[enumClubActivityType.FRIENDLY_MATCH_DAY] && tp.type != enumClubActivityType[enumClubActivityType.TOURNAMENT_DAY])
      {
         //If it is the coach and it is not a EXTRA_TRAINING_DAY or FRIENDLY_MATCH_DAY or TOURNAMENT_DAY day, delete it
         isResume = false;           
      }

      if (isResume) {
         //If it is the coach, update the TP. Don't delete it
         this.trainingPlayerService.updateWithServerDateCheck(tp, !this.isCoachOrAssistantOrSupervisorConnected).subscribe({
            next: data => {

               let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);
               if (dataDecrypted != null) {
                  if (dataDecrypted.reason == "OUTOFDATE")
                     this.frameworkService.displayAlert(false, "Un événement passé ne peut plus être modifié !");
                  else {
                     let insert: boolean = true;
                     //Check if user is coach, change insert value
                     if (this.accessRightsService.isMemberCoachOnly(userConnected)) {
                        insert = false;
                     }
                     //this.frameworkService.logInfo(insert, "savePresent 1 CALL");

                     this.allClubEventsOfUserAuthenticated = this.cacheService.manageIUClubEventsOfUserAuthenticated(this.date, dataDecrypted, this.eventClub, insert, userConnected);
                     this.subjectService.IsTrainingPlayerCacheLoadedSubject.next(true);
                     this.frameworkService.displayAlert(true);
                  }
               }
               else {
                  this.frameworkService.displayAlert(false);
               }
               this.subjectService.IsButtonInLoadingSubject.next(false);
            },
            error: err => {
               this.frameworkService.displayAlert(null, err);
               this.subjectService.IsButtonInLoadingSubject.next(false);
            }
         });
      }
      else {
         this.trainingPlayerService.deleteWithServerDateCheck(tp.sysId, tp.day, tp.extraTrainingTimeStarting, !this.isCoachOrAssistantOrSupervisorConnected).subscribe({
            next: data => {
               let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);

               if (dataDecrypted != null) {
                  if (dataDecrypted.reason == "OUTOFDATE")
                     this.frameworkService.displayAlert(false, "Un événement passé ne peut plus être modifié !");
                  else {
                     if (ec.tpSysId != 0)
                        this.allClubEventsOfUserAuthenticated = this.cacheService.manageRClubEventsOfUserAuthenticated(tp, userConnected);
                     this.subjectService.IsTrainingPlayerCacheLoadedSubject.next(true);
                     this.frameworkService.displayAlert(true);
                  }
               }
               else {
                  this.frameworkService.displayAlert(false);
               }
               this.subjectService.IsButtonInLoadingSubject.next(false);
            },
            error: err => {
               this.frameworkService.displayAlert(null, err);
               this.subjectService.IsButtonInLoadingSubject.next(false);
            }
         });
      }
   }
}
