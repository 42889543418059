<mat-card class="mat-card-center-95P">

   <mat-card-header>
      <mat-card-title class="matcard-title"><span class="material-icons matcard-title-icon"
            style="margin-left:auto">sports_volleyball</span>
         {{titleDescriptionDisplay}}</mat-card-title>
   </mat-card-header>

   <mat-card-content>
   </mat-card-content>
</mat-card>