import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'zone.js'; //Added for lazy module error in firefox,safari in server.
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


if (environment.production) {
   enableProdMode();

   if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/custom-ngsw-worker.js')
        .then((registration) => {
          console.log('Angular Service Worker registered');
        })
        .catch((error) => {
          console.error('Error registering Angular Service Worker : ', error);
        });
    }
}

function bootstrap() {
   platformBrowserDynamic().bootstrapModule(AppModule)
   .catch(err => console.error(err));
};

if (document.readyState === 'complete') {
   bootstrap();
} else {
   document.addEventListener('DOMContentLoaded', bootstrap);
}

