<form [formGroup]="form">
   <mat-card class="mat-card-color popupDimensionTrainingPlayerEdition">
      <mat-card-header class="mat-card-header-color">
         <mat-card-title>{{title}}</mat-card-title>
      </mat-card-header>
      <mat-card-content class="mat-card-content-padding">
         <div class="flexSpaceBetween">
            <div>Type :
            </div>
            <div><mat-select placeholder="Choisir ..." formControlName="allActivities"
               class="dropDownTPEditionWidth">
               <mat-option *ngFor="let item of allActivities" [value]="item.value">
                  <ng-container>{{item.viewValue}}</ng-container>
               </mat-option>
            </mat-select>
            </div>
         </div>
         <div class="br1" *ngIf="!isAnExtraTrainingDay(this.form.get('allActivities').value)">
         </div>
         <div class="flexSpaceBetween" *ngIf="!isAnExtraTrainingDay(this.form.get('allActivities').value)">
            <div>Contre :
            </div>
            <div><input matInput type="text" [maxLength]="50" formControlName="team"
               class="form-control shadow-none" placeholder="{{'team' | translate}}" />
            </div>
         </div>
         <div class="br1"></div>
         <div class="flexSpaceBetween" *ngIf="!isAnExtraTrainingDay(this.form.get('allActivities').value)">
            <div>A domicile :
            </div>
            <div>  <mat-checkbox formControlName="isAtHome"></mat-checkbox>
            </div>
         </div>
         <div class="flexSpaceBetween">
            <div>Non comptabilisation de présence :
            </div>
            <div>   <mat-checkbox formControlName="isPresenceNotCounted"></mat-checkbox>
            </div>
         </div>
         <div class="br1"></div>
         <div class="flexSpaceBetween">Heure :
            <div>
            <ngx-timepicker-field formControlName="timeStarting" [format]="24" [defaultTime]="'00:00'"
               [confirmBtnTmpl]="okButton" [cancelBtnTmpl]="cancelButton">
            </ngx-timepicker-field>
            <ng-template #okButton>
               <button type="button" mat-raised-button color="primary" [class]="'spanButtonSeparator buttonNormalYes'">OK
               </button>
            </ng-template>
            <ng-template #cancelButton>
               <button type="button" mat-raised-button color="primary"
                  [class]="'spanButtonSeparator buttonNormalNo'">{{'Cancel' |
                  translate}}</button>
            </ng-template>
            </div>
         </div>
         <div class="br1"></div>
         <div class="flexSpaceBetween" *ngIf="isAnExtraTrainingDay(this.form.get('allActivities').value) || 
         isAnTournamentDay(this.form.get('allActivities').value) || 
         isAnFriendlyMatchDay(this.form.get('allActivities').value)">Heure de fin :
            <div>
            <ngx-timepicker-field formControlName="timeEnding" [format]="24" [defaultTime]="'00:00'"
               [confirmBtnTmpl]="okButton" [cancelBtnTmpl]="cancelButton">
            </ngx-timepicker-field>
            <ng-template #okButton>
               <button type="button" mat-raised-button color="primary" [class]="'spanButtonSeparator buttonNormalYes'">OK
               </button>
            </ng-template>
            <ng-template #cancelButton>
               <button type="button" mat-raised-button color="primary"
                  [class]="'spanButtonSeparator buttonNormalNo'">{{'Cancel' |
                  translate}}</button>
            </ng-template>
            </div>
         </div>
         <div class="br1"></div>
         <div class="flexSpaceBetween">
            <div>Lieu :
            </div>
            <div><input matInput type="text" [maxLength]="200" formControlName="trainingBuilding"
               class="form-control shadow-none" style="width: 250px;" placeholder="..." />
            </div>
         </div>
         <div class="br1"></div>
         <div class="flexSpaceBetween">
            <div>
               <app-button icon="cancel" [class]="'spanButtonSeparator buttonNormalNo'" [text]="'Cancel' |
            translate" [isBackgroundOpaque]="true" (onClick)="onCancel()"></app-button>
            </div>
            <div>
               <app-button [visibility]="isDeleteVisible" icon="delete" [class]="'spanButtonSeparator buttonDelete'" [text]="'Delete' |
               translate" [disabled]="form.invalid" isSubmit="true" (onClick)="onDelete()"></app-button>
               <app-button icon="save" [class]="'spanButtonSeparator buttonNormalYes'" [text]="'Save' |
               translate" [disabled]="form.invalid" isSubmit="true" (onClick)="onSave()"></app-button>
            </div>
         </div>
      </mat-card-content>
   </mat-card>
</form>