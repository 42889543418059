<ng-container class="noPrint">
   <div class="container-scroller">
      <app-navbar *ngIf="showNavbar" class="noPrint"></app-navbar>
      <div class="container-fluid page-body-wrapper">
         <app-sidebar *ngIf="showSidebar" class="noPrint"></app-sidebar>
         <div class="main-panel" [ngClass]="{ 'main-panel-only' : !showSidebar }">
            <div class="content-wrapper">
               <div contentAnimate class="mainBackground">
                  <router-outlet></router-outlet>
               </div>
            </div>
            <div>
               <app-footer *ngIf="showFooter" class="noPrint"></app-footer>
            </div>
            <!-- partial -->
         </div>
         <!-- main-panel ends -->
      </div>
      <!-- page-body-wrapper ends -->
   </div>
</ng-container>