<mat-card class="mat-card-center-95P">

   <mat-card-header>
      <mat-card-title class="matcard-title"><span class="material-icons matcard-title-icon"
            style="margin-left:auto">pending_actions</span>
         {{titleDescriptionDisplay}}</mat-card-title>
   </mat-card-header>

   <form [formGroup]="form" [hidden]="true">
      <mat-card>
         <mat-card-content>
            <span>
               {{championshipFilterDisplay}}
               <mat-select formControlName="allchampionship" (selectionChange)="onFilterChange()" style="width:90px">
                  <mat-option *ngFor="let item of filter" [value]="item.value">
                     <ng-container>{{item.viewValue}}</ng-container>
                  </mat-option>
               </mat-select>
            </span>
         </mat-card-content>
      </mat-card>
   </form>

   <mat-card-content>
      <mat-card>
         <mat-card-header>
            <mat-card-subtitle>Le site est actuellement, pour certaines parties, en cours de construction. Par
               conséquent, certaines fonctionnalités ne sont pas encore disponibles mais le seront bientôt ...
            </mat-card-subtitle>
         </mat-card-header>
         <mat-card-content>
         </mat-card-content>
      </mat-card>
   </mat-card-content>
</mat-card>