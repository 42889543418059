<mat-card class="mat-card-center-95P">
   <mat-card-header class="mat-card-header-color noPrint">
      <mat-card-title>
         <div class="flexStart">
            <div>
               <span class="material-icons matcard-title-icon"
               style="margin-left:auto">emoji_events</span>
               <span style="font-size: small">Choix des équipes</span>

            </div>
            <div class="alignmentRight">
               <div style="padding-right:20px">
                  <app-button icon="print" class="spanButtonSeparator noPrint"
                  (onClick)="onPrintRanking()"></app-button>
               </div>
            </div>
         </div>
      </mat-card-title>
   </mat-card-header>
   <mat-card-content>
      <div class="noPrint">
         <mat-slide-toggle [checked]="allToggleTeamAreEnabled"
            (change)="onToggleAllTeams($event)">{{allToggleLabel}}</mat-slide-toggle>
         <span *ngFor="let item of toggleTeamList">
            <mat-slide-toggle [class]="item.class" [checked]="item.enabled"
               (change)="onTeamToggleChanged($event, item)">
               {{ item.name }}
            </mat-slide-toggle>
         </span>
         <div class="br1"></div>
      </div>

      <mat-tab-group>
         <mat-tab label="Classements">
            <div class="card" #tabContentRanking>
               <div class="matcard matcardFloat">
                  <ng-container *ngFor="let team of allTeams;let i = index;" >
                     <section>
                        <app-panel *ngIf="team.isRankingAvailable" [headerClass]="getMatCardHeaderColorResult(team)">
                           <span panel-title>
                              <div class="flexStart">
                                 <div class="printMargin">
                                    <span class="material-icons matcard-title-icon matIconColorInvertedForPrint"
                                       style="margin-left:auto">emoji_events</span>
                                    <span class="printHeader">{{team.teamDisplay}}</span>
                                 </div>
                                 <div class="alignmentRight">
                                    <div style="padding-right:20px">
                                       <span class="dateUpdate">&nbsp;{{getDateReport(getMainRankingOfTeam(team)[0])}}</span>
                                    </div>
                                 </div>
                              </div>
                           </span>
                           <div panel-content>
                              <table class="matTable" *ngIf="getMainRankingOfTeam(team).length > 0">
                                 <tr class="matRow">
                                    <th [class]="'matHeader matHeader' + col" *ngFor="let col of displayedRankingHeaderColumns">
                                       {{col}}</th>
                                 </tr>
                                 <ng-container *ngFor="let row of getMainRankingOfTeam(team)">
                                    <tr>
                                       <td [class]="classForTeamNameOfClub(row, true)"
                                          *ngFor="let col of displayedRankingColumns">
                                          <div style="height:100%"
                                             [innerHtml]="displayColumnRanking(col, row) | safe: 'html'">
                                          </div>
                                       </td>
                                    </tr>
                                 </ng-container>

                                 <tr class="matRow" *ngIf="getReserveRankingOfTeam(team).length > 0">
                                    <th class="matHeader" [attr.colspan]="displayedRankingHeaderColumns.length">
                                       Réserve
                                    </th>
                                 </tr>
                                 <ng-container *ngFor="let row of getReserveRankingOfTeam(team)">
                                    <tr>
                                       <td [class]="classForTeamNameOfClub(row, false)"
                                          *ngFor="let col of displayedRankingColumns">
                                          <div style="height:100%"
                                             [innerHtml]="displayColumnRanking(col, row) | safe: 'html'">
                                          </div>
                                       </td>
                                    </tr>
                                 </ng-container>
                              </table>
                           </div>
                        </app-panel>
                     </section>
                  </ng-container>
               </div>
            </div>
         </mat-tab>
         <mat-tab label="Résultats">
            <div class="card" #tabContentResult>
               <div class="matcard matcardFloat">
                  <section class="breakingSection">
                     <ng-container *ngFor="let team of allTeams;">
                        <section><app-panel *ngIf="getMainRankingOfTeam(team).length > 0 && team.isRankingAvailable"
                              [headerClass]="getMatCardHeaderColorResult(team)">
                              <span panel-title>
                                 <div class="flexStart printMargin">
                                    <div>
                                       <span class="material-icons matcard-title-icon matIconColorInvertedForPrint"
                                          style="margin-left:auto">school</span>
                                       <span class="printHeader"><span class="printHeader">{{team.teamDisplay}}</span></span>
                                    </div>
                                    <div class="alignmentRight noPrint">
                                       <div style="padding-right:20px">
                                          <app-button [icon]="getAllResultsIcon()" [text]="getAllResultsText()"
                                             class="spanButtonSeparator"
                                             (onClick)="onAllResultsClick()"></app-button>
                                       </div>
                                    </div>
                                 </div>
                              </span>
                              <div panel-content>
                                 <table class="matTable">
                                    <tr class="matRow">
                                       <th [class]="'matHeader matHeader' + col" *ngFor="let col of displayedMatchHeaderColumns">
                                          {{col}}
                                       </th>
                                    </tr>
                                    <ng-container *ngFor="let row of getMatchOfTeam(team)">
                                       <tr>
                                          <td [class]="classForMatchOfClub(row, col)"
                                             *ngFor="let col of displayedMatchColumns">
                                             <div style="height:100%"
                                                [innerHtml]="displayColumn(false, col, row) | safe: 'html'">
                                             </div>
                                          </td>
                                       </tr>
                                    </ng-container>
                                 </table>
                              </div>
                           </app-panel>
                        </section>
                     </ng-container>
                  </section>
               </div>
            </div>
         </mat-tab>
         <mat-tab label="(Live)">
            <div class="card">
               <div class="matcard matcardFloat">
                  <section class="breakingSection">
                     <ng-container *ngFor="let team of allTeams;">
                        <section><app-panel *ngIf="getMainRankingOfTeam(team).length > 0 && team.isRankingAvailable"
                              [headerClass]="getMatCardHeaderColorResult(team)">
                              <span panel-title>
                                 <div class="flexStart">
                                    <div>
                                       <span class="material-icons matcard-title-icon matIconColorInvertedForPrint"
                                          style="margin-left:auto">school</span>
                                       <span class="printHeader"><span class="printHeader">{{team.teamDisplay}}</span></span>
                                    </div>
                                 </div>
                              </span>
                              <div panel-content>
                                 <table class="matTable">
                                    <tr class="matRow">
                                       <th class="matHeader" *ngFor="let col of displayedMatchHeaderColumns">
                                          {{col}}
                                       </th>
                                    </tr>
                                    <ng-container *ngFor="let row of getMatchLiveOfTeam(team)">
                                       <tr>
                                          <td [class]="classForMatchOfClub(row, col)"
                                             *ngFor="let col of displayedMatchColumns">
                                             <div style="height:100%"
                                                [innerHtml]="displayColumn(false, col, row) | safe: 'html'">
                                             </div>
                                          </td>
                                       </tr>
                                    </ng-container>
                                 </table>
                              </div>
                           </app-panel>
                        </section>
                     </ng-container>
                  </section>
               </div>
            </div>
         </mat-tab>
         <mat-tab label="A jouer">
            <div class="card">
               <div class="matcard matcardFloat">
                  <section class="breakingSection">
                     <ng-container *ngFor="let team of allTeams;">
                        <section><app-panel *ngIf="getMainRankingOfTeam(team).length > 0 && team.isRankingAvailable"
                              [headerClass]="getMatCardHeaderColorResult(team)">
                              <span panel-title>
                                 <div class="flexStart">
                                    <div>
                                       <span class="material-icons matcard-title-icon matIconColorInvertedForPrint"
                                          style="margin-left:auto">school</span>
                                       <span class="printHeader"><span class="printHeader">{{team.teamDisplay}}</span></span>
                                    </div>
                                 </div>
                              </span>
                              <div panel-content>
                                 <table class="matTable">
                                    <tr class="matRow">
                                       <th class="matHeader" *ngFor="let col of displayedMatchHeaderColumns">
                                          {{col}}
                                       </th>
                                    </tr>
                                    <ng-container *ngFor="let row of getMatchFutureOfTeam(team)">
                                       <tr>
                                          <td [class]="classForMatchOfClub(row, col)"
                                             *ngFor="let col of displayedMatchColumns">
                                             <div style="height:100%"
                                                [innerHtml]="displayColumn(true, col, row) | safe: 'html'">
                                             </div>
                                          </td>
                                       </tr>
                                    </ng-container>
                                 </table>
                              </div>
                           </app-panel>
                        </section>
                     </ng-container>
                  </section>
               </div>
            </div>
         </mat-tab>
      </mat-tab-group>
   </mat-card-content>
</mat-card>