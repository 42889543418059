import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
   selector: 'app-confirmDialog',
   templateUrl: './confirmDialog.component.html',
   styleUrls: ['./confirmDialog.component.scss'],
   encapsulation: ViewEncapsulation.None
})
export class ConfirmDialogComponent implements OnInit {
  public message1: string;
  public message2: string;
  public title: string;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {
      // Update view with given values
      this.title = data.title;
      this.message1 = data.message1;
      this.message2 = data.message2;
   }

  public ngOnInit() {
   }

  public onConfirm(): void {
      // Close the dialog, return true
      this.dialogRef.close(true);
   }

  public onDismiss(): void {
      // Close the dialog, return false
      this.dialogRef.close(false);
   }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConfirmDialogModel {
  constructor(public title: string, public message1: string, public message2: string) {
   }
}
