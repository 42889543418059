import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, retry, throwError } from 'rxjs';

@Injectable()
export class GDPRService {
   constructor(private httpClient: HttpClient) {
   }

   public getGDPR() {
      return this.httpClient.get('./assets/gDPR.html', { responseType: 'text' })
         .pipe(retry(1), catchError(this.handleError));
   }

   // Error handling 
   handleError(error) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
      } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      return throwError(() => errorMessage);
   }
}
