<app-datagrid [datagridParam]="datagridParam"></app-datagrid>

<div class="matcard">
   <mat-card>

      <mat-card-header>
         <mat-card-title>
            Championnat courant {{importChampionshipDisplay}}
         </mat-card-title>
         <mat-card-subtitle>
         </mat-card-subtitle>
      </mat-card-header>

      <mat-card-content>

         <form [formGroup]="form">
            <div class="matcard">
               <mat-card>
                  <mat-card-content>
                     <div>
                        <span>
                           Nouveau championnat courant :
                           <mat-select formControlName="allchampionship" (selectionChange)="onFilterChange()"
                              style="width:90px">
                              <mat-option *ngFor="let item of filter" [value]="item.value">
                                 <ng-container>{{item.viewValue}}</ng-container>
                              </mat-option>
                           </mat-select>
                           <app-button icon="trending_flat" [class]="'spanButtonSeparator'" [text]="'Change' | translate" [isBackgroundOpaque]="true" (onClick)="onChangeChampionship_click()"></app-button>
                        </span>
                     </div>
                     <div>
                        Changer le championnat courant implique la DESACTIVATION de TOUS les MEMBRES du championnat actuellement en cours !!!
                     </div>
                  </mat-card-content>
               </mat-card>
            </div>
         </form>

      </mat-card-content>

      <div class="alertShow">
         <app-alertShow></app-alertShow>
      </div>
   </mat-card>
</div>