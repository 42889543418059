<mat-card style="background-color: rgb(54, 7, 7);">
   <mat-card-header class="mat-card-header-color">
      <mat-card-title>{{title}}
      </mat-card-title>
   </mat-card-header>
   <mat-card-content class="mat-card-content-padding">
      <div mat-dialog-content>
         <div *ngFor="let version of allVersions">
            <div *ngIf="isSeparator(version)" class="br1"></div>
            <div *ngIf="isSeparator(version)" class="versionBorder">{{getFormattedVersion(version)}}</div>
            <div *ngIf="!isSeparator(version) && !isSeparatorLi(version)">
               <div class="br0"></div>
               <div class="section">&nbsp;&nbsp;&nbsp;&nbsp;{{version.value}}</div>
            </div>
            <div *ngIf="isSeparatorLi(version)">
               <li>{{version.value.replace("*","")}}</li>
            </div>
         </div>
      </div>

      <div class="br1"></div>
      <div class="flexSpaceBetween">
         <div>
            <app-button icon="disabled_by_default" [class]="'spanButtonSeparator'" [text]="'Close' | translate" [isBackgroundOpaque]="true" (onClick)="onClose()" [isAlwaysEnabled]="true"></app-button>
         </div>
      </div>

      <mat-card-header class="mat-card-header-color">
         <mat-card-title>Historique des versions
         </mat-card-title>
      </mat-card-header>
      <div *ngIf="!isNetworkStatusOnline">(Connexion à internet requise)</div>
      <ng-container *ngIf="isNetworkStatusOnline" >
      <div *ngFor="let version of allHistoryVersions">
         <div *ngIf="isSeparator(version)" class="br1"></div>
         <div *ngIf="isSeparator(version)" class="versionBorder">{{getFormattedVersion(version)}}</div>
         <div *ngIf="!isSeparator(version) && !isSeparatorLi(version)">
            <div class="br0"></div>
            <div class="section">&nbsp;&nbsp;&nbsp;&nbsp;{{version.value}}</div>
         </div>
         <div *ngIf="isSeparatorLi(version)">
            <li>{{version.value.replace("*","")}}</li>
         </div>
      </div>

      <div class="br1"></div>
      <div class="flexSpaceBetween">
         <div>
            <app-button icon="disabled_by_default" [class]="'spanButtonSeparator'" [text]="'Close' | translate" [isBackgroundOpaque]="true" (onClick)="onClose()" [isAlwaysEnabled]="true"></app-button>
         </div>
      </div>
   </ng-container>
   </mat-card-content>
</mat-card>