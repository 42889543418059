import { FormGroup } from '@angular/forms';
import { FrameworkService } from 'src/sites/core/services/framework.service';

export function StrengthPasswordValidator(controlName: string, frameworkService: FrameworkService) {
   return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      if (frameworkService.getPasswordMeasureStrength(control.value) < 70) {
         control.setErrors({ strengthPassword: true });
      } else {
         control.setErrors(null);
      }
   }
}