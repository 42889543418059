<mat-card class="mat-card-center-95P">

   <mat-card-header>
      <mat-card-title class="matcard-title">
         <div class="material-icons matcard-title-icon" style="margin-left:auto">support</div> Développement
      </mat-card-title>
   </mat-card-header>

   <mat-card-content>
      <div class="mainmatcard">
         <div class="matcard">
            <mat-card>
               <mat-card-header class="mat-card-header-color">
                  <mat-card-title>Nom de domaine vabourlettis.be</mat-card-title>
               </mat-card-header>
               <mat-card-content>
                  <a href="https://www.ovh.com/manager/#/hub/" target="_blank" rel="noopener" class="auth-link">OVH</a>
               </mat-card-content>
            </mat-card>
         </div>

         <!-- https://www.ganatan.com/tutorials/search-engine-optimization-avec-angular -->

         <div class="matcard">
            <mat-card>
               <mat-card-header class="mat-card-header-color">
                  <mat-card-title>Statistiques vabourlettis.be</mat-card-title>
               </mat-card-header>
               <mat-card-content>
                  <a href=" https://search.google.com/search-console?utm_source=about-page&resource_id=https://vabourlettis.be/"
                     target="_blank" rel="noopener" class="auth-link">Google Search Console</a>
               </mat-card-content>
            </mat-card>
         </div>
         <div class="matcard">
            <mat-card>
               <mat-card-header class="mat-card-header-color">
                  <mat-card-title>Liste des rencontres</mat-card-title>
               </mat-card-header>
               <mat-card-content>
                  <div>Les import se font vià le menu "Administration" dans le championnat courant</div>
                  <div>Cet import ne peut être exécuté que si les imports précédents ont été effectués. (Les équipes
                     doivent être présentes en BD)</div>
                  <div class="br1"></div>
                  <div>* Cette fonctionalité permet d'importer les rencontres :</div>
                  <ul>
                     <li>des jeunes (tour 1) "JeunesTour1.xlsx"</li>
                     <li>des jeunes (tour 2) "JeunesTour2.xlsx"</li>
                  </ul>
                  <div class="br1"></div>
                  <div>Remarque : Les données sont effacées intégralement à chaque type d'import</div>
                  <div class="br1"></div>
                  <div>Ces fichiers sont :</div>
                  <ul>
                     <li>
                        <div>"JeunesTour1.xlsx, JeunesTour2.xlsx" : copier/coller du site de
                           l'Archvb</div>
                        <div><img src="../../../../sites/vabourlettis/images/development/JeunesTour12.png"
                              alt="JeunesTour12" style="width:400px;" />

                           <img src="../../../../sites/vabourlettis/images/development/JeunesTour12Copy.png"
                              alt="JeunesTour12Copy" style="width:400px;" />
                        </div>
                     </li>
                  </ul>

               </mat-card-content>
            </mat-card>
         </div>
         <div class="matcard">
            <mat-card>
               <mat-card-header class="mat-card-header-color">
                  <mat-card-title>Mise à jour manuelle des données</mat-card-title>
               </mat-card-header>
               <mat-card-content>
                  <div>Certaines données doivent être mises à jour manuellement</div>
                  <ul>
                     <li>Changement de la saison courante</li>
                     <li>Rôle dans le club (poste de responsables, ...) -> RoleInClubCustom</li>
                     <li>Rôle dans le comité (vice-président, ...) -> RoleInClubCustom</li>
                     <li>Jours, heures d'entraînement, photos, heure de présence par équipe si nouvelle équipe</li>
                     <li>Réunions de comité</li>
                     <li>Matchs amicaux et tournois</li>
                     <li>Les activités</li>
                     <li>Les sponsors</li>
                     <li>Les nouvelles de l'acceuil</li>
                  </ul>
               </mat-card-content>
            </mat-card>
         </div>
         <div class="matcard">
            <mat-card>
               <mat-card-header class="mat-card-header-color">
                  <mat-card-title>Divers</mat-card-title>
               </mat-card-header>
               <mat-card-content>
                  <div>Les info de l'utilisateur logué est rafraichi lors de la mise en cache (localstorage)</div>
                  <div>Les membres "inactifs" (IsUserLoginInactive = true) sont exclus du système de login, présences
                     et changement du mdp</div>
               </mat-card-content>
            </mat-card>
         </div>
         <div class="matcard">
            <mat-card>
               <mat-card-header class="mat-card-header-color">
                  <mat-card-title>TODO</mat-card-title>
               </mat-card-header>
               <mat-card-content>
                  <div>
                     <div *ngFor="let todo of allTodos">
                        <div *ngIf="isSeparator(todo)" class="br1"></div>
                        <div *ngIf="isSeparator(todo)" class="todoBorder">{{todo}}</div>
                        <div *ngIf="!isSeparator(todo) && !isSeparatorLi(todo)">
                           <div class="br0"></div>
                           <div class="section">&nbsp;&nbsp;&nbsp;&nbsp;{{todo.value}}</div>
                        </div>
                        <div *ngIf="isSeparatorLi(todo)">
                           <li>{{todo.value.replace("*","")}}</li>
                        </div>
                     </div>
                  </div>
               </mat-card-content>
            </mat-card>
         </div>
      </div>
   </mat-card-content>
</mat-card>