import { enumDocumentType } from "../../enum/Enum";

//-- Datagrid parameters
export class mailRequest {
    public toEmail : string = "";
    public ccEmail : string = "";
    public bccEmail : string = "";
    public subject : string = "";
    public title : string = "";
    public body : string = "";
    public attachments = [];
    public type : enumDocumentType = enumDocumentType.None;

    public constructor(init?:Partial<mailRequest>) {
      Object.assign(this, init);
    }
}