import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AlertShow, AlertShowType } from '../models/alertshow/alertshow';


@Injectable({ providedIn: 'root' })
//-- AlertShow service
export class AlertShowService {
   private subject = new Subject<AlertShow>();
   private defaultId = 'default-alert';

   // enable subscribing to alerts observable
   onAlert(id = this.defaultId): Observable<AlertShow> {
      return this.subject.asObservable().pipe(filter(x => x && x.id === id));
   }

   // convenience methods
   success(message: string, options?: any) {
      this.alertShow(new AlertShow({ ...options, type: AlertShowType.Success, message }));
   }

   error(message: string, options?: any) {
      this.alertShow(new AlertShow({ ...options, type: AlertShowType.Error, message }));
   }

   info(message: string, options?: any) {
      this.alertShow(new AlertShow({ ...options, type: AlertShowType.Info, message }));
   }

   warn(message: string, options?: any) {
      this.alertShow(new AlertShow({ ...options, type: AlertShowType.Warning, message }));
   }

   // main alert method    
   alertShow(alert: AlertShow) {
      alert.id = alert.id || this.defaultId;
      this.subject.next(alert);
   }

   // clear alerts
   clear(id = this.defaultId) {
      this.subject.next(new AlertShow({ id }));
   }

   saveStatusResetTimeOnSuccess() {
      return 3500;
   }
   saveStatusResetTimeOnFailed() {
      return 3000;
   }
}