import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormParam } from '../../models/system/formParam';
import { AccessRightsService } from '../../services/accessRights.service';
import { Dimensions, ImageCroppedEvent, ImageTransform } from '../imageCrop/interfaces/index';
import { ImageCropComponent } from './imageCrop.component';
import { StorageService } from 'src/sites/vabourlettis/authentication/storage.service';

@Component({
   selector: 'app-imageCropMain',
   templateUrl: './imageCropMain.component.html',
   styleUrls: ['./imageCropMain.component.scss'],
})
export class ImageCropMainComponent {
  public canvasRotation = 0;
  public column: string = "";
  public containWithinAspectRatio = false;
  public croppedImage: any = '';
  public format: string = "jpeg";
  public imageChangedEvent: any = '';
  public imageQuality: string = "60";
  public isDisplayOnAMobile = false;
  public isSaveDialogButtonEnable = false;
  public resizeToWidth: string = "200";
  public rotation = 0;
  public scale = 1;
  public showCropper = false;
  public transform: ImageTransform = {};

  constructor (
      public accessRightsService: AccessRightsService,
      public storageService: StorageService,
      @Inject(MAT_DIALOG_DATA) public data: ImageCropModel,
      @Optional() @Inject(MAT_DIALOG_DATA) public formParam: FormParam,
      @Optional() public dialog: MatDialogRef<ImageCropComponent>) {
      this.column = data.column;
      this.resizeToWidth = data.resizeToWidth;
      this.format = data.format;
      this.imageQuality = data.imageQuality;

      this.getScreenSize();
   }

  @HostListener('window:resize', ['$event'])
   public getScreenSize() {
      this.isDisplayOnAMobile = (window.innerWidth <= 960);
   }

  public cropperReady(sourceImageDimensions: Dimensions) {
   }

  public fileChangeEvent(event: any): void {
      this.imageChangedEvent = event;
      this.isSaveDialogButtonEnable = true;
   }

  public flipAfterRotate() {
      const flippedH = this.transform.flipH;
      const flippedV = this.transform.flipV;
      this.transform = {
         ...this.transform,
         flipH: flippedV,
         flipV: flippedH
      };
   }

  public flipHorizontal() {
      this.transform = {
         ...this.transform,
         flipH: !this.transform.flipH
      };
   }

  public flipVertical() {
      this.transform = {
         ...this.transform,
         flipV: !this.transform.flipV
      };
   }

  public imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
   }

  public imageLoaded() {
      this.showCropper = true;
   }


  public loadImageFailed() {
      this.isSaveDialogButtonEnable = false;
   }

  public onCloseDialog(): void {
      this.dialog.close({ image: null, column: "" });
   }

  public onSaveDialog(): void {
      this.dialog.close({ image: this.croppedImage, column: this.formParam.column });
   }

  public resetImage() {
      this.scale = 1;
      this.rotation = 0;
      this.canvasRotation = 0;
      this.transform = {};
   }

  public rotateLeft() {
      this.canvasRotation--;
      this.flipAfterRotate();
   }

  public rotateRight() {
      this.canvasRotation++;
      this.flipAfterRotate();
   }

  public toggleContainWithinAspectRatio() {
      this.containWithinAspectRatio = !this.containWithinAspectRatio;
   }

  public updateRotation() {
      this.transform = {
         ...this.transform,
         rotate: this.rotation
      };
   }

  public zoomIn() {
      this.scale += .1;
      this.transform = {
         ...this.transform,
         scale: this.scale
      };
   }

  public zoomOut() {
      this.scale -= .1;
      this.transform = {
         ...this.transform,
         scale: this.scale
      };
   }
}

export class ImageCropModel {
  constructor(public column: string, public resizeToWidth : string, public format: string, public imageQuality : string) {
   }
}