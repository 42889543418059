
export class PushNotificationModel {
  public users : number[] = [];
  public championshipSysId : number = null;
  public category : string = "";
  public message : string = "";

  public constructor(init?:Partial<PushNotificationModel>) {
    Object.assign(this, init);
  }
}