<ng-container *ngIf="visible">
    <div class="timelineActivityLabelBadge" *ngIf="!isLabelHidden">{{date}}</div>
    <div class="timelineActivity-badge primary"><span class="material-icons material-icons-medium">{{icon}}</span></div>
    <div [class]="classContent">
        <div *ngIf="!isUserConnected && timelineType != EnumTimelineType.SUPPER" class="timelineActivity-panel-content">
            <div *ngIf="" style="font-size:x-small">(Identification requise)</div>
        </div>
        <div *ngIf="!isUserConnected && timelineType == EnumTimelineType.SUPPER" class="timelineActivity-panel-content">
            <div [class]="class">
            <se [eventClub]="eventClub"
                [isNetworkStatusOnline]="isNetworkStatusOnline"
                [isUserConnected]="isUserConnected"
                [memberPresenceCountActivity]="memberPresenceCountActivity"></se>
            </div>
        </div>
        <div [class]="class">
            <div *ngIf="isUserConnected" class="timelineActivity-panel-content">
                <div *ngIf="timelineType != EnumTimelineType.FULL && 
                timelineType != EnumTimelineType.RESULT && 
                timelineType != EnumTimelineType.SUPPER" 
                    style="height:100%" [innerHtml]="text | safe: 'html'">
                </div>
                <div *ngIf="timelineType == EnumTimelineType.NORMAL">
                    <md [eventClub]="eventClub" [matchDisplayType]="EnumMatchDisplayType.NORMAL"></md>
                </div>
                <div *ngIf="timelineType == EnumTimelineType.FULL">
                    <md [eventClub]="eventClub" [matchDisplayType]="EnumMatchDisplayType.FULL"></md>
                </div>
                <div *ngIf="timelineType == EnumTimelineType.RESULT">
                    <md [eventClub]="eventClub" [matchDisplayType]="EnumMatchDisplayType.RESULT"></md>
                </div>
                <div *ngIf="timelineType == EnumTimelineType.SUPPER"><se [eventClub]="eventClub"
                    [isNetworkStatusOnline]="isNetworkStatusOnline"
                    [isUserConnected]="isUserConnected"
                    [memberPresenceCountActivity]="memberPresenceCountActivity"></se>
                </div>
            </div>
        </div>
    </div>
</ng-container>