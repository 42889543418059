import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import 'linq-typed';
import { timer } from 'rxjs';
import { enumCacheStatusLoad, enumDatagridHeaderOrder } from 'src/sites/core/enum/Enum';
import { datagridParam } from 'src/sites/core/models/system/datagridParam';
import { AlertShowService } from 'src/sites/core/services/alertShow.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { championship } from 'src/sites/vabourlettis/models/championship';
import { ChampionshipService } from '../../../vabourlettis/services/championship.service';
import { CacheService } from '../../services/cache.service';
import { encryptedValue } from '../../authentication/encryptedValue';
import { EncryptionService } from 'src/sites/core/services/encryption.service';
import { SubjectService } from 'src/sites/core/services/subject.service';

@Component({
   selector: 'app-adminChampionship-list',
   templateUrl: './adminChampionship-list.component.html',
   styleUrls: ['./adminChampionship-list.component.scss']
})
export class AdminChampionshipListComponent implements OnInit {
  public allChampionships: championship[];
  public datagridParam: datagridParam<championship>;
  public filter = [];
  public form: FormGroup = new FormGroup({});
  public importChampionshipDisplay: string = "";
  public isLoading: boolean = false;

  constructor(
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private frameworkService: FrameworkService,
      private championshipService: ChampionshipService,
      private alertShowService: AlertShowService,
      private translateService: TranslateService,
      private encryptionService: EncryptionService) {

      this.form.addControl("allchampionship", new FormControl(''));
      this.subjectService.IsDatagridShouldbeRefreshedSubject.subscribe(value => {
         if (this.isLoading) this.refresh();
      }
      );
   }

  public initialize() {
      this.refresh();
   }

  public ngOnInit() {
   this.subjectService.GlobalCacheLoadedStatusSubject.subscribe(value => { if (value != null && value >= enumCacheStatusLoad.loaded) this.initialize(); });
  }

  public onChangeChampionship_click(): void {
      if (this.form.get("allchampionship").value != "") {
         this.championshipService.changeCurrentChampionship(this.form.get("allchampionship").value).subscribe({
            next: data => {
               let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);

               if (dataDecrypted != null) {
                  this.refresh();
                  this.alertShowService.success(this.translateService.instant("MessageDataSavedSuccessfully"));
                  timer(this.frameworkService.saveStatusResetTimeOnSuccess()).subscribe(x => { this.alertShowService.clear() });
                  this.subjectService.IsButtonInLoadingSubject.next(false);
               }
               else {
                  this.alertShowService.error(this.translateService.instant("MessageDataNotSavedDueToAnError"));
                  timer(this.frameworkService.saveStatusResetTimeOnFailed()).subscribe(x => { this.alertShowService.clear() });
                  this.subjectService.IsButtonInLoadingSubject.next(false);
               }
            },
            error: err => {
               this.alertShowService.error(err.mes);
               timer(this.frameworkService.saveStatusResetTimeOnFailed()).subscribe(x => { this.alertShowService.clear() });
               this.subjectService.IsButtonInLoadingSubject.next(false);
            }
         });
      }
      else
         this.subjectService.IsButtonInLoadingSubject.next(false);
   }

  public onFilterChange(): void {
      this.refresh();
   }

  public refresh() {
      this.isLoading = false;

      let currentChampionship = this.cacheService.getAllChampionships().FirstOrDefault(j => j.isCurrent);
      this.importChampionshipDisplay = this.frameworkService.format("{0}-{1}", "yearStarting;yearEnding", currentChampionship);
      this.filter = this.cacheService.getAllChampionships().map(x => (
         {
            value: x.sysId,
            viewValue: this.frameworkService.format("{0}-{1}", "yearStarting;yearEnding", x)
         }));

      // Display the datagrid
      const queryFilters = [];
      this.championshipService.getAllActiveWithoutIncluding(queryFilters).subscribe(
         (data: encryptedValue) => {
            let datasource = this.encryptionService.decryptObject(false, data.value);

            this.datagridParam = new datagridParam(
               {
                  hidden: !this.isLoading,
                  dataSource: datasource,
                  filters: null, //filters
                  mainTitle: "AdminChampionshipListTitle",
                  headerOrder: enumDatagridHeaderOrder.ApiByDefault,
                  entity: new championship(),
                  urlApi: "C1008",
                  paginatorItemsCount: this.frameworkService.getPaginatorItemsCount(),
                  formTitle: "AdminChampionshipListTitle"
               });
            this.isLoading = true;
         }
      );
   }
}
