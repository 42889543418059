<mat-card class="mat-card-color popupDimensionConfirmDialog">
   <mat-card-header>
      <mat-card-title>{{title}}</mat-card-title>
      <mat-card-subtitle></mat-card-subtitle>
   </mat-card-header>
   <mat-card-content class="mat-card-content-padding">
      <div mat-dialog-content>
         <p>{{message1}}</p>
         <p>{{message2}}</p>
      </div>
      <div mat-dialog-actions class="mat-dialogActions">
            <div>
               <app-button icon="clear" [class]="'spanButtonSeparator buttonNormalNo'" [text]="'Non'" [isBackgroundOpaque]="true" (onClick)="onDismiss()" [isAlwaysEnabled]="true"></app-button>
            </div>
            <div>
               <app-button icon="done" [class]="'spanButtonSeparator buttonNormalYes'" [text]="'Oui'" [isBackgroundOpaque]="true" (onClick)="onConfirm()" [isAlwaysEnabled]="true"></app-button>
            </div>
      </div>
   </mat-card-content>
</mat-card>