<app-datagrid [datagridParam]="datagridParam" (datagridFilters)="getDatagridFilters($event)"></app-datagrid>

<mat-card>

   <mat-card-header>
      <mat-card-title>
         {{importDataTitleDisplay}} {{importChampionshipDisplay}}
      </mat-card-title>
      <mat-card-subtitle>{{importDataDescriptionDisplay}}
         <br>
         <a href="https://www.portailfvwb.be/Portail/Calendar" target="_blank" rel="noopener" class="auth-link">{{
            exportFilesFromFvwbPortal }}</a>
      </mat-card-subtitle>
   </mat-card-header>

   <mat-card-content>
      <app-button icon="send" [class]="'spanButtonSeparator'" [text]="'Import' | translate" [isBackgroundOpaque]="true" (onClick)="fileInput.click()"></app-button>
      <input hidden (change)="onFileChange($event)" #fileInput type="file" multiple="false">
   </mat-card-content>

   <div class="alertShow">
      <app-alertShow></app-alertShow>
   </div>
</mat-card>