import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { user } from 'src/sites/core/models/user/user';
import { AccessRightsService } from 'src/sites/core/services/accessRights.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { sportsClub } from '../../models/sportsClub';
import { CacheService } from '../../services/cache.service';
import { enumCacheStatusLoad, enumHRefType, enumPrivacyLevel, enumRoles } from 'src/sites/core/enum/Enum';
import { StorageService } from '../../authentication/storage.service';
import { SubjectService } from 'src/sites/core/services/subject.service';

@Component({
   selector: 'app-contactus',
   templateUrl: './contactus.component.html',
   styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {
   public allMembersByRole;
   public allRoles = [];
   public allRolesOfMembers = [];
   public championshipFilterDisplay: string = "";
   public filter = [];
   public form: FormGroup = new FormGroup({});
   public isNotFirstTime: boolean = false;
   public sportclub: sportsClub;
   public titleDescriptionDisplay: string = "";
   public EnumPrivacyLevel = enumPrivacyLevel;
   public EnumHRefType = enumHRefType;
   public allTeamsFiltered = [];

   constructor(
      private cacheService: CacheService,
      private frameworkService: FrameworkService,
      private translateService: TranslateService,
      public accessRightsService: AccessRightsService,
      public storageService: StorageService,
      private subjectService: SubjectService
   ) {
      this.form.addControl("allchampionship", new FormControl(''));
   }

   public ngOnInit() {
      this.subjectService.GlobalCacheLoadedStatusSubject.subscribe(value => { if (value != null && value >= enumCacheStatusLoad.loaded) this.initialize(); });
   }

   public getAllMembersByRole(): Map<string, object> {
      let list = new Map<string, object>();
      let allUsers = this.cacheService.getAllUsers();
      if (allUsers != null)
         allUsers = allUsers.filter(i => i.sysId != 1 && i.sysId != 2).sort(function (a, b) {
            if (a.firstName + a.lastName < b.firstName + b.lastName) {
               return -1;
            }
            if (a.firstName + a.lastName > b.firstName + b.lastName) {
               return 1;
            }
            return 0;
         });
      allUsers.forEach(u => this.frameworkService.getProfileRoles(u, false, false).forEach(i => {
         let temp = [];
         temp = list.get(i) as user[];
         if (temp == undefined) {
            temp = [];
         }
         temp.push(u);
         list.set(i, temp);
      }
      ));

      return list;
   }

   public getAllUsersOfRole(role): [] {
      if (this.allMembersByRole != null) {
         return this.allMembersByRole.get(role);
      }
      return [];
   }

   public getPhoneFormat(phone: string) {
      return this.frameworkService.phoneFormat(phone);
   }

   public initialize() {
      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });

      this.sportclub = this.cacheService.getSportClub();

      this.refresh();
   }

   public isMemberCoachOnly(profile) {
      return this.accessRightsService.isMemberCoachOnly(profile);
   }

   public isUserRoleAssistantCoach(role) {
      return this.accessRightsService.isUserRoleAssistantCoach(role);
   }

   public isUserRoleCoach(role) {
      return this.accessRightsService.isUserRoleCoach(role);
   }

   public isUserRoleCoachOrAssistant(role) {
      return this.accessRightsService.isUserRoleCoachOrAssistant(role);
   }

   public isUserRoleTeamContact(role) {
      return this.accessRightsService.isUserRoleTeamContact(role);
   }

   public isUserRoleTeamDelegate(role) {
      return this.accessRightsService.isUserRoleTeamDelegate(role);
   }

   public onFilterChange(): void {
      this.refresh();
   }

   public getTeamContactOfTeam(teamSysId: number): user {
      return this.cacheService.getAllUsers().FirstOrDefault(i => i.teamSysId == teamSysId && i.roleInClubCustom?.includes(this.translateService.instant(enumRoles[enumRoles.TeamContact])));
   }

   public refresh() {
      this.allRolesOfMembers = this.frameworkService.getAllRolesOfContacts();
      this.allMembersByRole = this.getAllMembersByRole();

      this.filter = this.cacheService.getAllChampionships().map(x => (
         {
            value: x.sysId,
            viewValue: this.frameworkService.format("{0}-{1}", "yearStarting;yearEnding", x)
         })
      );

      if (!this.isNotFirstTime) {
         let championshipSysId = this.cacheService.getCurrentChampionship()?.sysId;
         this.form.get("allchampionship").setValue(championshipSysId);
         this.isNotFirstTime = true;
      }

      this.allTeamsFiltered = this.cacheService.getAllTeamsWithoutPicture().filter(i => i.division != "" && i.sysId > 1);
   }

   public translatePage() {
      //Championship
      this.translateService.get(['Championship'])
         .subscribe(translations => {
            this.championshipFilterDisplay = translations['Championship'];
         });

      //ContactusTitle
      this.translateService.get(['ContactusTitle'])
         .subscribe(translations => {
            this.titleDescriptionDisplay = translations['ContactusTitle'];
         });
   }
}
