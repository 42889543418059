import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import 'linq-typed';
import { Observable, ObservableInput, catchError, forkJoin, from, throwError, timeout } from 'rxjs';
import { enumCacheStatusLoad, enumHRefType } from 'src/sites/core/enum/Enum';
import { datagridFilterDetail } from 'src/sites/core/models/system/datagridFilter';
import { datagridParam } from 'src/sites/core/models/system/datagridParam';
import { workgroup } from 'src/sites/core/models/workgroup/workgroup';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { match } from '../../models/match';
import { team } from '../../models/team';
import { CacheService } from '../../services/cache.service';
import { SubjectService } from 'src/sites/core/services/subject.service';
import { SystemService } from 'src/sites/core/services/system.service';
import { matchUDTO } from '../../models/matchUDTO';

type AOA = any[][];

@Component({
   selector: 'app-adminImportData',
   templateUrl: './adminImportData.component.html',
   styleUrls: ['./adminImportData.component.scss'],
   providers: [DatePipe]
})

export class AdminImportDataComponent implements OnInit {
   @ViewChild('fileInputChampionship') public fileInputChampionship: ElementRef;
   @ViewChild('fileInputMainRanking') public fileInputMainRanking: ElementRef;
   @ViewChild('fileInputNotChampionship') public fileInputNotChampionship: ElementRef;
   @ViewChild('fileInputReferee') public fileInputReferee: ElementRef;
   @ViewChild('fileInputReserveRanking') public fileInputReserveRanking: ElementRef;
   @ViewChild('fileInputYoung') public fileInputYoung: ElementRef;

   public allMatchsCurrent: match[];
   public allMatchsCurrentCS;
   public allMatchsPreviousCS;
   public allTeamsCurrent: team[];
   public allWorkgroups: workgroup[];
   public championshipNameDisplay: string = "";
   public currentChampionshipSysId = 0;
   public data_import: AOA;
   public datagridParam: datagridParam<match>;
   public dialog: any;
   public exportFilesFromAchvbPortal: string = "";
   public exportFilesFromFvwbPortal: string = "";
   public importChampionshipDisplay: string = "";
   public importDataDescriptionDisplay: string = "";
   public importDataTitleDisplay: string = "";
   public isLoading: boolean = false;
   public previousChampionshipSysId = 0;
   public EnumHRefType = enumHRefType;
   public selectedFiles1: File[] = [];
   public selectedFiles2: File[] = [];
   public selectedFiles3: File[] = [];
   public isSynchronisationRunning: boolean = false;
   public isNetworkStatusOnline: boolean = false;

   constructor(
      public datepipe: DatePipe,
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private systemService: SystemService,
      private frameworkService: FrameworkService,
      private translateService: TranslateService) {
      this.subjectService.IsSynchronisationMembersInProgressSubject.subscribe(value => {
         if (value != null) {
            if (value == 2) {
               this.frameworkService.displayAlert(true, "La synchronisation des membres a bien été effectuée.");
            }
            else {
               if (value == 1) {
                  this.frameworkService.displayAlert(true, "La synchronisation des membres a bien été effectuée avec mises à jour des données.");
                  this.cacheService.cacheUpdateLoad(["user", "team"], true);
               }
               else
                  this.frameworkService.displayAlert(false, "La synchronisation des membres n'a pas été effectuée.");
            }
            this.subjectService.IsButtonInLoadingSubject.next(false);
            this.isSynchronisationRunning = false;
         }
      }
      );
      this.subjectService.IsSynchronisationSeniorMatchsInProgressSubject.subscribe(value => {
         if (value != null) {
            if (value == 2) {
               this.frameworkService.displayAlert(true, "La synchronisation des recontres seniors a bien été effectuée.");
            }
            else {
               if (value == 1) {
                  this.cacheService.cacheUpdateLoad(["match", "ranking"], true);
                  this.frameworkService.displayAlert(true, "La synchronisation des rencontres seniors a bien été effectuée avec mises à jour des données.");
               }
               else
                  this.frameworkService.displayAlert(false, "La synchronisation des rencontres seniors n'a pas été effectuée.");
            }
            this.subjectService.IsButtonInLoadingSubject.next(false);
            this.isSynchronisationRunning = false;
         }
      }
      );
      this.subjectService.IsSynchronisationYoungMatchsInProgressSubject.subscribe(value => {
         if (value != null) {
            if (value == 2) {
               this.frameworkService.displayAlert(true, "La synchronisation des recontres jeunes a bien été effectuée.");
            }
            else {
               if (value == 1) {
                  this.cacheService.cacheUpdateLoad("match", true);
                  this.frameworkService.displayAlert(true, "La synchronisation des rencontres jeunes a bien été effectuée avec mises à jour des données.");
               }
               else
                  this.frameworkService.displayAlert(false, "La synchronisation des rencontres jeunes n'a pas été effectuée.");
            }
            this.subjectService.IsButtonInLoadingSubject.next(false);
            this.isSynchronisationRunning = false;
         }
      }
      );
   }

   public getDateFromYoungExcel(date: string, turn: string): Date {
      if (date != null) {
         let temp1 = date.split(' ');
         if (temp1.length == 2) {
            let temp2 = temp1[1].split('/');
            return new Date(+ temp2[1] + "/" + temp2[0] + "/" + ((turn == "1") ? this.cacheService.getCurrentChampionship().yearStarting : this.cacheService.getCurrentChampionship().yearEnding));
         }
         else
            return null;
      }
      else
         return null;
   }

   public getTeamCategoryFromExcelFieldForCoupe(team1: string, team2: string): number {
      let found = 0;
      let teams = team1 + team2;
      for (let cpt = 0; cpt < this.allTeamsCurrent.length; cpt++) {
         if (teams.trim() != "" && this.allTeamsCurrent[cpt].teamCategory != "" && teams.toUpperCase().includes("(" + this.getTeamCategoryWithoutCategory(this.allTeamsCurrent[cpt].teamCategory.toUpperCase()) + ")")) {
            found = this.allTeamsCurrent[cpt].sysId;
            return found;
         }
      }
      return found;
   }

   public getTeamCategoryFromExcelFieldForYoung(number: string): number {
      let found = 0;
      for (let cpt = 0; cpt < this.allTeamsCurrent.length; cpt++) {
         if (number.trim() != "" && this.allTeamsCurrent[cpt].teamCategory != "" && number.toUpperCase().includes(this.allTeamsCurrent[cpt].teamCategory.toUpperCase())) {
            found = this.allTeamsCurrent[cpt].sysId;
         }
      }
      return found;
   }

   public getTeamCategoryWithoutCategory(category: string): string {
      //P3BM -> P3M
      return this.frameworkService.left(category, 2) + this.frameworkService.right(category, 1);
   }

   public fvwbSynchronize(methods: number) {
      if (methods != 2 && methods != 3 && methods != 4)
         return;

      this.isSynchronisationRunning = true;

      switch (methods) {
         case 2:
            from(this.systemService.fvwbSynchronizeMembers(true)).pipe().subscribe(
               result => { },
               error => { console.log('error fvwbSynchronizeMembers : ' + error); }
            );
            break;
         case 3:
            from(this.systemService.fvwbSynchronizeSeniorMatchs(true)).pipe().subscribe(
               result => { },
               error => { console.log('error fvwbSynchronizeSeniorMatchs : ' + error); }
            );
            break;
         case 4:
            from(this.systemService.fvwbSynchronizeYoungMatchs(true)).pipe().subscribe(
               result => { },
               error => { console.log('error fvwbSynchronizeYoungMatchs : ' + error); }
            );
            break;
      }
   }

   public uploadFiles1(): void {
      if (this.selectedFiles1.length == 1) {
         const formData: FormData = new FormData();

         for (const file of this.selectedFiles1) {
            formData.append('files', file, "membres.xlsx");
         }

         let appServiceArray: Observable<any>[] = [];
         appServiceArray.push(this.systemService.upload(formData));
         forkJoin(appServiceArray).subscribe(res => {
            if (res[0]) {
               this.frameworkService.displayAlert(true, "Le(s) fichier(s) ont bien été transférés.");
            }
            else {
               this.frameworkService.displayAlert(false, "Le(s) fichier(s) n'ont pas pu être transférés.");
            }
            this.removeAllFiles1();
         });
      }
   }

   public uploadFiles2(): void {
      if (this.selectedFiles2.length == 1) {
         const formData: FormData = new FormData();

         for (const file of this.selectedFiles2) {
            formData.append('files', file, "membres2.xlsx");
         }

         let appServiceArray: Observable<any>[] = [];
         appServiceArray.push(this.systemService.upload(formData));
         forkJoin(appServiceArray).subscribe(res => {
            if (res[0]) {
               this.frameworkService.displayAlert(true, "Le(s) fichier(s) ont bien été transférés.");
            }
            else {
               this.frameworkService.displayAlert(false, "Le(s) fichier(s) n'ont pas pu être transférés.");
            }
            this.removeAllFiles2();
         });
      }
   }

   public uploadFiles3(): void {
      if (this.selectedFiles3.length == 1) {
         const formData: FormData = new FormData();

         for (const file of this.selectedFiles3) {
            formData.append('files', file, "u17.xlsx");
         }

         let appServiceArray: Observable<any>[] = [];
         appServiceArray.push(this.systemService.upload(formData));
         forkJoin(appServiceArray).subscribe(res => {
            if (res[0]) {
               this.frameworkService.displayAlert(true, "Le(s) fichier(s) ont bien été transférés.");
            }
            else {
               this.frameworkService.displayAlert(false, "Le(s) fichier(s) n'ont pas pu être transférés.");
            }
            this.removeAllFiles3();
         });
      }
   }

   removeAllFiles1(): void {
      this.selectedFiles1 = [];
      const fileInput = document.getElementById('input-file-id1') as HTMLInputElement;
      if (fileInput) {
         fileInput.value = '';
      }
   }

   removeAllFiles2(): void {
      this.selectedFiles2 = [];
      const fileInput = document.getElementById('input-file-id2') as HTMLInputElement;
      if (fileInput) {
         fileInput.value = '';
      }
   }

   removeAllFiles3(): void {
      this.selectedFiles3 = [];
      const fileInput = document.getElementById('input-file-id3') as HTMLInputElement;
      if (fileInput) {
         fileInput.value = '';
      }
   }

   public initialize() {
      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });

      this.refresh(false);
   }

   public ngOnInit() {
      this.subjectService.GlobalCacheLoadedStatusSubject.subscribe(value => { if (value != null && value >= enumCacheStatusLoad.loaded) this.initialize(); });
      this.subjectService.IsNetworkStatusOnlineSubject.subscribe(value => { this.isNetworkStatusOnline = value; });
   }

   public onFilesSelected1(event: any): void {
      this.selectedFiles1 = event.target.files as File[];
   }

   public onFilesSelected2(event: any): void {
      this.selectedFiles2 = event.target.files as File[];
   }

   public onFilesSelected3(event: any): void {
      this.selectedFiles3 = event.target.files as File[];
   }

   public onExportAll() {
      window.addEventListener('message', function (event) {
         // Check the origin of the message
         if (event.origin === "https://www.portailfvwb.be/Member/Export?sort=LastName-asc~FirstName-asc&filter=&group=&showInactive=false&isInDebtfalse&isSuspended=false") {
            // Do something with the received message
            console.log('Message received in the second window:', event.data);

            // Close the window
            window.close();
         }
      });

      const win = window.open("https://www.portailfvwb.be/Member/Export?sort=LastName-asc~FirstName-asc&filter=&group=&showInactive=false&isInDebtfalse&isSuspended=false", '1');


      setTimeout(() => {
         const win = window.open("https://www.portailfvwb.be/TrainingPlace/Export?sort=Name-asc&filter=&group=", '2');

      }, 1000);
   }

   public exportToExcel() {
      this.frameworkService.exportToExcel(this.cacheService.getAllMatchs().filter(i => i.team.teamCategory == "U17").map(item => 
               new matchUDTO(
                     (item.day == null) ? "" : this.datepipe.transform(item.day, 'dd/MM/yyyy'),
                     item.time,
                     item.fromTeam,
                     item.awayTeam,
                     item.buildingName,
                     item.address,
                     item.zipCode,
                     item.locality,
                     item.score,
                     item.type
                  )), "U17");
      this.subjectService.IsButtonInLoadingSubject.next(false);
   }

   public refresh(reload: boolean) {
      this.isLoading = false;

      if (reload) {
         //TODO JD replace this.cacheService.reloadMatch();
      }

      let currentChampionship = this.cacheService.getAllChampionships().FirstOrDefault(j => j.isCurrent);

      //-> Set filter(s)
      let filterDetail = new datagridFilterDetail();
      filterDetail.dropdownLists = this.cacheService.getAllChampionships().map(x => (
         {
            value: x.sysId,
            viewValue: this.frameworkService.format("{0}-{1}", "yearStarting;yearEnding", x)
         }));

      filterDetail.dropdownSelectedValueOfLists = currentChampionship?.sysId;
      this.currentChampionshipSysId = currentChampionship?.sysId;
      let previousChampionship = this.cacheService.getAllChampionships().FirstOrDefault(i => i.yearStarting == (Number(currentChampionship.yearStarting) - 1).toString());
      if (previousChampionship != null)
         this.previousChampionshipSysId = previousChampionship?.sysId;
      else
         this.previousChampionshipSysId = 0;
      this.importChampionshipDisplay = this.frameworkService.format("{0}-{1}", "yearStarting;yearEnding", currentChampionship);

      // List of all matchs of the current championship
      let self = this;
      this.allMatchsCurrentCS = this.cacheService.getAllMatchs().filter(
         function (record) {
            let value = record;

            const props = "match.championship.sysId".split('.');
            for (let index = 0; index < props.length; index += 1) {
               if (props[index] === undefined) break;
               if (value[props[index]] != null)
                  value = value[props[index]];
            }
            return Number(value) == self.currentChampionshipSysId;
         }
      );

      // List of all matchs of the previous championship
      this.allMatchsPreviousCS = this.cacheService.getAllMatchs().filter(
         function (record) {
            let value = record;

            const props = "match.championship.sysId".split('.');
            for (let index = 0; index < props.length; index += 1) {
               if (props[index] === undefined) break;
               if (value[props[index]] != null)
                  value = value[props[index]];
            }
            return Number(value) == self.previousChampionshipSysId;
         }
      );

      // List of all teams of the current championship
      this.allTeamsCurrent = this.cacheService.getAllTeamsWithoutPicture();

      filterDetail.labelText = this.championshipNameDisplay + " : ";
      filterDetail.name = "championship";
      filterDetail.idName = "match.championship.sysId";

      let filters = new Map<string, datagridFilterDetail>();
      filters.set(filterDetail.name, filterDetail);
      //<- Set filter(s)

      this.isLoading = true;
   }

   public translatePage() {
      //ExportFiles1FromAchvbPortal
      this.translateService.get(['ExportFiles1FromAchvbPortal'])
         .subscribe(translations => {
            this.exportFilesFromAchvbPortal = translations['ExportFiles1FromAchvbPortal'];
         });

      //Championship
      this.translateService.get(['Championship'])
         .subscribe(translations => {
            this.championshipNameDisplay = translations['Championship'];
         });

      //ImportDataTitleDisplay
      this.translateService.get(['ImportDataTitleDisplay'])
         .subscribe(translations => {
            this.importDataTitleDisplay = translations['ImportDataTitleDisplay'];
         });

      //ImportData2DescriptionDisplay
      this.translateService.get(['ImportData3DescriptionDisplay'])
         .subscribe(translations => {
            this.importDataDescriptionDisplay = translations['ImportData3DescriptionDisplay'];
         });
   }
}