<footer class="footer">
   <div class="flexFooter">
      <div class="footerPushLeft" (click)="getHistoryVersion()">©2021 JDax <u>{{ currentApplicationVersionNumber }} {{ currentApplicationLastVersionUpdate }}</u></div>
      <div class="footerPushRight">
         <span>Mise à jour : {{currentDataLastVersionUpdate}}</span> 
      </div>
   </div>
   <div class="flexFooter">
      <div class="footerPushLeft" (click)="getGDPR()"><u>Règles GDPR</u></div>
      <div class="footerPushRight" (click)="getDataPrivacyPopup()"><u>Protection de vos données</u></div>
   </div>
</footer>