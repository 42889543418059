import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { CacheService } from '../../services/cache.service';
import { SubjectService } from 'src/sites/core/services/subject.service';

@Component({
   selector: 'app-buildingTraining',
   templateUrl: './buildingTraining.component.html',
   styleUrls: ['./buildingTraining.component.scss']
})
export class BuildingTrainingComponent implements OnInit {
   public allRoles = [];
   public championshipFilterDisplay: string = "";
   public filter = [];
   public form: FormGroup = new FormGroup({});
   public isNotFirstTime: boolean = false;
   public titleDescriptionDisplay: string = "";

   constructor(
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private frameworkService: FrameworkService,
      private translateService: TranslateService
   ) {
      this.form.addControl("allchampionship", new FormControl(''));
   }

   public initialize() {
      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });

      this.refresh();
   }

   public ngOnInit() {
   }

   public onFilterChange(): void {
      this.refresh();
   }

   public refresh() {
      this.filter = this.cacheService.getAllChampionships().map(x => (
         {
            value: x.sysId,
            viewValue: this.frameworkService.format("{0}-{1}", "yearStarting;yearEnding", x)
         })
      );

      if (!this.isNotFirstTime) {
         let championshipSysId = this.cacheService.getCurrentChampionship().sysId;
         this.form.get("allchampionship").setValue(championshipSysId);
         this.isNotFirstTime = true;
      }
   }

   public translatePage() {
      //Championship
      this.translateService.get(['Championship'])
         .subscribe(translations => {
            this.championshipFilterDisplay = translations['Championship'];
         });

      //BuildingTrainingTitle
      this.translateService.get(['BuildingTrainingTitle'])
         .subscribe(translations => {
            this.titleDescriptionDisplay = translations['BuildingTrainingTitle'];
         });
   }
}
