import { Component } from "@angular/core";

@Component({
   selector: 'app-print',
   templateUrl: './print.component.html',
   styleUrls: ['./print.component.scss']
})
export class PrintComponent {
  constructor() {
   }
}
