import { eventClub } from "./eventClub";

export class eventClubKeyValue {
    eventClub: eventClub;
    date: string
    clubActivityType: string;

    constructor(init?: Partial<eventClubKeyValue>) {
        Object.assign(this, init);
    }
}
