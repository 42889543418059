export class infoPresenceOfUser {
   sysId: Number = 0;
   name: string = "";
   countOfPresenceOnMatch: number = 0;
   countOfPresenceOnTraining: number = 0;
   percentOfPresenceOnMatch: number[] = [];
   percentOfPresenceOnTraining: number[] = [];
   pieChartLabelsForMatch: string[] = [];
   pieChartLabelsForTraining: string[] = [];

   constructor(init?: Partial<infoPresenceOfUser>) {
      Object.assign(this, init);
   }
}
