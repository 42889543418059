export class AlertShow {
    id: string;
    type: AlertShowType;
    message: string;
    autoClose: boolean;
    keepAfterRouteChange: boolean;
    fade: boolean;

    constructor(init?:Partial<AlertShow>) {
        Object.assign(this, init);
    }
}

export enum AlertShowType {
    Success,
    Error,
    Info,
    Warning
}