//This file is autogenerated by C# GeneratedModels

import { jsonIgnore } from "json-ignore";
import { auditFields } from "src/sites/core/models/system/auditFields";
import { metadataModelBase } from "src/sites/core/models/system/metadataModelBase";
import { team } from "src/sites/vabourlettis/models/team";
import { enumControlType, enumFormValidator, enumPrivacyLevel, enumWorkgroup } from "../../enum/Enum";
import { workgroup } from "../workgroup/workgroup";

@Reflect.metadata('userBase', 'userBase')
export class userBase extends auditFields {

   @Reflect.metadata('username',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MinLength": "4",
            "MaxLength": "81"
         },
         editStickyValidator: [enumFormValidator.Required]
      })
   username: string = "";

   @Reflect.metadata('firstName',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "40"
         }
      })
   firstName: string = "";

   @Reflect.metadata('lastName',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "40"
         }
      })
   lastName: string = "";

   @Reflect.metadata('password',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "30"
         },
         editStickyValidator: [enumFormValidator.Required],
         editControlType: enumControlType.Password,
         grisIsInDetailExpanded: true
      })
   password: string = "";

   @Reflect.metadata('email',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "50"
         },
         editControlType: enumControlType.Email,
         grisIsInDetailExpanded: true
      })
   email: string = "";

   @Reflect.metadata('language',
      <metadataModelBase><unknown>{
         editControlType: enumControlType.Dropdown,
         editDropdownStickyValues: [{ value: 'fr', viewValue: 'LanguageFrench' },
         { value: 'nl', viewValue: 'LanguageDutch' },
         { value: 'en', viewValue: 'LanguageEnglish' }],
         editDropdownIsTranslateForDisplay: true,
         grisIsInDetailExpanded: true
      })
   language: string = "";

   @Reflect.metadata('token',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "200"
         },
         grisIsInDetailExpanded: true
      })
   token: string = "";

   @Reflect.metadata('avatar',
      <metadataModelBase><unknown>{
         editControlType: enumControlType.Picture,
         grisIsInDetailExpanded: true
      })
   avatar: string = "";

   @Reflect.metadata('isUserLoginInactive',
      <metadataModelBase><unknown>{
         editControlType: enumControlType.Bool2States
      })
   isUserLoginInactive: boolean = false;

   @Reflect.metadata('accountLoginTries',
      <metadataModelBase><unknown>{
         editControlType: enumControlType.Integer,
         gridColumnIsVisible: enumWorkgroup[enumWorkgroup.None],
         editColumnIsVisible: enumWorkgroup[enumWorkgroup.UserReadOnly]
      })
   accountLoginTries: number = 0;

   @Reflect.metadata('accountSecurityCode',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "10"
         },
         grisIsInDetailExpanded: true,
         gridColumnIsVisible: enumWorkgroup[enumWorkgroup.None],
         editColumnIsVisible: enumWorkgroup[enumWorkgroup.UserReadOnly]
      })
   accountSecurityCode: string = "";

   @Reflect.metadata('accountSecurityCodeAvailability',
      <metadataModelBase><unknown>{
         grisIsInDetailExpanded: true,
         gridColumnIsVisible: enumWorkgroup[enumWorkgroup.None],
         editColumnIsVisible: enumWorkgroup[enumWorkgroup.UserReadOnly]
      })
   accountSecurityCodeAvailability: Date = null;

   @Reflect.metadata('gender',
      <metadataModelBase><unknown>{
         editControlType: enumControlType.Bool3States
      })
   gender?: boolean = null;

   @Reflect.metadata('birthdayDate',
      <metadataModelBase><unknown>{
         editControlType: enumControlType.DateOnly
      })
   birthdayDate?: Date = null;

   @Reflect.metadata('activationTime',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         },
         editControlType: enumControlType.TimeOnly,
         grisIsInDetailExpanded: true
      })
   activationTime: string = "";

   @Reflect.metadata('picturePersonal',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         },
         editControlType: enumControlType.Picture,
         grisIsInDetailExpanded: true
      })
   picturePersonal: string = "";

   @Reflect.metadata('pictureVab',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         },
         editControlType: enumControlType.Picture,
         grisIsInDetailExpanded: true
      })
   pictureVab: string = "";

   @Reflect.metadata('phone1',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "50"
         }
      })
   phone1: string = "";

   @Reflect.metadata('phone2',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "50"
         },
         grisIsInDetailExpanded: true
      })
   phone2: string = "";

   @Reflect.metadata('nationality',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "30"
         },
         grisIsInDetailExpanded: true
      })
   nationality: string = "";

   @Reflect.metadata('address',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "250"
         },
         grisIsInDetailExpanded: true
      })
   address: string = "";

   @Reflect.metadata('zipCode',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "10"
         },
         grisIsInDetailExpanded: true
      })
   zipCode: string = "";

   @Reflect.metadata('locality',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "50"
         },
         grisIsInDetailExpanded: true
      })
   locality: string = "";

   @Reflect.metadata('nickName',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "20"
         },
         grisIsInDetailExpanded: true
      })
   nickName: string = "";

   @Reflect.metadata('jacketNumber',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         },
         grisIsInDetailExpanded: true
      })
   jacketNumber: number = 0;

   @Reflect.metadata('roleInComity',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "100"
         },
         grisIsInDetailExpanded: true
      })
   roleInComity: string = "";

   @Reflect.metadata('roleInClub',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "200"
         },
         grisIsInDetailExpanded: true
      })
   roleInClub: string = "";

   @Reflect.metadata('roleInClubCustom',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "200"
         },
         grisIsInDetailExpanded: true
      })
   roleInClubCustom: string = "";

   @Reflect.metadata('licenceType',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "50"
         },
         grisIsInDetailExpanded: true
      })
   licenceType: string = "";

   @Reflect.metadata('category',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "20"
         },
         grisIsInDetailExpanded: true
      })
   category: string = "";

   @Reflect.metadata('roleInTeam',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "100"
         },
         grisIsInDetailExpanded: true
      })
   roleInTeam: string = "";

   @Reflect.metadata('jacketSize',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "5"
         },
         grisIsInDetailExpanded: true
      })
   jacketSize: string = "";

   @Reflect.metadata('shortsSize',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "5"
         },
         grisIsInDetailExpanded: true
      })
   shortsSize: string = "";

   @Reflect.metadata('licenceNumber',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "10"
         },
         grisIsInDetailExpanded: true
      })
   licenceNumber: string = "";

   @Reflect.metadata('nationalNumber',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "15"
         },
         grisIsInDetailExpanded: true
      })
   nationalNumber: string = "";

   @Reflect.metadata('contribution',
      <metadataModelBase> <unknown> {
        editVariableValidator: {
        } 
      })
      contribution : number = 0;
     
     @Reflect.metadata('isContributionPayed',
      <metadataModelBase> <unknown> {
        editVariableValidator: {
        } 
      })
      isContributionPayed : boolean = false;
     
     @Reflect.metadata('subscriptionEndpoint',
      <metadataModelBase> <unknown> {
        editVariableValidator: {
        "MaxLength": "200"
        } 
      })
      subscriptionEndpoint : string = "";
     
     @Reflect.metadata('subscriptionP256DH',
      <metadataModelBase> <unknown> {
        editVariableValidator: {
        "MaxLength": "200"
        } 
      })
      subscriptionP256DH : string = "";
     
     @Reflect.metadata('subscriptionAuth',
      <metadataModelBase> <unknown> {
        editVariableValidator: {
        "MaxLength": "200"
        } 
      })
      subscriptionAuth : string = "";
     
     @Reflect.metadata('subscriptionCategories',
      <metadataModelBase> <unknown> {
        editVariableValidator: {
        "MaxLength": "1000"
        } 
      })
      subscriptionCategories : string = "";

   @Reflect.metadata('startAffiliate',
      <metadataModelBase><unknown>{
         editControlType: enumControlType.DateOnly,
         grisIsInDetailExpanded: true
      })
   startAffiliate?: Date = null;

   @Reflect.metadata('isNotificationByEmailActivate',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   isNotificationByEmailActivate: boolean = false;

   @Reflect.metadata('isNotificationByPushActivate',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   isNotificationByPushActivate: boolean = false;

   @Reflect.metadata('isNotificationActivityReportByEmailActivate',
   <metadataModelBase><unknown>{
      editVariableValidator: {
      }
   })
   isNotificationActivityReportByEmailActivate: boolean = false;

   @Reflect.metadata('isNotificationMatchByEmailActivate',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   isNotificationMatchByEmailActivate: boolean = false;

   @Reflect.metadata('isNotificationMatchOfClubByEmailActivate',
   <metadataModelBase><unknown>{
      editVariableValidator: {
      }
   })
   isNotificationMatchOfClubByEmailActivate: boolean = false;

   @Reflect.metadata('isNotificationComiteeMeetingByEmailActivate',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   isNotificationComiteeMeetingByEmailActivate: boolean = false;

   @Reflect.metadata('isNotificationAvailabilityByEmailActivate',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   isNotificationAvailabilityByEmailActivate: boolean = false;

   @Reflect.metadata('isNotificationAvailabilityReportByEmailActivate',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   isNotificationAvailabilityReportByEmailActivate: boolean = false;

   @Reflect.metadata('isNotificationAvailabilityForTeamByEmailActivate',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   isNotificationAvailabilityForTeamByEmailActivate: string = "";

   @Reflect.metadata('reminderNotificationAmount',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   reminderNotificationAmount: number = 0;

   @Reflect.metadata('reminderTimeBeforeEvent',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   reminderTimeBeforeEvent: number = 0;

   @Reflect.metadata('emailPrivacyLevel',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   emailPrivacyLevel: number = enumPrivacyLevel.None;

   @Reflect.metadata('addressPrivacyLevel',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   addressPrivacyLevel: number = enumPrivacyLevel.None;

   @Reflect.metadata('phone1PrivacyLevel',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   phone1PrivacyLevel: number = enumPrivacyLevel.None;

   @Reflect.metadata('phone2PrivacyLevel',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   phone2PrivacyLevel: number = enumPrivacyLevel.None;

   @Reflect.metadata('birthdayDatePrivacyLevel',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   birthdayDatePrivacyLevel: number = enumPrivacyLevel.None;

   @Reflect.metadata('workgroupSysId',
      <metadataModelBase><unknown>{
         editControlType: enumControlType.Dropdown,
         editDropdownStickyValues: Object.keys(enumWorkgroup)
            .filter(key => isNaN(Number(key)))
            .map(key => ({ value: enumWorkgroup[key], viewValue: key })),

         editDropdownEntityMapping: "workgroup",
         editDropdownPropertiesForDisplay: "name",
         editDropdownFormatForDisplay: "{0}",
         editDropdownIsTranslateForDisplay: true
      })
   workgroupSysId: number = enumWorkgroup.UserReadOnly;


   @Reflect.metadata('workgroup',
      <metadataModelBase><unknown>{
         editColumnIsVisible: enumWorkgroup[enumWorkgroup.None],
         gridColumnIsVisible: enumWorkgroup[enumWorkgroup.None]
      })
   @jsonIgnore()
   workgroup: workgroup = new workgroup();

   @Reflect.metadata('teamSysId',
      <metadataModelBase><unknown>{
         editControlType: enumControlType.Dropdown,
         editDropdownEntityMapping: "team",
         editDropdownPropertiesForDisplay: "teamCategory",
         editDropdownFormatForDisplay: "{0}"
      })
   teamSysId?: number = null;

   @Reflect.metadata('team',
      <metadataModelBase><unknown>{
         editColumnIsVisible: enumWorkgroup[enumWorkgroup.None],
         gridColumnIsVisible: enumWorkgroup[enumWorkgroup.None]
      })
   @jsonIgnore()
   team: team;

   @Reflect.metadata('team2SysId',
      <metadataModelBase><unknown>{
         editControlType: enumControlType.Dropdown,
         editDropdownEntityMapping: "team2",
         editDropdownPropertiesForDisplay: "teamCategory",
         editDropdownFormatForDisplay: "{0}"
      })
   team2SysId?: number = null;

   @Reflect.metadata('team2',
      <metadataModelBase><unknown>{
         editColumnIsVisible: enumWorkgroup[enumWorkgroup.None],
         gridColumnIsVisible: enumWorkgroup[enumWorkgroup.None]
      })
   @jsonIgnore()
   team2: team;
}