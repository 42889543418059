//This file is autogenerated by C# GeneratedModels

import { jsonIgnore } from "json-ignore";
import { auditFields } from "src/sites/core/models/system/auditFields";
import { metadataModelBase } from "src/sites/core/models/system/metadataModelBase";
import { enumControlType, enumWorkgroup } from "../../core/enum/Enum";
import { championship } from "./championship";
import { team } from "./team";

@Reflect.metadata('matchBase', 'matchBase')
export class matchBase extends auditFields {

   @Reflect.metadata('number',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "20"
         }
      })
   number: string = "";

   @Reflect.metadata('name',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "30"
         }
      })
   name: string = "";

   @Reflect.metadata('type',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "30"
         }
      })
   type: string = "";

   @Reflect.metadata('turn',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "30"
         },
         grisIsInDetailExpanded: true
      })
   turn: string = "";

   @Reflect.metadata('day',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         },
         editControlType: enumControlType.DateOnly
      })
   day: Date = null;

   @Reflect.metadata('time',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "5"
         }
      })
   time: string = "";

   @Reflect.metadata('isAtHome',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         },
         editControlType: enumControlType.Bool3States,
         grisIsInDetailExpanded: true
      })
   isAtHome?: boolean = null;

   @Reflect.metadata('fromTeam',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "50"
         }
      })
   fromTeam: string = "";

   @Reflect.metadata('awayTeam',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "50"
         }
      })
   awayTeam: string = "";

   @Reflect.metadata('score',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "7"
         }
      })
   score: string = "";

   @Reflect.metadata('scoreDetail',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "60"
         }
      })
   scoreDetail: string = "";

   @Reflect.metadata('scoreReserve',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "7"
         },
         grisIsInDetailExpanded: true
      })
   scoreReserve: string = "";

   @Reflect.metadata('status',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "5"
         },
         grisIsInDetailExpanded: true
      })
   status: string = "";

   @Reflect.metadata('statusReserve',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "5"
         },
         grisIsInDetailExpanded: true
      })
   statusReserve: string = "";

   @Reflect.metadata('buildingName',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "100"
         },
         grisIsInDetailExpanded: true
      })
   buildingName: string = "";

   @Reflect.metadata('address',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "250"
         },
         grisIsInDetailExpanded: true
      })
   address: string = "";

   @Reflect.metadata('zipCode',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "10"
         },
         grisIsInDetailExpanded: true
      })
   zipCode: string = "";

   @Reflect.metadata('locality',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "50"
         },
         grisIsInDetailExpanded: true
      })
   locality: string = "";


   @Reflect.metadata('referee1',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "150"
         }
      })
   referee1: string = "";

   @Reflect.metadata('transportCost1',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   transportCost1: number = 0;

   @Reflect.metadata('indemnity1',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   indemnity1: number = 0;

   @Reflect.metadata('referee2',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "150"
         }
      })
   referee2: string = "";

   @Reflect.metadata('transportCost2',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   transportCost2: number = 0;

   @Reflect.metadata('indemnity2',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   indemnity2: number = 0;

   @Reflect.metadata('long',
   <metadataModelBase> <unknown> {
     editVariableValidator: {
     } 
   })
   long : string = "";
 
  @Reflect.metadata('lat',
   <metadataModelBase> <unknown> {
     editVariableValidator: {
     } 
   })
   lat : string = "";

   @Reflect.metadata('teamSysId',
      <metadataModelBase><unknown>{
         editControlType: enumControlType.Dropdown,
         editDropdownEntityMapping: "team",
         editDropdownPropertiesForDisplay: "teamCategory",
         editDropdownFormatForDisplay: "{0}"
      })
   teamSysId?: number = null;


   @Reflect.metadata('team',
      <metadataModelBase><unknown>{
         editColumnIsVisible: enumWorkgroup[enumWorkgroup.None],
         gridColumnIsVisible: enumWorkgroup[enumWorkgroup.None]
      })
   @jsonIgnore()
   team: team;

   @Reflect.metadata('championshipSysId',
      <metadataModelBase><unknown>{
         editControlType: enumControlType.Dropdown,
         editDropdownEntityMapping: "championship",
         editDropdownPropertiesForDisplay: "yearStarting;yearEnding",
         editDropdownFormatForDisplay: "{0}-{1}"
      })
   championshipSysId: number = 0;

   @Reflect.metadata('championship',
      <metadataModelBase><unknown>{
         editColumnIsVisible: enumWorkgroup[enumWorkgroup.None],
         gridColumnIsVisible: enumWorkgroup[enumWorkgroup.None],
         grisIsInDetailExpanded: true
      })
   @jsonIgnore()
   championship: championship;
}