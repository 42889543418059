import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { MessagePopupComponent } from '../components/messagePopup/messagePopup.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
   providedIn: 'root',
})
export class SnackbarService {
   private snackBarRef: MatSnackBarRef<MessagePopupComponent> | null = null;
   private data: dataPopup[] = [];

   constructor(private snackBar: MatSnackBar, private translateService: TranslateService) {
      this.data = [];
   }

   public open(success: boolean | null, message: string) {
      this.processQueue(message, success);
   }

   private processQueue(message: string, success: boolean | null) {
      if (message == null || message == "") {
         if (success)
            message = this.translateService.instant("MessageDataSavedSuccessfully");
         else
            message = this.translateService.instant("MessageDataNotSavedDueToAnError");
      }

      let icon = "done";
      let classColor = "snackbarSuccess";
      let status = "snackbarContainerSuccess";

      if (success == null) {
         icon = "warning";
         classColor = "snackbarWarning";
         status = "snackbarContainerWarning";
      }
      if (success == false) {
         icon = "error";
         classColor = "snackbarError";
         status = "snackbarContainerError";
      }

      let nextData = new dataPopup();
      nextData.message = message;
      nextData.classColor = classColor;
      nextData.icon = icon;

      if (this.snackBarRef) {

         this.data.push(nextData);
         this.snackBarRef.dismiss();

         this.snackBarRef = this.snackBar.openFromComponent(MessagePopupComponent, {
            data: this.data,
            duration: 5000,
            panelClass: "snackbarContainer"
         });
      }
      else {
         this.data = [];
         this.data.push(nextData);
      }

      this.snackBarRef = this.snackBar.openFromComponent(MessagePopupComponent, {
         data: this.data,
         duration: 5000,
         panelClass: "snackbarContainer"
      });
      this.snackBarRef.afterDismissed().subscribe(() => {
         this.snackBarRef = null;
      });
   }
}

export class dataPopup {
   message: string;
   classColor: string;
   icon: string;
}