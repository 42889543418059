<div class="formControl" *ngIf="isFormShown">
   <div class="jdax-panel-round-primary-flat" style="width: 97%;">
      <mat-icon style="padding-left:10px"
         *ngIf="!isDisplayOnAMobile || (isDisplayOnAMobile && !isButtonDisabled && !isOnError)">{{ mdParam.formIcon }}
      </mat-icon>
      <div class="formTitleMargin"
         *ngIf="!isDisplayOnAMobile || (isDisplayOnAMobile && !isButtonDisabled && !isOnError)">
         {{ mdParam.formTitle | translate }}
      </div>
   </div>

   <div class="br1"></div>

   <div mat-dialog-content (scroll)="onScroll($event)">
      <div id="top" #top></div>

      <form [formGroup]="form">
         <ng-container *ngFor="let column of listOfColumnMetadata">
            <div class="container">

               <div class="left">

                  <div class="form-group"
                     style="height: 100% !important; min-height: 100% !important;width: 100% !important">

                     <!-- WITH MAT FORM FIELD -->
                     <mat-form-field appearance="outline" class="matFormField"
                        [style]="isColumnHidden(column[1].editColumnIsVisible) ? 'display:none' : 'display:inline-block'"
                        *ngIf="getControlType(column[1]) != 'Bool2States' &&
                                       getControlType(column[1]) != 'Bool3States'">
                        <mat-label>{{column[0] | translate}}</mat-label>

                        <!-- html -->
                        <input *ngIf="getControlType(column[1]) == 'Html'" matInput type="text"
                           [maxLength]="getMaxLength(column[1].editVariableValidator)" formControlName="{{column[0]}}"
                           class="form-control shadow-none" placeholder="..." />


                        <!-- text -->
                        <input *ngIf="getControlType(column[1]) == 'Text'" matInput type="text"
                           [maxLength]="getMaxLength(column[1].editVariableValidator)" formControlName="{{column[0]}}"
                           class="form-control shadow-none" placeholder="..." />

                        <!--password -->
                        <span *ngIf="getControlType(column[1]) == 'Password'" style="display: flex;">
                           <input matInput placeholder="..." [type]="hidePassword ? 'password' : 'text'"
                              [maxLength]="getMaxLength(column[1].editVariableValidator)"
                              formControlName="{{column[0]}}" class="form-control shadow-none">
                           <mat-icon matSuffix class="passwordIcon" (click)="hidePassword = !hidePassword">
                              {{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </span>

                        <!-- email -->
                        <input *ngIf="getControlType(column[1]) == 'Email'" matInput type="email" email="true"
                           [maxLength]="getMaxLength(column[1].editVariableValidator)" formControlName="{{column[0]}}"
                           class="form-control shadow-none" placeholder="..." />

                        <!-- dateOnly -->
                        <div *ngIf="getControlType(column[1]) == 'DateOnly'" style="display: flex;">
                           <input matInput [matDatepicker]="dp" formControlName="{{column[0]}}" class="shadow-none">
                           <mat-datepicker-toggle matSuffix [for]="dp" style="margin-left:auto">
                              <mat-icon matDatepickerToggleIcon>event</mat-icon>
                           </mat-datepicker-toggle>
                           <mat-datepicker touchUi #dp></mat-datepicker>
                        </div>

                        <!-- timeOnly -->
                        <!--TODO (ModalForm) : replace with ngx-material-timepicker -->
                        <div *ngIf="getControlType(column[1]) == 'TimeOnly'" style="display: flex;height:28px">
                           <input matInput type="time" formControlName="{{column[0]}}" class="timeIcon shadow-none">
                        </div>

                        <!-- phone (Mobile or Office) -->
                        <div
                           *ngIf="getControlType(column[1]) == 'PhoneMobile' || getControlType(column[1]) == 'PhoneOffice'"
                           style="height:28px">
                           <input matInput type="text" [mask]='getParamFromControlType(column[1])'
                              formControlName="{{column[0]}}" class="shadow-none">
                        </div>

                        <!-- dropdown -->
                        <div *ngIf="getControlType(column[1]) == 'Dropdown'" style="height:38px;padding-top:6px">
                           <mat-select placeholder="..." formControlName="{{column[0]}}">
                              <ng-container *ngIf="column[1].editDropdownEntityMapping == ''">
                                 <!-- TODO (ModalForm) : if dropdown is not sticky -> how to retrieve and load dropdown from datagrid values -->
                                 <mat-option *ngFor="let item of column[1].editDropdownStickyValues"
                                    [value]="item.value">
                                    <ng-container *ngIf="column[1].editDropdownIsTranslateForDisplay">{{"Dropdown" +
                                       item.viewValue | translate}}</ng-container>
                                    <ng-container *ngIf="!column[1].editDropdownIsTranslateForDisplay">{{item.value}}
                                    </ng-container>
                                 </mat-option>
                              </ng-container>
                              <ng-container
                                 *ngIf="column[1].editDropdownEntityMapping != '' && column[1].editDropdownStickyValues.length > 0">
                                 <mat-option *ngFor="let item of column[1].editDropdownStickyValues"
                                    [value]="item.value">
                                    <ng-container *ngIf="column[1].editDropdownIsTranslateForDisplay">{{"Dropdown" +
                                       item.viewValue | translate}}</ng-container>
                                    <ng-container *ngIf="!column[1].editDropdownIsTranslateForDisplay">
                                       {{item.viewValue}}</ng-container>
                                 </mat-option>
                              </ng-container>
                              <ng-container
                                 *ngIf="column[1].editDropdownEntityMapping != '' && column[1].editDropdownStickyValues.length == 0">
                                 <mat-option
                                    *ngFor="let item of editDropdownDatabaseValues.get(column[1].editDropdownEntityMapping)"
                                    [value]="item.value">
                                    <ng-container>{{item.viewValue}}</ng-container>
                                 </mat-option>
                              </ng-container>
                           </mat-select>
                        </div>

                        <!-- textarea -->
                        <div *ngIf="getControlType(column[1]) == 'Textarea'">
                           <textarea matInput placeholder="..." rows="10" formControlName="{{column[0]}}"
                              class="form-control shadow-none"></textarea>
                        </div>

                        <!-- numeric -->
                        <div *ngIf="getControlType(column[1]) == 'Integer'">
                           <input matInput type="number" placeholder="..."
                              oninput="this.value = this.value.replace('.',',').replace('/^d+(.d{1,2})?$/', '');"
                              formControlName="{{column[0]}}" class="form-control shadow-none">
                        </div>

                        <!-- decimal -->
                        <!-- TODO (ModalForm) : this accept +- at the end -> should be fixed -->
                        <div *ngIf="getControlType(column[1]) == 'Decimal'">
                           <input matInput type="number" placeholder="..."
                              onkeypress='if (event.key === "." || event.key === "e" || event.key === "+") { return false; }'
                              formControlName="{{column[0]}}" class="form-control shadow-none">
                        </div>

                        <div *ngIf="getControlType(column[1]) == 'TextAllToUpperCase'">
                           <input matInput type="text" placeholder="..." onkeyup="this.value = this.value.toUpperCase()"
                              formControlName="{{column[0]}}" class="form-control shadow-none">
                        </div>

                        <div *ngIf="getControlType(column[1]) == 'TextAllToLowerCase'">
                           <input matInput type="text" placeholder="..." onkeyup="this.value = this.value.toLowerCase()"
                              formControlName="{{column[0]}}" class="form-control shadow-none">
                        </div>

                        <div *ngIf="getControlType(column[1]) == 'Text1CharUpperCase'">
                           <input matInput type="text" placeholder="..."
                              onkeyup="this.value = this.value.charAt(0).toUpperCase() + this.value.substring(0, 1);"
                              formControlName="{{column[0]}}" class="form-control shadow-none">
                        </div>

                        <div *ngIf="getControlType(column[1]) == 'LetterLowerCaseOnly'">
                           <input matInput type="text" placeholder="..."
                              onkeyup="this.value = this.value.replace(/[^a-zA-Z]/g,'').toLowerCase()"
                              formControlName="{{column[0]}}" class="form-control shadow-none">
                        </div>

                        <div *ngIf="getControlType(column[1]) == 'LetterUpperCaseOnly'">
                           <input matInput type="text" placeholder="..."
                              onkeyup="this.value = this.value.replace(/[^a-zA-Z]/g,'').toUpperCase()"
                              formControlName="{{column[0]}}" class="form-control shadow-none">
                        </div>

                        <div *ngIf="getControlType(column[1]) == 'Letter1CharUpperCase'">
                           <input matInput type="text" placeholder="..."
                              onkeyup="this.value = (this.value.replace(/[^a-zA-Z]/g,'')).charAt(0).toUpperCase() + (this.value.replace(/[^a-zA-Z]/g,'')).substring(0, 1)"
                              formControlName="{{column[0]}}" class="form-control shadow-none">
                        </div>

                        <!-- PICTURE -->
                        <div *ngIf="getControlType(column[1]) == 'Picture'">
                           <div class="picture">
                              <img [src]="getImgURL(column[0])" alt="Photo" loading="lazy" width="50"
                                 *ngIf="getImgURL(column[0])">
                              <mat-icon class="pictureIcon" (click)="showImageCropPopup(column[0])">photo</mat-icon>
                           </div>

                           <input matInput formControlName="{{column[0]}}" hidden="true" />
                           <!-- Fetch selected filed on change -->
                           <!-- <input type="file" (change)="uploadFile($event,column[0])" class="uploadFile" multiple="multiple" accept="image/*" /> -->
                        </div>

                        <!-- TODO (ModalForm) : Add check rules for dateOnly -->
                        <small class="text-danger" *ngIf="checkIsDecimal(column[0])">
                           {{'FormFieldIsDecimal' | translate }}
                        </small>
                        <small class="text-danger" *ngIf="checkIsEmail(column[0])">
                           {{'FormFieldIsEmailError' | translate }}
                        </small>
                        <small class="text-danger" *ngIf="checkMinLength(column[0])">
                           {{'FormMinLengthOfFieldValidationError' | translate : { value:
                           form.get(column[0]).errors?.minlength.requiredLength } }}
                        </small>
                        <small class="text-danger" *ngIf="checkMaxLength(column[0])">
                           {{'FormMaxLengthOfFieldValidationError' | translate : { value:
                           form.get(column[0]).errors?.maxlength.requiredLength } }}
                        </small>
                        <small class="text-danger" *ngIf="checkIsRequired(column[0])">
                           {{'FormFieldIsRequiredValidationError' | translate }}
                        </small>

                     </mat-form-field>
                     <!-- WITH MAT FORM FIELD -->



                     <!-- WITHOUT MAT FORM FIELD -->
                     <div
                        *ngIf="getControlType(column[1]) == 'Bool2States' || getControlType(column[1]) == 'Bool3States'"
                        [style]="isColumnHidden(column[1].editColumnIsVisible) ? 'display:none' : ''">
                        <!-- Bool2States and Bool3States -->
                        <span class="checkboxSpan">
                           <mat-checkbox *ngIf="getControlType(column[1]) == 'Bool3States'"
                              formControlName="{{column[0]}}" [ngModel]="form.get(column[0]).value"
                              [indeterminate]="form.get(column[0]).value === null"></mat-checkbox>
                           <mat-checkbox *ngIf="getControlType(column[1]) == 'Bool2States'"
                              formControlName="{{column[0]}}" [ngModel]="form.get(column[0]).value"></mat-checkbox>
                           <mat-label style="margin-left:15px">{{column[0] | translate}}</mat-label>
                           <mat-icon *ngIf="getControlType(column[1]) == 'Bool3States'"
                              (click)="setIndeterminate(column[0])" class="checkboxIcon">clear</mat-icon>

                           <!-- Used for radiobutton (inactive)
                                    <input type="radio" value="true" formControlName="{{column[0]}}" [ngModel]="getCheckedValue(column[0])"> Male
                                    <input type="radio" value="false" formControlName="{{column[0]}}" [ngModel]="getCheckedValue(column[0])"> Female
                                    <input type="radio" value="null" formControlName="{{column[0]}}" [ngModel]="getCheckedValue(column[0])"> None -->
                        </span>
                     </div>

                     <div *ngIf="getControlType(column[1]) == 'Bool2States' || 
                                        getControlType(column[1]) == 'Bool3States'"
                        [style]="isColumnHidden(column[1].editColumnIsVisible) ? 'display:none' : ''">
                        <small class="text-danger" *ngIf="checkMinLength(column[0])">
                           {{'FormMinLengthOfFieldValidationError' | translate : { value:
                           form.get(column[0]).errors?.minlength.requiredLength } }}
                        </small>
                        <small class="text-danger" *ngIf="checkMaxLength(column[0])">
                           {{'FormMaxLengthOfFieldValidationError' | translate : { value:
                           form.get(column[0]).errors?.maxlength.requiredLength } }}
                        </small>
                        <small class="text-danger" *ngIf="checkIsRequired(column[0])">
                           {{'FormFieldIsRequiredValidationError' | translate }}
                        </small>
                     </div>
                     <!-- WITHOUT MAT FORM FIELD -->
                  </div>

               </div>

               <div class="right" *ngIf="!isFormActionDelete()">
                  <mat-icon (click)="resetPicture(column[0])" class="clearIcon"
                     [style]="isColumnHidden(column[1].editColumnIsVisible) ? 'display:none' : 'display:inline-block'">highlight_off
                  </mat-icon>
               </div>

            </div>
         </ng-container>
      </form>
   </div>

   <div class="alertShow" *ngIf="!isDisplayOnAMobile">
      <app-alertShow></app-alertShow>
   </div>
   <div class="flexEnd buttonPadding">
      <div id="scrollTop">
         <app-button [visibility]="isScrollTopArrowShow && !isButtonDisabled" icon="arrow_upward" [class]="'spanButtonSeparator scrollTop'" (onClick)="scrollToElement(top)"></app-button>
      </div>
      <div *ngIf="!isButtonDisabled">
         <app-button icon="cancel" [class]="'spanButtonSeparator buttonNormalNo'" [text]="'Cancel' |
         translate" [disabled]="isSaveDisabled" [isBackgroundOpaque]="true" (onClick)="onCancelDialog()"></app-button>
      </div>
      <div *ngIf="!isButtonDisabled">
         <app-button [visibility]="isFormActionDelete()" icon="delete" [class]="'spanButtonSeparator buttonDelete'" [text]="'Delete' |
         translate" [disabled]="form.invalid" (onClick)="onSaveDialog($event)"></app-button>
         <app-button [visibility]="!isFormActionDelete()" icon="save" [class]="'spanButtonSeparator buttonNormalYes'" [text]="'Save' |
         translate" [disabled]="form.invalid || isSaveDisabled" (onClick)="onSaveDialog($event)"></app-button>
      </div>
   </div>
</div>