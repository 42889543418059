import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { PwaComponent } from '../components/pwa/pwa.component';

@Injectable({
   providedIn: 'root'
})
export class PwaService {
   private promptEvent: any;

   constructor(
      private bottomSheet: MatBottomSheet,
      private platform: Platform
   ) { }

   initPwaPrompt() {
      if (this.platform.ANDROID) {
         window.addEventListener('beforeinstallprompt', (event: any) => {
            event.preventDefault();
            this.promptEvent = event;
            this.openPromptComponent('android');
         });
      }
      if (this.platform.IOS) {
         const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator['standalone']);
         if (!isInStandaloneMode) {
            this.openPromptComponent('ios');
         }
      }
   }

   openPromptComponent(mobileType: 'ios' | 'android') {
      timer(3000)
         .pipe(take(1))
         .subscribe(() => {
            this.bottomSheet.open(PwaComponent, { data: { mobileType, promptEvent: this.promptEvent } });
         });
   }
}