<form [formGroup]="form">
   <div>
      <div>
         <mat-card class="mat-card-center-95P">
            <mat-card-header class="mat-card-header-color">
               <mat-card-title>
                  <div class="flexStart">
                     <div>
                        <span
                           class="material-icons matcard-title-icon">event_note</span>&nbsp;{{titleDescriptionDisplay}}&nbsp;
                     </div>
                     <div class="alignmentRight" style="padding-right:20px">
                        <span *ngIf="selectedDate != null">
                           <app-button icon="picture_as_pdf" [visibility]="isComiteeMeetingAvailable"
                              [isBackgroundOpaque]="false" [text]="''" (onClick)="onMeetingEdition(false)"></app-button>
                        </span>
                        <span *ngIf="selectedDate != null">
                           <app-button icon="date_range" [isBackgroundOpaque]="false" [text]="''"
                              [visibility]="isManagerComiteeMeetingAvailable()"
                              (onClick)="onMeetingEdition(true)"></app-button>
                        </span>
                     </div>
                  </div>
               </mat-card-title>
            </mat-card-header>
            <mat-card-content>
               <div class="matcard matcardFloat">

                  <div class="matcard matcardFloat">
                     <app-panel>
                        <span panel-title>Liste des réunions</span>
                        <div panel-content>
                           <ng-container *ngFor="let item of allComiteeMeetings">
                              <span><app-button icon="touch_app" [class]="getApprovedColor(item.isApproved)"
                                    [text]="getDateWithDayFormatForScreen(item.date)" [isBackgroundOpaque]="true"
                                    (onClick)="onComiteeMeetingClick(item.date)"></app-button> </span>
                           </ng-container>
                        </div>
                     </app-panel>
                  </div>

                  <div class="flexAlignment">
                     <mat-card class="matcard">
                        <mat-card-content>
                           <!-- Calendrier -->
                           <cmc [minDate]="minDate" [maxDate]="maxDate" (onSelected)="onCalendarDateSelect($event)">
                           </cmc>
                        </mat-card-content>
                     </mat-card>
                  </div>

               </div>
            </mat-card-content>
         </mat-card>
      </div>
   </div>
</form>