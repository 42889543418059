import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { enumActivityType } from 'src/sites/core/enum/Enum';
import { user } from 'src/sites/core/models/user/user';
import { AccessRightsService } from 'src/sites/core/services/accessRights.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { eventClub } from '../../models/eventClub';
import { match } from '../../models/match';
import { trainingPlayer } from '../../models/trainingPlayer';
import { CacheService } from '../../services/cache.service';
import { TrainingPlayerService } from '../../services/trainingPlayer.service';
import { StorageService } from '../../authentication/storage.service';
import { encryptedValue } from '../../authentication/encryptedValue';
import { EncryptionService } from 'src/sites/core/services/encryption.service';
import { SubjectService } from 'src/sites/core/services/subject.service';

@Component({
   selector: 'app-trainingPlayerEditionDialog',
   templateUrl: './trainingPlayerEdition.component.html',
   styleUrls: ['./trainingPlayerEdition.component.scss'],
   encapsulation: ViewEncapsulation.None
})
export class TrainingPlayerEditionComponent implements OnInit {
  public allActivities: any[] = [];
  public allClubEventsOfUserAuthenticated: Map<string, eventClub>;
  public allReasons: any[] = [];
  public crudType: number;
  public date: Date;
  public eventClub: eventClub;
  public form: FormGroup = new FormGroup({});
  public isCoachOnlyConnected: boolean = false;
  public isDeleteVisible: boolean = false;
  public title: string;
  public tpOfCoach: trainingPlayer;
  public user: user;

  constructor(public dialogRef: MatDialogRef<TrainingPlayerEditionComponent>,
      @Inject(MAT_DIALOG_DATA) public data: TrainingPlayerEditionModel,
      private trainingPlayerService: TrainingPlayerService,
      private frameworkService: FrameworkService,
      private cacheService: CacheService,
      private subjectService: SubjectService,
      public storageService: StorageService,
      public accessRightsService: AccessRightsService,
      private encryptionService: EncryptionService) {
      this.subjectService.IsButtonInLoadingSubject.next(false);
      this.isCoachOnlyConnected = this.accessRightsService.isMemberCoachOnly(this.storageService.getUserConnected());

      this.user = this.storageService.getUserConnected();

      this.date = data.date;
      // let trainingDay = this.cacheService.getAllTrainingDays().FirstOrDefault(i => i.day == new Date(data.date).getDay());
      this.allClubEventsOfUserAuthenticated = this.cacheService.getAllClubEventsOfUserAuthenticated();
      let event = this.frameworkService.findFirstEventClub(this.allClubEventsOfUserAuthenticated, this.frameworkService.getDateFormatYyyyMmDd(this.data.date));
      //this.frameworkService.logInfo(event?.clubActivityType);

      this.form.addControl("allReasons", new FormControl(''));
      this.form.addControl("allActivities", new FormControl(''));
      this.form.addControl("team", new FormControl(''));
      this.form.addControl("isAtHome", new FormControl(''));
      this.form.addControl("timeStarting", new FormControl(''));
      this.form.addControl("timeEnding", new FormControl(''));
      this.form.addControl("trainingBuilding", new FormControl('Hall des sports anderlues'));
      this.form.addControl("isPresenceNotCounted", new FormControl(''));

      this.allReasons = this.frameworkService.getNonPresenceReason();
      this.allActivities = this.frameworkService.getMatchTrainingActivitiesType();

      if (event == null) {
         this.crudType = 1;
         this.title = "Ajout de l'activité au " + this.frameworkService.getCalendarActivityDate(data.date, true);
         this.isDeleteVisible = false;
      }
      else {
         this.tpOfCoach = this.cacheService.getExtraTrainingOfCoachFromDate(this.date);
         if (this.tpOfCoach != null)
            this.eventClub = this.frameworkService.findFirstEventClub(this.allClubEventsOfUserAuthenticated, this.frameworkService.getDateFormatYyyyMmDd(this.date) + this.tpOfCoach.type)?.eventClub;

         if (this.isAnExtraDay(event.clubActivityType)) {
            this.crudType = 2;

            this.form.get("allActivities").setValue(this.tpOfCoach.type);
            this.form.get("team").setValue(this.tpOfCoach.extraTrainingTeam);
            this.form.get("timeStarting").setValue(this.tpOfCoach.extraTrainingTimeStarting);
            this.form.get("timeEnding").setValue(this.tpOfCoach.extraTrainingTimeEnding);
            this.form.get("isAtHome").setValue(this.tpOfCoach.extraTrainingIsAtHome);
            this.form.get("trainingBuilding").setValue(this.tpOfCoach.extraTrainingBuilding);
            this.form.get('isPresenceNotCounted').setValue(this.tpOfCoach.isPresenceNotCounted);

            this.title = "Modification de l'activité du " + this.frameworkService.getCalendarActivityDate(data.date, true);
            this.isDeleteVisible = true;
         }
         else {
            this.dialogRef.close(true);
         }
      }
   }

  public deleteTrainingPlayers(user: user, ec: eventClub, tp: trainingPlayer) {
      this.trainingPlayerService.deleteAllTrainingPlayerOfCoach(tp).subscribe({
         next: data => {
            let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);

            if (dataDecrypted != null) {
               if (dataDecrypted.tpSysId != 0)
               {
                  //this.frameworkService.logInfo(dataDecrypted, "deleteTrainingPlayers dataDecrypted");
                  //this.frameworkService.logInfo(tp, "deleteTrainingPlayers tp");
                  //Delete all training player of coach
                  dataDecrypted.forEach(tp => {
                     this.allClubEventsOfUserAuthenticated = this.cacheService.manageRClubEventsOfUserAuthenticated(tp, user);
                  });
               }
               this.subjectService.IsTrainingPlayerCacheLoadedSubject.next(true);
               this.frameworkService.displayAlert(true);
            }
            else {
               this.frameworkService.displayAlert(false);
            }
         },
         error: err => {
            this.frameworkService.displayAlert(null, err);
         }
      });
   }

  public isAnExtraDay(type: string) {
      return (type == enumActivityType[enumActivityType.FRIENDLY_MATCH_DAY] ||
         type == enumActivityType[enumActivityType.TOURNAMENT_DAY] ||
         type == enumActivityType[enumActivityType.EXTRA_TRAINING_DAY]);
   }

  public isAnExtraTrainingDay(type: string) {
      return type == enumActivityType[enumActivityType.EXTRA_TRAINING_DAY];
   }

   public isAnTournamentDay(type: string) {
      return type == enumActivityType[enumActivityType.TOURNAMENT_DAY];
   }

   public isAnFriendlyMatchDay(type: string) {
      return type == enumActivityType[enumActivityType.FRIENDLY_MATCH_DAY];
   }

  public manageTrainingPlayer(user: user, tp: trainingPlayer) {
      let ec = new eventClub();

      if (this.crudType == 1) {
         //Create a new eventClub
         ec.tpSysId = null;
         ec.day = tp.day;
         ec.event = new match();
         ec.event.sysId = null;
         ec.event.day = this.frameworkService.getDateOnlyFormatDatabase(this.date);
         ec.event.Present = true;

         ec.event.name = tp.type;
         ec.event.type = tp.type;
         ec.clubActivityType = this.frameworkService.getClubActivityType(tp.type);
         if (tp.extraTrainingTimeEnding == "00:00" || tp.extraTrainingTimeEnding == "")
            ec.event.time = tp.extraTrainingTimeStarting;
         else
         {
            ec.event.time = tp.extraTrainingTimeStarting + " à " + tp.extraTrainingTimeEnding;
         }       


         if (tp.extraTrainingIsAtHome)
            ec.event.awayTeam = tp.extraTrainingTeam;
         else
            ec.event.fromTeam = tp.extraTrainingTeam;
         ec.event.isAtHome = tp.extraTrainingIsAtHome;
         ec.event.buildingName = tp.extraTrainingBuilding;

         this.trainingPlayerService.insertWithServerDateCheck(tp, false).subscribe({
            next: data => {
               let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);

               if (dataDecrypted != null) {
                  this.allClubEventsOfUserAuthenticated = this.cacheService.manageIUClubEventsOfUserAuthenticated(this.date, dataDecrypted, ec, true, user);
                  this.subjectService.IsTrainingPlayerCacheLoadedSubject.next(true);
                  this.frameworkService.displayAlert(true);
               }
               else
                  this.frameworkService.displayAlert(false);
            },
            error: err => {
               this.frameworkService.displayAlert(null, err);
            }
         });
      }
      else {
         //Update the eventClub
         ec = this.eventClub;

         ec.event.name = tp.type;
         ec.event.type = tp.type;
         ec.clubActivityType = this.frameworkService.getClubActivityType(tp.type);
         if (tp.extraTrainingTimeEnding == "00:00" || tp.extraTrainingTimeEnding == "")
            ec.event.time = tp.extraTrainingTimeStarting;
         else
            ec.event.time = tp.extraTrainingTimeStarting + " à " + tp.extraTrainingTimeEnding;
         
         if (tp.extraTrainingIsAtHome)
            ec.event.awayTeam = tp.extraTrainingTeam;
         else
            ec.event.fromTeam = tp.extraTrainingTeam;
         ec.event.isAtHome = tp.extraTrainingIsAtHome;
         ec.event.buildingName = tp.extraTrainingBuilding;

         //this.frameworkService.logInfo(ec, "ec");
         //this.frameworkService.logInfo(tp, "tp");
         //Update all other trainingplayer 
         this.trainingPlayerService.updateAllTrainingPlayerOfCoach(tp).subscribe({
            next: data => {
               let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);

               if (dataDecrypted != null) {
                  //Update all training player of coach
                  dataDecrypted.forEach(tp => {
                     this.allClubEventsOfUserAuthenticated = this.cacheService.manageIUClubEventsOfUserAuthenticated(this.date, tp, ec, false, user);
                  });
                  this.subjectService.IsTrainingPlayerCacheLoadedSubject.next(true);
                  this.frameworkService.displayAlert(true);
               }
               else
                  this.frameworkService.displayAlert(false);
            },
            error: err => {
               this.frameworkService.displayAlert(null, err);
            }
         });
      }
   }

  public ngOnInit() {
   }

  public onCancel(): void {
      this.dialogRef.close(false);
   }

  public onDelete(): void {
      this.deleteTrainingPlayers(this.user, this.eventClub, this.tpOfCoach);
      this.dialogRef.close(true);
   }

  public onSave(): void {
      if (this.form.get("allActivities").value =="")
      {
         this.frameworkService.displayAlert(false, "Vous devez sélectionner un type d'activité.");
         this.subjectService.IsButtonInLoadingSubject.next(false);
         return;
      }
      let tp = new trainingPlayer();
      tp.championship = null;
      tp.player = null;
      if (this.crudType == 1) {
         //Create a new tp
         tp.championshipSysId = this.cacheService.getCurrentChampionship().sysId;
         tp.playerSysId = this.frameworkService.getSysIdOfUser(this.cacheService.getCoachOfCurrentLogged()); //TODO Donner la possibilité à l'admin de simuler l'équipe à laquelle il appartient
         tp.day = this.frameworkService.getDateOnlyFormatDatabase(this.date);
         tp.present = 1;
      }
      else {
         //Get the tp coach
         tp = this.tpOfCoach;
      }

      tp.type = this.form.get("allActivities").value;
      if (tp.type == enumActivityType[enumActivityType.EXTRA_TRAINING_DAY]) {
         tp.extraTrainingTeam = "";
      }
      else {
         tp.extraTrainingTeam = this.form.get("team").value.toString().toUpperCase();
      }
      tp.extraTrainingTimeStarting = this.form.get("timeStarting").value;
      if (this.form.get("timeEnding").value == "00:00" || this.form.get("timeEnding").value == "")
         tp.extraTrainingTimeEnding = "";
      else
         tp.extraTrainingTimeEnding = this.form.get("timeEnding").value;
      tp.extraTrainingIsAtHome = this.form.get("isAtHome").value == "" ? false : this.form.get("isAtHome").value;
      tp.extraTrainingBuilding = this.form.get("trainingBuilding").value.toString();
      tp.isPresenceNotCounted = this.form.get("isPresenceNotCounted").value == "" ? false : this.form.get("isPresenceNotCounted").value;
      this.manageTrainingPlayer(this.user, tp);

      this.dialogRef.close(true);
   }

}

export class TrainingPlayerEditionModel {
  constructor(public date: Date) {
   }
}
