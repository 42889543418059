import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ChangePasswordComponent } from 'src/sites/core/components/account/changePassword.component';
import { LoginComponent } from 'src/sites/core/components/account/login.component';
import { RecoverPasswordComponent } from 'src/sites/core/components/account/recoverPassword.component';
import { RegisterComponent } from 'src/sites/core/components/account/register.component';
import { AuthGuard } from "src/sites/core/helpers";
import { HomeComponent } from 'src/sites/vabourlettis/components/home/home.component';

import { AdminUserListComponent } from 'src/sites/core/components/adminUser/adminUser-list.component';
import { ActivityComponent } from 'src/sites/vabourlettis/components/activity/activity.component';
import { AdminActivityListComponent } from 'src/sites/vabourlettis/components/adminActivity/adminActivity-list.component';
import { AdminBuildingTrainingListComponent } from 'src/sites/vabourlettis/components/adminBuildingTraining/adminBuildingTraining-list.component';
import { AdminChampionshipListComponent } from 'src/sites/vabourlettis/components/adminChampionship/adminChampionship-list.component';
import { AdminDevelopmentComponent } from 'src/sites/vabourlettis/components/adminDevelopment/adminDevelopment.component';
import { AdminMatchListComponent } from 'src/sites/vabourlettis/components/adminMatch/adminMatch-list.component';
import { AdminSponsorListComponent } from 'src/sites/vabourlettis/components/adminSponsor/adminSponsor-list.component';
import { AdminTeamListComponent } from 'src/sites/vabourlettis/components/adminTeam/adminTeam-list.component';
import { BuildingTrainingComponent } from 'src/sites/vabourlettis/components/buildingTraining/buildingTraining.component';
import { ClubComponent } from 'src/sites/vabourlettis/components/club/club.component';
import { ComiteeMeetingComponent } from 'src/sites/vabourlettis/components/comiteeMeeting/comiteeMeeting.component';
import { ComiteeMemberComponent } from 'src/sites/vabourlettis/components/comiteeMember/comiteeMember.component';
import { ComiteeTodoComponent } from 'src/sites/vabourlettis/components/comiteeTodo/comiteeTodo.component';
import { ContactusComponent } from 'src/sites/vabourlettis/components/contactus/contactus.component';
import { FinancesComponent } from 'src/sites/vabourlettis/components/finances/finances.component';
import { MatchComponent } from 'src/sites/vabourlettis/components/match/match.component';
import { NewsPaperComponent } from 'src/sites/vabourlettis/components/newsPaper/newsPaper.component';
import { ProfileComponent } from 'src/sites/vabourlettis/components/profile/profile.component';
import { RankingComponent } from 'src/sites/vabourlettis/components/ranking/ranking.component';
import { SponsorComponent } from 'src/sites/vabourlettis/components/sponsor/sponsor.component';
import { TeamComponent } from 'src/sites/vabourlettis/components/team/team.component';
import { TrainingInfoComponent } from 'src/sites/vabourlettis/components/trainingInfo/trainingInfo.component';
import { TrainingPlayerComponent } from 'src/sites/vabourlettis/components/trainingPlayer/trainingPlayer.component';
import { AdminHomeNewsListComponent } from 'src/sites/vabourlettis/components/adminHomeNews/adminHomeNews-list.component';
import { DiaryComponent } from 'src/sites/vabourlettis/components/diary/diary.component';
import { AdminImportDataComponent } from 'src/sites/vabourlettis/components/adminImportData/adminImportData.component';
import { MemberComponent } from 'src/sites/vabourlettis/components/member/member.component';
import { PrintComponent } from './common/print/print.component';
import { RedirectGuard } from './redirectGuard';
import { AdminBuildingTrainingClosingDayListComponent } from 'src/sites/vabourlettis/components/adminBuildingTrainingClosingDay/adminBuildingTrainingClosingDay-list.component';
import { AdminMemberListComponent } from 'src/sites/vabourlettis/components/adminMember/adminMember-list.component';

const routes: Routes = [
   { path: '', redirectTo: '/home', pathMatch: 'full' },
   {
      path: 'print',
      outlet: 'print',
      component: PrintComponent,
      children: [
         { path: 'ranking', component: RankingComponent }
      ]
   },
   {
      path: 'print',
      outlet: 'print',
      component: PrintComponent,
      children: [
         { path: 'diary', component: DiaryComponent }
      ]
   },
   { path: 'home', component: HomeComponent },
   { path: 'fvwbSynchronize', component: HomeComponent },
   
   { path: 'loginout', component: HomeComponent },

   { path: 'register', component: RegisterComponent },
   { path: 'recoverPassword', component: RecoverPasswordComponent },
   { path: 'changePassword', component: ChangePasswordComponent },

   { path: 'team', component: TeamComponent },
   { path: 'diary', component: DiaryComponent },
   { path: 'club', component: ClubComponent },
   { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
   { path: 'member', component: MemberComponent, canActivate: [AuthGuard] },
   { path: 'activity', component: ActivityComponent },
   { path: 'buildingTraining', component: BuildingTrainingComponent, canActivate: [AuthGuard] },
   { path: 'comiteeMember', component: ComiteeMemberComponent, canActivate: [AuthGuard] },
   { path: 'comiteeMeeting', component: ComiteeMeetingComponent, canActivate: [AuthGuard] },
   { path: 'comiteeTodo', component: ComiteeTodoComponent, canActivate: [AuthGuard] },
   { path: 'finances', component: FinancesComponent, canActivate: [AuthGuard] },
   { path: 'match', component: MatchComponent },
   { path: 'newsPaper', component: NewsPaperComponent },
   { path: 'ranking', component: RankingComponent },
   { path: 'trainingInfo', component: TrainingInfoComponent },
   { path: 'trainingPlayer', component: TrainingPlayerComponent, canActivate: [AuthGuard] },
   { path: 'sponsor', component: SponsorComponent },
   { path: 'contactus', component: ContactusComponent },

   { path: 'login', component: LoginComponent },
   { path: 'admindevelopment', component: AdminDevelopmentComponent, canActivate: [AuthGuard] },
   { path: 'adminchampionshiplist', component: AdminChampionshipListComponent, canActivate: [AuthGuard] },
   { path: 'adminmemberlist', component: AdminMemberListComponent, canActivate: [AuthGuard] },
   { path: 'adminhomeNewslist', component: AdminHomeNewsListComponent, canActivate: [AuthGuard] },
   { path: 'adminactivitylist', component: AdminActivityListComponent, canActivate: [AuthGuard] },
   { path: 'adminsponsorlist', component: AdminSponsorListComponent, canActivate: [AuthGuard] },
   { path: 'adminteamlist', component: AdminTeamListComponent, canActivate: [AuthGuard] },
   { path: 'adminuserlist', component: AdminUserListComponent, canActivate: [AuthGuard] },
   { path: 'adminbuildingtraininglist', component: AdminBuildingTrainingListComponent, canActivate: [AuthGuard] },
   { path: 'adminbuildingtrainingClosingDaylist', component: AdminBuildingTrainingClosingDayListComponent, canActivate: [AuthGuard] },
   { path: 'adminmatchlist', component: AdminMatchListComponent, canActivate: [AuthGuard] },
   { path: 'adminimportdata', component: AdminImportDataComponent, canActivate: [AuthGuard] },
   // otherwise redirect to home
   { path: '**', redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
   imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', initialNavigation: 'enabled' })], //, enableTracing: true
   exports: [RouterModule, TranslateModule]
})
export class AppRoutingModule { }