import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SubjectService } from 'src/sites/core/services/subject.service';

@Component({
   selector: 'app-buttonPlusMinus',
   templateUrl: './buttonPlusMinus.component.html',
   styleUrls: ['./buttonPlusMinus.component.scss']
})
export class ButtonPlusMinusComponent implements OnInit, OnChanges {
   @Input() isPlusButton: boolean;
   @Input() visibility: boolean = true;
   @Input() isAlwaysEnabled: boolean = false;
   @Input() isEventClickNotEmitted: boolean = false;

   @Output() onClick = new EventEmitter<string>();

   isNetworkStatusOnline: boolean = false;
   isNetworkLoading: boolean = false;

   constructor(private subjectService: SubjectService) {  
   }

   ngOnChanges(changes: SimpleChanges): void {
      for (let propName in changes) {
         let change = changes[propName];
         switch(propName) {
            case "visibility":
               this.visibility = change.currentValue;
            break;
         }
       }
   }

   ngOnInit() {
      this.subjectService.IsNetworkStatusOnlineSubject.subscribe(value => { this.isNetworkStatusOnline = value; });
      this.subjectService.IsButtonInLoadingSubject.subscribe(value => { this.isNetworkLoading = value; }); 
   }

   onButtonClick(disabled: boolean) {
      if (!disabled && !this.isNetworkLoading && !this.isEventClickNotEmitted)
      {
         this.isNetworkLoading = true;
         this.subjectService.IsButtonInLoadingSubject.next(true);
         this.onClick.emit(); 
      }
   }

   getClass(disabled: boolean) {
      if (disabled) return "disabled";
      return "";
   }
}
