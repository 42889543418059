<div>
   <div>
      <mat-card class="mat-card-center-95P">
         <mat-card-header class="mat-card-header-color">
            <mat-card-title>
               <span class="material-icons matcard-title-icon"
                  style="margin-left:auto">directions_run</span>{{titleDescriptionDisplay}}
            </mat-card-title>
         </mat-card-header>
         <mat-card-content>

            <div class="matcard matcardFloat" *ngFor="let team of allTeamsFiltered">
               <app-panel [widthPanel]="'400px'">
                  <span panel-title>{{team.teamDisplay}}</span>
                  <div panel-content>
                     <div>
                        {{getTeamContactOfTeam(team.sysId)?.firstName}}&nbsp;{{getTeamContactOfTeam(team.sysId)?.lastName}}
                     </div>
                     <div>
                        <ul>
                           <li *ngIf="team.trainingDay1 != -1">Le {{"DayOfWeek"+team.trainingDay1 |
                              translate}} de
                              {{team.trainingTimeFrom1.replace(":", "H")}} à
                              {{team.trainingTimeTo1.replace(":", "H")}}<br>
                              <span style="font-style:italic">{{team.buildingTrainingName1}}</span></li>
                           <li *ngIf="team.trainingDay2 != -1">Le {{"DayOfWeek"+team.trainingDay2 |
                              translate}} de
                              {{team.trainingTimeFrom2.replace(":", "H")}} à
                              {{team.trainingTimeTo2.replace(":", "H")}}<br>
                              <span style="font-style:italic">{{team.buildingTrainingName2}}</span></li>
                           <li *ngIf="team.trainingDay3 != -1">Le {{"DayOfWeek"+team.trainingDay3 |
                              translate}} de
                              {{team.trainingTimeFrom3.replace(":", "H")}} à
                              {{team.trainingTimeTo3.replace(":", "H")}}<br>
                              <span style="font-style:italic">{{team.buildingTrainingName3}}</span></li>
                        </ul>
                     </div>
                     
                  </div>
               </app-panel>
            </div>

         </mat-card-content>
      </mat-card>
   </div>
</div>