import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { SubjectService } from 'src/sites/core/services/subject.service';
import { CacheService } from 'src/sites/vabourlettis/services/cache.service';

@Component({
   selector: 'app-button',
   templateUrl: './button.component.html',
   styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit, OnChanges {
   @Input() icon: string;
   @Input() visibility: boolean = true;
   @Input() color: string = "primary";
   @Input() text: string;
   @Input() class: string;
   @Input() disabled: boolean = false;
   @Input() isAlwaysEnabled: boolean = false;
   @Input() isIconShowOnlyOnMobile: boolean = false;
   @Input() isSubmit: boolean = false;
   @Input() isBackgroundOpaque: boolean = false;
   @Input() isEventClickNotEmitted: boolean = false;

   @Output() onClick = new EventEmitter<string>();

   isNetworkStatusOnline: boolean = false;
   isNetworkLoading: boolean = false;
   isDisplayOnAMobile: boolean = false;
   textToDisplay: string;
   type: string = "button";
   internalClass: string;
   isButtonDisabled: boolean = true;

   constructor(private subjectService: SubjectService) {
   }

   ngOnChanges(changes: SimpleChanges): void {
      for (let propName in changes) {
         let change = changes[propName];
         switch(propName) {
            case "internalClass":
               this.internalClass = this.class + (this.isBackgroundOpaque ? " " : " navbarLinkBackground");
            break; 
            case "visibility":
               this.visibility = change.currentValue;
            break;
            case "disabled":
               this.isButtonDisabled = !((!this.subjectService.IsGlobalCacheInLoading && !this.isNetworkLoading && this.isNetworkStatusOnline && !this.disabled) || this.isAlwaysEnabled);
            break; 
            case "text":
               this.textToDisplay = (this.isIconShowOnlyOnMobile) ? "" : change.currentValue;
            break;
         }
       }
   }

   ngOnInit() {
      this.subjectService.IsNetworkStatusOnlineSubject.subscribe(value => { this.isNetworkStatusOnline = value; });
      this.subjectService.IsButtonInLoadingSubject.subscribe(value => { this.isNetworkLoading = value; }); 
      this.subjectService.IsWindowResizingSubject.subscribe(value => { this.isDisplayOnAMobile = value && this.isIconShowOnlyOnMobile; if (value && this.isIconShowOnlyOnMobile) this.textToDisplay = ""; else this.textToDisplay = this.text; });   

      this.internalClass = this.class + (this.isBackgroundOpaque ? " " : " navbarLinkBackground");
      if (this.color == "") this.color = "primary";
      if (this.isSubmit) this.type = "submit";

      this.isButtonDisabled = !((!this.subjectService.IsGlobalCacheInLoading && !this.isNetworkLoading && this.isNetworkStatusOnline && !this.disabled) || this.isAlwaysEnabled);
   }

   onButtonClick() {
      if (!this.isNetworkLoading && !this.isEventClickNotEmitted && this.isNetworkStatusOnline)
      {
         this.isNetworkLoading = true;
         if (!this.isAlwaysEnabled)
            this.subjectService.IsButtonInLoadingSubject.next(true);
         this.onClick.emit(); 
      }

      if (!this.isNetworkStatusOnline && this.isAlwaysEnabled)
      {
         this.onClick.emit();
      }
   }
}
