import { Component, HostListener, Inject, OnInit, Optional, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ComiteeMeetingCalendarComponent } from 'src/sites/core/components/comiteeMeetingCalendar/comiteeMeetingCalendar.component';
import { ModalFormParam } from 'src/sites/core/models/system/modalFormParam';
import { user } from 'src/sites/core/models/user/user';
import { AccessRightsService } from 'src/sites/core/services/accessRights.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { comiteeMeeting } from '../../models/comiteeMeeting';
import { CacheService } from '../../services/cache.service';
import { ComiteeMeetingEditionComponent, ComiteeMeetingEditionModel } from '../comiteeMeetingEdition/comiteeMeetingEdition.component';
import { StorageService } from '../../authentication/storage.service';
import { SubjectService } from 'src/sites/core/services/subject.service';
import { enumCacheStatusLoad } from 'src/sites/core/enum/Enum';

@Component({
   selector: 'app-comiteeMeeting',
   templateUrl: './comiteeMeeting.component.html',
   styleUrls: ['./comiteeMeeting.component.scss'],
   encapsulation: ViewEncapsulation.None
})
export class ComiteeMeetingComponent implements OnInit {
  @ViewChild(ComiteeMeetingCalendarComponent) public comiteeMeetingCalendar: ComiteeMeetingCalendarComponent;

  public allComiteeMeetings: comiteeMeeting[];
  public allComiteeRoles = new Map<string, user>();
  public allComiteeRolesUser = new Map<string, user>();
  public allRoles = [];
  public championship = null;
  public championshipFilterDisplay: string = "";
  public comiteeOrderOfTheDayDisplay: string = "";
  public form: FormGroup = new FormGroup({});
  public isAddingMeetingInProgress: boolean = false;
  public isComiteeMeetingAvailable: boolean = false;
  public isDisplayOnAMobile = (window.innerWidth <= 960);
  public isExternalDocumentDeleting: boolean = false;
  public isMeetingDeleting: boolean = false;
  public isMeetingExpanded: boolean = false;
  public isMemberExpanded: boolean = false;
  public isNetworkStatusOnline: boolean = false;
  public isPointsToViewExpanded: boolean = false;
  public isPointsToViewModify: boolean = false;
  public isPresenceModify: boolean = false;
  public isReportOfMeetingFreeTextExpanded: boolean = false;
  public isReportOfMeetingModify: boolean = false;
  public isReportOfMeetingPictureExpanded: boolean = false;
  public maxDate = this.frameworkService.getToday();
  public meetingDate: Date;
  public minDate = this.frameworkService.getToday();
  public networkStatus = navigator.onLine;
  public selectedDate;
  public selectedMeeting: comiteeMeeting = null;
  public selectedTime;
  public titleDescriptionDisplay: string = "";

  constructor(
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private dateAdapter: DateAdapter<any>,
      private frameworkService: FrameworkService,
      private translateService: TranslateService,
      public accessRightsService: AccessRightsService,
      public storageService: StorageService,
      //private gmailAuthService: GmailAuthService,
      @Optional() @Inject(MAT_DIALOG_DATA) public modalFormParam: ModalFormParam,
      @Optional() public dialog: MatDialog
   ) {
      this.getScreenSize();
      //this.fetchEmailsFromGmail();
      this.form.addControl("allchampionship", new FormControl(''));
      this.form.addControl("time", new FormControl(''));
      this.form.addControl("location", new FormControl(''));
      this.form.addControl("pointsToView", new FormControl(''));
      this.form.addControl("reportOfMeeting", new FormControl(''));
      this.form.addControl("externalDocumentOfMeetingPicture", new FormControl(''));
      this.form.addControl("externalDocumentOfMeetingPdf", new FormControl(''));
   }

  @HostListener('window:resize', ['$event'])
   public getScreenSize() {
      this.isDisplayOnAMobile = (window.innerWidth <= 960);
   }

   public ngOnInit() {
      this.subjectService.GlobalCacheLoadedStatusSubject.subscribe(value => { if (value != null && value >= enumCacheStatusLoad.loaded) this.initialize(); });

      this.dateAdapter.setLocale(this.translateService.currentLang);
      this.translatePage();

      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => { this.translatePage(); });

      this.subjectService.IsNetworkStatusOnlineSubject.subscribe(value => { this.isNetworkStatusOnline = value; });
      this.subjectService.IsWindowResizingSubject.subscribe(value => { this.isDisplayOnAMobile = value; });
      this.subjectService.IsComiteeMeetingCacheLoadedSubject.subscribe(value => { if (value) this.initialize(); });

      this.initialize();
   }

   // fetchEmailsFromGmail() {
   //    // Get an authenticated client
   //    this.gmailAuthService.getAuthClient().then((client) => {
   //      // Use the client to make API requests
   //      // Example: List user's messages
   //      client.request({
   //        method: 'GET',
   //        url: 'https://www.googleapis.com/gmail/v1/users/admin/messages',
   //      }).then((response) => {
   //        console.log(response.data);
   //      });
   //    });
   //  }

  public getApprovedColor(approved: boolean) {
      return approved ? "spanButtonSeparator spanButtonWidth approvedBackcolor" : "spanButtonSeparator spanButtonWidth notYetApprovedBackcolor";
   }

  public getDateWithDayFormatForScreen(date) {
      return this.frameworkService.getDateWithDayFormatForScreen(date.toString())
   }

  public initialize() {
      this.championship = this.cacheService.getAllChampionships().FirstOrDefault(i => i.isCurrent);
      this.allComiteeMeetings = this.cacheService.getAllComiteeMeetings().sort(function (a, b) {
         if (a.date > b.date) {
            return -1;
         }
         if (a.date < b.date) {
            return 1;
         }
         return 0;
      });
            
      this.setMinMaxDateOfCalendar();
   }

  public isManagerComiteeMeetingAvailable() {
      if (!this.accessRightsService.isUserConnected)
         return false;
      else
      {
         return this.accessRightsService.getMemberIsPresident(this.storageService.getUserConnected()) ||
         this.accessRightsService.getMemberIsVicePresident(this.storageService.getUserConnected()) ||
         this.accessRightsService.getMemberIsSecretary(this.storageService.getUserConnected()) || 
         this.accessRightsService.isUserConnectedAdministratorOrSupervisor();
      }
   }

  //<-> Event when click on the date of calendar
  public onCalendarDateSelect(date: Date) {
      this.selectedDate = date;
      let temp = this.allComiteeMeetings.FirstOrDefault(i => 
         this.frameworkService.getDateFormatYyyyMmDd(i.date) == 
         this.frameworkService.getDateFormatYyyyMmDd(this.selectedDate));
      this.isComiteeMeetingAvailable = temp != null;
   }

  public onComiteeMeetingClick(date) {
      this.onCalendarDateSelect(date);
      this.comiteeMeetingCalendar.changeSelectedDate(this.selectedDate);
      this.subjectService.IsButtonInLoadingSubject.next(false);
   }

  public onMeetingEdition(isOnEdition : boolean) {
      this.subjectService.IsButtonInLoadingSubject.next(false);

      let comiteeMeeting = this.allComiteeMeetings.FirstOrDefault(i => this.frameworkService.getDateFormatYyyyMmDd(i.date) == this.frameworkService.getDateFormatYyyyMmDd(this.selectedDate));

      const dialogRefData = new ComiteeMeetingEditionModel(isOnEdition, this.selectedDate, comiteeMeeting, this.championship);

      const dialogRef = this.dialog.open(ComiteeMeetingEditionComponent, {
         position: { top: '0px' },
         maxWidth: "100%",
         width: "100%",
         data: dialogRefData
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
         this.subjectService.IsButtonInLoadingSubject.next(false);
      });
   }

  public setMinMaxDateOfCalendar() {
   if (this.accessRightsService.isUserConnectedInComitee()) {
         this.minDate = new Date("01/01/" + this.championship.yearStarting);
         this.maxDate = new Date("31/12/" + this.championship.yearEnding); 
      }
   }

  public translatePage() {
      //Championship
      this.translateService.get(['Championship'])
         .subscribe(translations => {
            this.championshipFilterDisplay = translations['Championship'];
         });

      //ComiteeMeetingTitle
      this.translateService.get(['ComiteeMeetingManagementTitle'])
         .subscribe(translations => {
            this.titleDescriptionDisplay = translations['ComiteeMeetingManagementTitle'];
         });

      //ComiteeOrderOfTheDay
      this.translateService.get(['ComiteeOrderOfTheDay'])
         .subscribe(translations => {
            this.comiteeOrderOfTheDayDisplay = translations['ComiteeOrderOfTheDay'];
         });
   }
}