export class queryFilter {
   operation: Number = 0; //TODO Put in constant
   propertyName: string = "";
   value: any;
   isAndAlsoBetweenValues: any;
   isOrElseOnFilter: boolean;

   constructor(init?: Partial<queryFilter>) {
      Object.assign(this, init);
   }
}
