import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { SubjectService } from 'src/sites/core/services/subject.service';

@Component({
   selector: 'app-buttonmenu',
   templateUrl: './buttonMenu.component.html',
   styleUrls: ['./buttonMenu.component.scss']
})
export class ButtonMenuComponent implements OnInit, OnChanges {
   @Input() icon: string;
   @Input() visibility: boolean = true;
   @Input() routerLink: string;
   @Input() href: string;
   @Input() disabled: boolean = false;
   @Input() class: string = "";
   @Input() alt: string;
   @Input() isBackgroundOpaque: boolean = false;
   @Input() isFlicked: boolean = false;
   @Input() widthButton: string;

   @Output() onClick = new EventEmitter<string>();

   public isNetworkStatusOnline: boolean = false;
   public isNetworkLoading: boolean = false;
   public internalClass: string = "";
   public internalWidth: string = "42px";
   public isDisplayOnAMobile = false;
   public isDataImageBase64: boolean = false;

   bgColor: string = "#3d3d3d79 !important";

   constructor(
      private subjectService: SubjectService) {
   }

   ngOnChanges(changes: SimpleChanges): void {
      for (let propName in changes) {
         let change = changes[propName];
         switch (propName) {
            case "isFlicked":
               this.class = "nav-link navbarLink " + (this.isBackgroundOpaque ? "navbarLinkBackgroundOpaque" : "navbarLinkBackground") + " animatedButton";
               if (this.isFlicked) this.class += " animate-flicker"; else this.class = this.class.replace(" animate-flicker", "");
               break;
            case "visibility":
               this.visibility = change.currentValue;
               break;
            case "disabled":
               this.disabled = change.currentValue;
               if (this.disabled) {
                  this.class = this.class.replace(" navbarLinkBackgroundOpaque", "").replace(" navbarLinkBackground", "");
                  this.class += " is_disabled";
               }
               else {
                  this.class += (this.isBackgroundOpaque ? " navbarLinkBackgroundOpaque" : " navbarLinkBackground");
                  this.class = this.class.replace(" is_disabled", "");
               }
               break;
            case "icon":
               this.isDataImageBase64 = change.currentValue.includes("Base64");
               break;
         }
      }
   }

   ngOnInit() {
      this.subjectService.IsWindowResizingSubject.subscribe(value => { this.isDisplayOnAMobile = value; this.resetWidth(); });
      this.subjectService.IsNetworkStatusOnlineSubject.subscribe(value => { this.isNetworkStatusOnline = value; });
      this.subjectService.IsButtonInLoadingSubject.subscribe(value => { this.isNetworkLoading = value; });

      this.class = "nav-link navbarLink" + (this.isBackgroundOpaque ? " navbarLinkBackgroundOpaque" : " navbarLinkBackground") + " animatedButton";
      if (this.disabled) {
         this.class = this.class.replace(" navbarLinkBackgroundOpaque", "").replace(" navbarLinkBackground", "");
         this.class += " is_disabled";
      }

      this.isDataImageBase64 = this.icon.includes("Base64");

      if (this.isFlicked) this.class += " animate-flicker";
      this.internalClass = "nav-link " + ((this.href != undefined && this.href != "onclick") ? " nav-linkImage" : "");

      this.resetWidth();
   }

   resetWidth() {
      if (this.isDisplayOnAMobile)
         this.internalWidth = "42px";
      else
         this.internalWidth = (this.widthButton != "") ? this.widthButton : "60px";
   }


   openLink() {
      if (!this.disabled) {
         window.open(this.href, '_blank');
      }
   }

   onLinkClick(event: MouseEvent) {
      if (this.disabled) {
         event.preventDefault();
         event.stopImmediatePropagation();
      }
   }

   onButtonClick() {
      if (!this.disabled) {
         this.bgColor = "#f44b4b !important";
         setInterval(() => {
            this.bgColor = "#3d3d3d79 !important";
         }, 1000);
         this.onClick.emit();
      }
   }
}
