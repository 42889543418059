<form [formGroup]="form">
   <div>
      <div id="divName">
         <mat-card class="mat-card-center-95P">
            <mat-card-content>
               <mat-card>
                  <mat-card-header class="mat-card-header-color">
                     <mat-card-title>
                        <span class="material-icons matcard-title-icon matIconColorInvertedForPrint"
                           style="margin-left:auto">date_range</span> <span class="printHeader"> Agenda</span> <span style="font-size:x-small"> (Uniquement rencontres officielles)</span>
                     </mat-card-title>
                     <mat-card-title>
                        <div class="flexDiary flexSmall noPrint">
                           <div>
                              <div>Choix des équipes&nbsp;&nbsp;</div>

                              <mat-slide-toggle [checked]="allToggleTeamAreEnabled" (change)="onToggleAllTeams($event)">{{allToggleLabel}}</mat-slide-toggle>

                              <span *ngFor="let item of toggleTeamList">
                                <mat-slide-toggle
                                [class]="item.class"
                                  [checked]="item.enabled"
                                  (change)="onTeamToggleChanged($event, item)">
                                  {{ item.name }}
                                </mat-slide-toggle>
                              </span>

                           </div>
                           <div class="flexDiary alignmentRight">
                              <div>
                                 <app-button icon="print" class="spanButtonSeparator noPrint" (onClick)="onPrintDiary()"
                                    [disabled]="this.allMatchs.length == 0"></app-button>
                              </div>
                              <div style="padding-right:20px">
                                 <app-button icon="forward_to_inbox" class="spanButtonSeparator noPrint"
                                    [text]="'Mailing'" isIconShowOnlyOnMobile="true" (onClick)="onSendToMail()"
                                    [disabled]="!isNetworkStatusOnline || this.allMatchs.length == 0"
                                    [visibility]="accessRightsService.isUserConnected"></app-button>
                              </div>
                           </div>
                        </div>
                     </mat-card-title>
                  </mat-card-header>

                  <mat-card-content>

                     <div class="matcard">
                        <app-panel [widthPanel]="'900px'">
                           <div panel-content>
                              <table class="matTable">
                                 <tr class="matRow">
                                    <th class="matHeader matHeaderDiary" *ngFor="let col of displayedMatchHeaderColumns">{{col}}</th>
                                 </tr>
                                 <ng-container *ngFor="let match of allMatchs; let i = index">
                                    <!-- <tr
                                       *ngIf="getPreviousMatchDay(i) != match.day && (currentTeam == match.teamSysId || currentTeam == 99999)">
                                       <td>
                                          <div class="br1"></div>
                                       </td>
                                    </tr> 
                                    *ngIf="currentTeam == match.teamSysId || currentTeam == 99999"-->
                                    <tr >
                                       <td [class]="classForMatchOfClub(match, col)"
                                          *ngFor="let col of displayedMatchColumns">
                                          <div
                                             *ngIf="((getPreviousMatchDay(i) != match.day || i == 0) && col == 'day') "
                                             style="height:100%" [innerHtml]="displayColumn(col, match) | safe: 'html'">
                                          </div>
                                          <div *ngIf="col != 'day'" style="height:100%"
                                             [class]="getColumnClass(col, match)"
                                             [innerHtml]="displayColumn(col, match) | safe: 'html'">
                                          </div>
                                       </td>
                                    </tr>
                                 </ng-container>
                              </table>
                           </div>
                        </app-panel>
                     </div>

                  </mat-card-content>
               </mat-card>
            </mat-card-content>
         </mat-card>
      </div>
   </div>
</form>