
import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

const ITEMS_PER_PAGE = 'MAT_PAGINATOR_ITEMS_PER_PAGE_LABEL';
const NEXT_PAGE = 'MAT_PAGINATOR_NEXT_PAGE_LABEL';
const PREV_PAGE = 'MAT_PAGINATOR_PREVIOUS_PAGE_LABEL';
const FIRST_PAGE = 'MAT_PAGINATOR_FIRST_PAGE_LABEL';
const LAST_PAGE = 'MAT_PAGINATOR_LAST_PAGE_LABEL';

@Injectable()
//-- MatPaginator service
export class MatPaginatorI18nService extends MatPaginatorIntl {
public constructor(private translateService: TranslateService) {
  super();

  this.translateService.onLangChange.subscribe((e: Event) => {
    this.getAndInitTranslations();
  });

  this.getAndInitTranslations();
}

public getRangeLabel = (page: number, pageSize: number, length: number): string => {
  if (length === 0 || pageSize === 0) {
    return `0 / ${length}`;
  }

  length = Math.max(length, 0);

  const startIndex: number = page * pageSize;
  const endIndex: number = startIndex < length
    ? Math.min(startIndex + pageSize, length)
    : startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} / ${length}`;
};

public getAndInitTranslations(): void {
  this.translateService.get([
    ITEMS_PER_PAGE,
    NEXT_PAGE,
    PREV_PAGE,
    FIRST_PAGE,
    LAST_PAGE
  ])
    .subscribe((translation: any) => {
      this.itemsPerPageLabel = translation[ITEMS_PER_PAGE];
      this.nextPageLabel = translation[NEXT_PAGE];
      this.previousPageLabel = translation[PREV_PAGE];
      this.firstPageLabel = translation[FIRST_PAGE];
      this.lastPageLabel = translation[LAST_PAGE];
      this.changes.next();
    });
}
}