<div *ngIf="href == undefined && visibility" [class]="class" [style.width]="internalWidth">
    <a [class]="internalClass" (click)="onLinkClick($event)" [routerLink]="disabled ? null : routerLink"
    [routerLinkActive]="disabled ? 'is_disabled' : 'is_active'">
    <span class="material-icons-bigger">{{icon}}</span></a>
</div>
<div *ngIf="href != undefined && href != 'onclick' && visibility && !isDataImageBase64" [class]="class" [style.width]="internalWidth">
    <div [class]="internalClass" (click)="openLink();">
        <img [src]="icon" class="iconDisplaySize" [alt]="alt">
    </div>
</div>
<div *ngIf="href != undefined && href != 'onclick' && visibility && isDataImageBase64" [class]="class" [style.width]="internalWidth">
    <div [class]="internalClass" (click)="openLink();">
        <span [class]="'logoBase64 ' + icon"></span>
    </div>
</div>
<div *ngIf="href == 'onclick' && visibility && !isDataImageBase64" [class]="class" [style.width]="internalWidth" [style.background-color]="bgColor" (click)="onButtonClick()">
    <a [class]="internalClass">
    <span class="material-icons-bigger">{{icon}}</span></a>
</div>
<div *ngIf="href == 'onclick' && visibility && isDataImageBase64" [class]="class" [style.width]="internalWidth" [style.background-color]="bgColor" (click)="onButtonClick()">
    <a [class]="internalClass">
    <span [class]="'logoBase64 ' + icon"></span></a>
</div>
