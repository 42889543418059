<mat-card style="background-color: rgb(54, 7, 7);">
   <mat-card-header class="mat-card-header-color">
      <mat-card-title>{{title}}
      </mat-card-title>
   </mat-card-header>
   <mat-card-content class="mat-card-content-padding">
      <div *ngIf="isNetworkStatusOnline" mat-dialog-content [innerHTML]="gDPR"></div>
      <div *ngIf="!isNetworkStatusOnline">(Connexion à internet requise)</div>
      <div class="br1"></div>
      <div>
         <div>
            <div class="columnLeft">
               <app-button icon="disabled_by_default" [class]="'spanButtonSeparator'" [text]="'Close' | translate" [isBackgroundOpaque]="true" (onClick)="onClose()" [isAlwaysEnabled]="true"></app-button>
            </div>
         </div>
      </div>
   </mat-card-content>
</mat-card>