//-- Datagrid filter parameters
export class datagridFilter {

    public detail = new Map<string, datagridFilterDetail>();

    public constructor(init?:Partial<datagridFilter>) {
      Object.assign(this, init);
    }
}

export class datagridFilterDetail {

  public name = "";
  public labelText = "";
  public idName = "";
  public dropdownLists;
  public dropdownSelectedValueOfLists;

  public constructor(init?:Partial<datagridFilterDetail>) {
    Object.assign(this, init);
  }
}