export class matchUDTO {
   constructor(
      public Date: string,
      public Heure: string,
      public Equipe1: string,
      public Equipe2: string,
      public Salle: string,
      public Rue: string,
      public CodePostal: string,
      public Localite: string,
      public Score: string,
      public Type: string
   ) {
   }
}