<mat-card [class]="getClass()">
   <mat-card-header>
      <mat-card-title>{{title}}</mat-card-title>
      <mat-card-subtitle></mat-card-subtitle>
   </mat-card-header>
   <mat-card-content class="mat-card-content-padding">
      <div mat-dialog-content>
         <div style="width:100%" [innerHTML]="sanitizer.bypassSecurityTrustHtml(message)">
         </div>
      </div>

      <div class="br1"></div>

      <div class="flexCenter">
         <div>
            <app-button icon="disabled_by_default" [class]="'spanButtonSeparator'" [text]="'Close' | translate"
               [isBackgroundOpaque]="true" (onClick)="onClose()" [isAlwaysEnabled]="true"></app-button>
         </div>
      </div>
   </mat-card-content>
</mat-card>