<div class="strengthBar">
    <div class="barLabel">{{barLabel}}</div> 
    <div>
        <ul id="strengthBarUl">  
            <li class="point" [style.background-color]="bar0"></li>
            <li class="point" [style.background-color]="bar1"></li>
            <li class="point" [style.background-color]="bar2"></li>
            <li class="point" [style.background-color]="bar3"></li>
            <li class="point" [style.background-color]="bar4"></li>  
        </ul>
    </div>
</div>