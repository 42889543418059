import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatCalendar, MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { comiteeMeeting } from 'src/sites/vabourlettis/models/comiteeMeeting';
import { CacheService } from 'src/sites/vabourlettis/services/cache.service';
import { AccessRightsService } from '../../services/accessRights.service';
import { FrameworkService } from '../../services/framework.service';
import { StorageService } from 'src/sites/vabourlettis/authentication/storage.service';
import { SubjectService } from '../../services/subject.service';

@Component({
   selector: 'cmc',
   templateUrl: './comiteeMeetingCalendar.component.html',
   styleUrls: ['./comiteeMeetingCalendar.component.scss'],
   encapsulation: ViewEncapsulation.None
})
export class ComiteeMeetingCalendarComponent implements OnInit {
   @Input() public maxDate: string = "";
   @Input() public minDate: string = "";
   @Input() public selectedDate: Date;
   @Input() public visible: boolean = true;
   @Output() public onSelected = new EventEmitter<Date>();
   @ViewChild('calendar', { static: false }) public calendar: MatCalendar<Date>;

   public allComiteeMeetings: comiteeMeeting[] = [];
   // <-> Event to customize each date of the calendar
   public changeCalendarDate: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
      return this.getCalendarCellClass(cellDate);
   }

   public isCoachOrAssistantOrSupervisorConnected: boolean = false;
   public isDisplayOnAMobile: boolean = false;

   constructor(
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private frameworkService: FrameworkService,
      public accessRightsService: AccessRightsService,
      public storageService: StorageService) {
      this.subjectService.IsWindowResizingSubject.subscribe(value => { this.isDisplayOnAMobile = value; });
      this.subjectService.IsComiteeMeetingCacheLoadedSubject.subscribe(value => {
         if (value) {
            if (this.calendar != undefined && this.selectedDate != null) {
               this.calendar._goToDateInView(new Date(this.selectedDate), 'month');
               this.calendar.updateTodaysDate();
               this.allComiteeMeetings = this.cacheService.getAllComiteeMeetings();
            }
         }
      });
   }

   public changeSelectedDate(date: Date) {
      this.selectedDate = date;
      if (this.calendar != null) {
         this.calendar._goToDateInView(new Date(date), 'month');
         this.calendar.updateTodaysDate();
      }
   }

   public getCalendarCellClass(originalCellDate) {
      let comiteeMeeting = this.cacheService.getAllComiteeMeetings().FirstOrDefault(i => this.frameworkService.getDateFormatYyyyMmDd(i.date) == this.frameworkService.getDateFormatYyyyMmDd(originalCellDate));
      if (comiteeMeeting != null)
         return comiteeMeeting.isApproved ? "comiteeMeeting approvedBackcolor" : "comiteeMeeting notYetApprovedBackcolor";
      return "";
   }

   public getSelectedDate(): Date {
      return this.selectedDate;
   }

   public initialize() {
      this.isCoachOrAssistantOrSupervisorConnected = this.frameworkService.IsMemberCoachs(this.storageService.getUserConnected()) || this.accessRightsService.isUserConnectedAdministratorOrSupervisor();
      this.changeSelectedDate(this.frameworkService.getToday());
   }

   public ngOnInit() {
      this.initialize();
   }

   //Original event of calendar (selectedChange)
   public onSelectedChange(date) {
      this.changeSelectedDate(date);
      this.onSelected.emit(date);
   }
}
