
export class AccessRights {
  public newCommand : boolean = false;
  public editCommand : boolean = false;
  public deleteCommand : boolean = false;
  public archiveCommand : boolean = false;
  public unarchiveCommand : boolean = false;

  public constructor(init?:Partial<AccessRights>) {
    Object.assign(this, init);
  }
}