import { enumDatagridHeaderOrder } from "../../enum/Enum";
import { datagridFilterDetail } from "./datagridFilter";

//-- Datagrid parameters
export class datagridParam<T> {
    public hidden : boolean = false;
    public mainTitle : string = "";
    public dataSource : any[];
    public headerOrder : enumDatagridHeaderOrder = enumDatagridHeaderOrder.AngularModel;
    public entity : T;
    public urlApi : string;
    public paginatorItemsCount = [2,5,10,15,100];
    public formTitle : string = "";
    public filters = new Map<string, datagridFilterDetail>();

    public constructor(init?:Partial<datagridParam<T>>) {
      Object.assign(this, init);
    }
}