//This file is autogenerated by C# GeneratedServices/Angular/ServicesAngularGenerationTool

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, of, retry, throwError, timeout } from 'rxjs';
import { AccessRightsService } from 'src/sites/core/services/accessRights.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { LogService } from 'src/sites/core/services/log.service';
import { authToken } from 'src/sites/vabourlettis/authentication/authToken';
import { AppConfigService } from './appconfig.service';
import { encryptedValue } from 'src/sites/vabourlettis/authentication/encryptedValue';
import { StorageService } from 'src/sites/vabourlettis/authentication/storage.service';
import { EncryptionService } from './encryption.service';
import { jsonIgnoreReplacer } from 'json-ignore';
import { PushNotificationModel } from '../models/system/pushNotificationModel';

@Injectable()
export class SystemService {
   public username = "UNKNOWN";

   constructor(
      private appConfigService: AppConfigService,
      private logger: LogService,
      private httpClient: HttpClient,
      private frameworkService: FrameworkService,
      private accessRightsService: AccessRightsService,
      private storageService: StorageService,
      private encryptionService : EncryptionService
   ) {
      this.httpClient = httpClient;
      this.accessRightsService = accessRightsService;
      this.username = !this.accessRightsService.isUserConnected ? "UNKNOWN" : this.storageService.getUserConnected().username;
   }

   public checkWebApi() {
      let url = "/Q202200001";
      return this.httpClient.get<string>(this.appConfigService.getApiUrl() + 'api/C2022' + url)
         .pipe(retry(2), catchError((error: HttpErrorResponse) => {
            if (error.status === 0 || error.status === 503) {
               return of("WEBAPI_OFFLINE");
            } else {
               return throwError(() => error);
            }
         }));
   }

   public upload(formData: FormData) {
      let url = "/Q202200002";
      return this.httpClient.post(this.appConfigService.getApiUrl() + 'api/C2022' + url, formData)
         .pipe(retry(1), catchError((error: HttpErrorResponse) => {
            if (error.status !== 0) {
               return throwError(() => error);
            }
         }));

   }

   public fvwbSynchronize(sendNotification: boolean) {
      let url = "/Q201200001";

      let encryptedData = new encryptedValue();
      encryptedData.value = this.encryptionService.encryptObject(true, false, sendNotification);

      return this.httpClient.post<encryptedValue>(this.appConfigService.getApiUrl() + 'api/C2012' + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
      .pipe(retry(1), catchError(this.handleError));
   }

   public fvwbSynchronizeMembers(sendNotification: boolean) {
      let url = "/Q201200002";

      let encryptedData = new encryptedValue();
      encryptedData.value = this.encryptionService.encryptObject(true, false, sendNotification);

      return this.httpClient.post<encryptedValue>(this.appConfigService.getApiUrl() + 'api/C2012' + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
      .pipe(retry(1), catchError(this.handleError));
   }

   public fvwbSynchronizeSeniorMatchs(sendNotification: boolean) {
      let url = "/Q201200003";

      let encryptedData = new encryptedValue();
      encryptedData.value = this.encryptionService.encryptObject(true, false, sendNotification);

      return this.httpClient.post<encryptedValue>(this.appConfigService.getApiUrl() + 'api/C2012' + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
      .pipe(retry(1), catchError(this.handleError));
   }

   public fvwbSynchronizeYoungMatchs(sendNotification: boolean) {
      let url = "/Q201200004";

      let encryptedData = new encryptedValue();
      encryptedData.value = this.encryptionService.encryptObject(true, false, sendNotification);

      return this.httpClient.post<encryptedValue>(this.appConfigService.getApiUrl() + 'api/C2012' + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
      .pipe(retry(1), catchError(this.handleError));
   }

   public NotificationSubscribe(subscription) {
      let encryptedData = new encryptedValue();
      encryptedData.value = this.encryptionService.encryptObject(true, false, subscription);
         
      let url = "/Q202200004";
      return this.httpClient.post(this.appConfigService.getApiUrl() + 'api/C2022' + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
         .pipe(retry(1), catchError(this.handleError));
   }

   public NotificationMessageSend(pushNotificationModel: PushNotificationModel) {
      let encryptedData = new encryptedValue();
      encryptedData.value = this.encryptionService.encryptObject(true, false, pushNotificationModel);
        
      let url = "/Q202200003";
      return this.httpClient.post(this.appConfigService.getApiUrl() + 'api/C2022' + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
         .pipe(retry(1), catchError(this.handleError));
   }

   public getDateServer() {
      let url = "/Q202200001";

      return this.httpClient.get(this.appConfigService.getApiUrl() + 'api/C2022' + url)
         .pipe(retry(1), catchError(this.handleError));
   }

   // Error handling
   public handleError(error) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
      } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      return throwError(() => errorMessage);
   }
}