
<div [class]="internalGlobalClass" [style.width]="internalWidth" [style.height]="internalHeight">
    <div [class]="internalHeaderClass">
        <span [class]="internalTitleClass"><ng-container *ngIf="isImportant"><span class='material-icons iconWarningSize'>new_releases</span>&nbsp;</ng-container>
        <ng-content select="[panel-title]"></ng-content></span>
    </div>
    <div>
        <div class="panel-image" [style.backgroundImage]="urlImage" [hidden]="isUrlImageEmpty()"></div>
        
        <!-- TODO: blur the border of image. See banner ? -->
        <!-- TODO add image too -->
        <div [class]="getContentClass()" [style.backgroundImage]="backgroundImage">
            <ng-content select="[panel-content]"></ng-content>
        </div>
    </div>
</div>