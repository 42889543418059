import { Component, Input, SimpleChange } from '@angular/core';
import { FrameworkService } from '../../services/framework.service';

@Component({
   selector: 'app-passwordStrength',
   templateUrl: './passwordStrength.component.html',
   styleUrls: ['./passwordStrength.component.scss']
})

export class PasswordStrengthComponent {
  private colors = ['#F00', '#F90', '#FF0', '#9F0', '#0F0'];

  @Input() public barLabel: string = "";
  @Input() public passwordToCheck: string;

  public bar0: string;
  public bar1: string;
  public bar2: string;
  public bar3: string;
  public bar4: string;

  constructor(
      private frameworkService: FrameworkService
   ) { }

  public ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
      if (changes['passwordToCheck'] != undefined && changes['passwordToCheck'].currentValue != undefined) {
         const  password = changes['passwordToCheck'].currentValue;
         this.setBarColors(5, '#DDD');
         if (password) {
            let c = this.getColor(this.frameworkService.getPasswordMeasureStrength(password));
            this.setBarColors(c.idx, c.col);
         }
      }
      if (changes['barLabel'] != undefined && changes['barLabel'].currentValue != undefined) {
         this.barLabel = changes['barLabel'].currentValue;
      }
   }

  private getColor(score: number) {
      let idx = 0;
      if (score > 90) {
         idx = 4;
      } else if (score > 70) {
         idx = 3;
      } else if (score >= 40) {
         idx = 2;
      } else if (score >= 20) {
         idx = 1;
      }
      return {
         idx: idx + 1,
         col: this.colors[idx]
      };
   }

  private setBarColors(count, col) {
      for (let _n = 0; _n < count; _n++) {
         this['bar' + _n] = col;
      }
   }
}