<div>
   <div>
      <mat-card class="mat-card-center-95P">
         <mat-card-header class="mat-card-header-color">
            <mat-card-title>
               <span class="material-icons matcard-title-icon"
                  style="margin-left:auto">supervisor_account</span>{{titleDescriptionDisplay}}
            </mat-card-title>
         </mat-card-header>
         <mat-card-content>
            <div class="matcard matcardFloat">
               <app-panel [widthPanel]="'250px'" *ngFor="let role of allRolesOfMembers">
                  <span panel-title>{{role.name}}</span>
                  <div panel-content *ngIf="getAllUsersOfRole(role.name) == null">
                     (Aucun)
                  </div>
                  <div panel-content *ngIf="getAllUsersOfRole(role.name) != null">
                     <ng-container *ngFor="let u of getAllUsersOfRole(role.name)">
                        <div class="flexLeft">
                           <div [class]="getClass(false)">
                              &nbsp;&nbsp;{{u.firstName}}
                           </div>
                           <div [class]="getClass(false)">
                              &nbsp;{{u.lastName}}</div>
                        </div>
                     </ng-container>
                  </div>
               </app-panel>
            </div>
         </mat-card-content>
      </mat-card>
   </div>
</div>