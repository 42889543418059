// snackbar-content.component.ts
import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { dataPopup } from '../../services/snackbar.service';

@Component({
  selector: 'app-messagePopup',
  templateUrl: './messagePopup.component.html',
  styleUrls: ['./messagePopup.component.scss']
})
export class MessagePopupComponent {

  constructor( public snackBarRef: MatSnackBarRef<MessagePopupComponent>,
   @Inject(MAT_SNACK_BAR_DATA) public data: dataPopup[]) {

      console.log(JSON.stringify(data));
   }

  closeSnackBar(): void {
   this.snackBarRef.dismiss();
 }
}
