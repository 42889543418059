//This file is autogenerated by C# GeneratedServices/Angular/ServicesAngularGenerationTool

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, retry, throwError } from 'rxjs';
import { AppConfigService } from 'src/sites/core/services/appconfig.service';
import { queryFilter } from '../models/queryFilter';
import { encryptedValue } from 'src/sites/vabourlettis/authentication/encryptedValue';
import { FrameworkService } from 'src/sites/core/services/framework.service';

@Injectable()
export class FormBaseService {

   constructor(
      private httpClient: HttpClient,
      private frameworkService: FrameworkService,
      private appConfigService : AppConfigService) {
   }

   getAllWithIncluding(entity: string) {
      //this.frameworkService.logInfo(this.frameworkService.getFormController(entity), entity);
      let url = "/Q1001?withIncluding=True";
      let queryFilters: queryFilter[] = [];
      return this.httpClient.post<encryptedValue>(this.appConfigService.getApiUrl() + 'api/' + this.frameworkService.getFormController(entity) + url, queryFilters)
         .pipe(retry(1), catchError(this.handleError));
   }

   getAllWithoutIncluding(entity: string) {
      //this.frameworkService.logInfo(this.frameworkService.getFormController(entity), entity);
      let url = "/Q1001?withIncluding=False";
      let queryFilters: queryFilter[] = [];
      return this.httpClient.post<encryptedValue>(this.appConfigService.getApiUrl() + 'api/' + this.frameworkService.getFormController(entity) + url, queryFilters)
         .pipe(retry(1), catchError(this.handleError));
   }

   getAllActiveWithIncluding(entity: string) {
      //this.frameworkService.logInfo(this.frameworkService.getFormController(entity), entity);
      let url = "/Q1001?withIncluding=True";
      url += "&isActive=True";
      let queryFilters: queryFilter[] = [];
      return this.httpClient.post<encryptedValue>(this.appConfigService.getApiUrl() + 'api/' + this.frameworkService.getFormController(entity) + url, queryFilters)
         .pipe(retry(1), catchError(this.handleError));
   }

   getAllActiveWithoutIncluding(entity: string) {
      //this.frameworkService.logInfo(this.frameworkService.getFormController(entity), entity);
      let url = "/Q1001?withIncluding=False";
      url += "&isActive=True";
      let queryFilters: queryFilter[] = [];
      return this.httpClient.post<encryptedValue>(this.appConfigService.getApiUrl() + 'api/' + this.frameworkService.getFormController(entity) + url, queryFilters)
         .pipe(retry(1), catchError(this.handleError));
   }

   getAllInactiveWithIncluding(entity: string) {
      //this.frameworkService.logInfo(this.frameworkService.getFormController(entity), entity);
      let url = "/Q1001?withIncluding=True";
      url += "&isActive=False";
      let queryFilters: queryFilter[] = [];
      return this.httpClient.post<encryptedValue>(this.appConfigService.getApiUrl() + 'api/' + this.frameworkService.getFormController(entity) + url, queryFilters)
         .pipe(retry(1), catchError(this.handleError));
   }

   getAllInactiveWithoutIncluding(entity: string) {
      //this.frameworkService.logInfo(this.frameworkService.getFormController(entity), entity);
      let url = "/Q1001?withIncluding=False";
      url += "&isActive=False";
      let queryFilters: queryFilter[] = [];
      return this.httpClient.post<encryptedValue>(this.appConfigService.getApiUrl() + 'api/' + this.frameworkService.getFormController(entity) + url, queryFilters)
         .pipe(retry(1), catchError(this.handleError));
   }

   // Error handling 
   handleError(error) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
      } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      return throwError(() => errorMessage);
   }
}