<mat-toolbar *ngIf="data.mobileType === 'android'" color="primary" class="android-prompt">
   <button mat-button (click)="installPwa()">
      Ajouter comme application
   </button>
   <button mat-icon-button (click)="close()">
      <span class="material-icons" style="margin-left:auto">close</span>
   </button>
</mat-toolbar>

<div *ngIf="data.mobileType === 'ios'" class="ios-prompt">
   <button class="close-ios-inv" mat-button color="primary" (click)="close()">
      <span class="material-icons" style="margin-left:auto">close</span>
   </button>
   <div>Pour installer ce site web sur votre appareil, cliquer sur le bouton de la barre du menu du navigateur
   </div>
   <div><img src="../../../../assets/images/system/ios-menu-btn.png">
      <span class="material-icons" style="margin-left:auto">navigate_next</span>
      <img src="../../../../assets/images/system/ios-add-btn.png">
   </div>
</div>