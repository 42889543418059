import { HttpErrorResponse } from "@angular/common/http";

export function handleError(method: string, error: HttpErrorResponse) {
  if (error.error instanceof ErrorEvent) {
    console.error("ERROR on Client-side (" + method + ") -> " + JSON.stringify(error.error));
  } else {
    console.error("ERROR on Server-side (" + method + ") -> " + JSON.stringify(error));
  }
}

