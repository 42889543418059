//This file is autogenerated by C# GeneratedModels

import { teamBase } from "./teamBase";

@Reflect.metadata('team', 'team')
export class team extends teamBase {
    clone(other : team) : void {
        let k: keyof team;
        for (k in other) {
            const v = other[k];
            (this[k] as (typeof v)) = v;
          }
    }
}
