import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from "@angular/material/bottom-sheet";

@Component({
   selector: 'app-pwa',
   templateUrl: './pwa.component.html',
   styleUrls: ['./pwa.component.scss']
})
export class PwaComponent {
  constructor(
      @Inject(MAT_BOTTOM_SHEET_DATA) public data: { mobileType: 'ios' | 'android', promptEvent?: any },
      private bottomSheetRef: MatBottomSheetRef<PwaComponent>
   ) { }

  public close() {
      this.bottomSheetRef.dismiss();
   }

  public installPwa(): void {
      this.data.promptEvent.prompt();
      this.close();
   }
}