import { enumAvailability, enumClubActivityType, enumMatchDisplayType } from "src/sites/core/enum/Enum";

export class eventClub {
    event: any;
    tpSysId: number = 0;
    day: Date;
    clubActivityType: enumClubActivityType = enumClubActivityType.NONE
    availability: number = enumAvailability.NULL;
    reason: string = "";

    constructor(init?: Partial<eventClub>) {
        Object.assign(this, init);
    }
}
