import { Component, Inject, LOCALE_ID, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import 'linq-typed';
import { Color, Label, SingleDataSet } from 'ng2-charts';
import { enumActivityType } from 'src/sites/core/enum/Enum';
import { user } from 'src/sites/core/models/user/user';
import { AccessRightsService } from 'src/sites/core/services/accessRights.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { infoPresenceOfUser } from '../../models/infoPresenceOfUser';
import { trainingPlayer } from '../../models/trainingPlayer';
import { CacheService } from '../../services/cache.service';
import { StorageService } from '../../authentication/storage.service';
import { SubjectService } from 'src/sites/core/services/subject.service';

@Component({
   selector: 'tps',
   templateUrl: './trainingPlayersStatistics.component.html',
   styleUrls: ['./trainingPlayersStatistics.component.scss'],
   encapsulation: ViewEncapsulation.None
})
export class TrainingPlayersStatisticsComponent implements OnInit {
  public allInfoPresenceOfUser: infoPresenceOfUser[] = [];
  public allTrainingDay: any[] = [];
  public allTrainingPlayersOfCS: trainingPlayer[] = [];
  public allUsers: user[] = [];
  public barChartData1: ChartDataSets[] = [];
  public barChartData2: ChartDataSets[] = [];
  public barChartLabels1;
  public barChartLabels2;
  public barChartLegend: boolean = true;
  public barChartOptions: ChartOptions = {
      maintainAspectRatio: true,
      aspectRatio: 1.3,
      responsive: true,
      scales: {
         yAxes: [{
            ticks: {
               beginAtZero: true
            }
         }]
      },
      plugins: {
         datalabels: {
            anchor: 'end',
            align: 'end',
         }
      }
   };
  public barChartType: string = "bar";
  public chartColors: Color[] = [{ backgroundColor: ["#4caf4c", "#ddbb25", "#c03030"] }];
  public coachsCount: number = 0;
  public date: Date;
  public guardPercent: number = 0;
  public isCoachOrAssistantOrSupervisorConnected: boolean = false;
  public matchDayCount: number = 0;
  public notPresencePercent: number = 0;
  public pieChartData: SingleDataSet;
  public pieChartLabels: Label[];
  public pieChartLegend: boolean = true;
  public pieChartOptions: ChartOptions = {
      responsive: true
   };
  public pieChartPlugins = [];
  public pieChartType: ChartType = 'doughnut';
  public playersCount: number = 0;
  public playersNotPresentTotal: number;
  public playersOnGuardTotal: number;
  public playersPresentTotal: number;
  public presencePercent: number = 0;
  public subTitle: string;
  public subTitle2: string;
  public temp: infoPresenceOfUser[] = [];
  public title: string;
  public trainingDayCount: number = 0;

  constructor(public dialogRef: MatDialogRef<TrainingPlayersStatisticsComponent>,
      @Inject(MAT_DIALOG_DATA) public data: TrainingPlayersStatisticsModel,
      @Inject(LOCALE_ID) private locale: string,
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private frameworkService: FrameworkService,
      public accessRightsService: AccessRightsService,
      public storageService: StorageService) {
      this.subjectService.IsButtonInLoadingSubject.next(false);
      this.isCoachOrAssistantOrSupervisorConnected = this.frameworkService.IsMemberCoachs(this.storageService.getUserConnected()) || this.accessRightsService.isUserConnectedAdministratorOrSupervisor();
      this.date = data.date;
      this.title = "Rapport des disponibilités de l'équipe";
      this.subTitle = "au " + this.frameworkService.getCalendarActivityDate(data.date, true) + " : ";
      
      this.initialize();

      this.subjectService.IsTrainingPlayerCacheLoadedSubject.subscribe(value => {
         if (value) {
            this.initialize();
         }
      });
   }

  public initialize() {
      this.allTrainingPlayersOfCS = this.cacheService.getAllTrainingPlayers().filter(i => (i.playerSysId == this.storageService.getUserConnected().sysId || i.playerSysId == this.frameworkService.getSysIdOfUser(this.cacheService.getCoachOfCurrentLogged())));
      let teamOfUserConnected = this.cacheService.getTeamOfUserConnected();
      if (teamOfUserConnected != null) { // If at least one team is selected ...
         //-> Add training days
         if (teamOfUserConnected.trainingDay1 != -1) this.allTrainingDay.push({ day: teamOfUserConnected.trainingDay1, timeFrom: teamOfUserConnected.trainingTimeFrom1, timeTo: teamOfUserConnected.trainingTimeTo1, buildingTraining: teamOfUserConnected.buildingTrainingName1 });
         if (teamOfUserConnected.trainingDay2 != -1) this.allTrainingDay.push({ day: teamOfUserConnected.trainingDay2, timeFrom: teamOfUserConnected.trainingTimeFrom2, timeTo: teamOfUserConnected.trainingTimeTo2, buildingTraining: teamOfUserConnected.buildingTrainingName2 });
         if (teamOfUserConnected.trainingDay3 != -1) this.allTrainingDay.push({ day: teamOfUserConnected.trainingDay3, timeFrom: teamOfUserConnected.trainingTimeFrom3, timeTo: teamOfUserConnected.trainingTimeTo3, buildingTraining: teamOfUserConnected.buildingTrainingName3});
         //<- Add training days
      }

      this.loadAllInfoPresenceOfUser();

      this.subTitle2 = this.playersCount + " joueurs" + ((this.coachsCount > 1) ? " / " + this.coachsCount + " coachs" : "");
   }

  public isDateIsRecordedAsTrainings(date: Date): boolean {
      return this.allTrainingDay.FirstOrDefault(i => i.day == new Date(date).getDay()) != null;
   }
   
   isAMatch(allMatchs, i) {
      return allMatchs.filter(j => {{this.frameworkService.getDateFormatYyyyMmDd(i) == this.frameworkService.getDateFormatYyyyMmDd(j.day)}});
   }

  public loadAllInfoPresenceOfUser(): void {
      this.playersOnGuardTotal = 0;
      this.playersNotPresentTotal = 0;
      this.playersPresentTotal = 0;

      let date = this.frameworkService.getToday();
      if (this.isCoachOrAssistantOrSupervisorConnected && this.date != null) {
         date = this.date;
      }
      this.allInfoPresenceOfUser = [];

      let allMatchs = this.cacheService.getAllMatchsOfUserTeamAndClub().filter(i =>
         (this.frameworkService.getDateFormatYyyyMmDd(i.day) <= this.frameworkService.getDateFormatYyyyMmDd(date)) &&
         (i.type == enumActivityType[enumActivityType.CHAMPIONSHIP] ||
            i.type == enumActivityType[enumActivityType.HAINAUT_CUP]));

      let allClosingDays = this.cacheService.getAllBuildingTrainingsClosingDay();

      //-> Count number of normal trainings in the championship
      this.trainingDayCount = 0;

      let trainingsDay = null;
      if (this.cacheService.getTeamOfUserConnected() != null) {
         if (this.cacheService.getTeamOfUserConnected().trainingDay1 != -1) {
            trainingsDay = this.frameworkService.getDaysBetweenDates(this.cacheService.getTeamOfUserConnected().startOfTrainingDate, date, this.cacheService.getTeamOfUserConnected().trainingDay1);
            trainingsDay = trainingsDay.filter(i => 
               this.frameworkService.getDateFormatYyyyMmDd(i) <= this.frameworkService.getDateFormatYyyyMmDd(date) && 
               allMatchs.filter(j => this.frameworkService.getDateFormatYyyyMmDd(j.day) === this.frameworkService.getDateFormatYyyyMmDd(i)).length == 0 && 
               allClosingDays.filter(j => this.frameworkService.getDateFormatYyyyMmDd(j.day) === this.frameworkService.getDateFormatYyyyMmDd(i)).length == 0
            );    
            this.trainingDayCount += trainingsDay.length;
            //this.frameworkService.logInfo(trainingsDay.length, "= (trainingDay1) NORMAL TRAINING_DAY count");
         }

         if (this.cacheService.getTeamOfUserConnected().trainingDay2 != -1) {
            trainingsDay = this.frameworkService.getDaysBetweenDates(this.cacheService.getTeamOfUserConnected().startOfTrainingDate, date, this.cacheService.getTeamOfUserConnected().trainingDay2);
            trainingsDay = trainingsDay.filter(i => 
               this.frameworkService.getDateFormatYyyyMmDd(i) <= this.frameworkService.getDateFormatYyyyMmDd(date) && 
               allMatchs.filter(j => this.frameworkService.getDateFormatYyyyMmDd(j.day) === this.frameworkService.getDateFormatYyyyMmDd(i)).length == 0 && 
               allClosingDays.filter(j => this.frameworkService.getDateFormatYyyyMmDd(j.day) === this.frameworkService.getDateFormatYyyyMmDd(i)).length == 0
            );    
            this.trainingDayCount += trainingsDay.length;
            //this.frameworkService.logInfo(trainingsDay.length, "= (trainingDay2) NORMAL TRAINING_DAY count");
         }
         if (this.cacheService.getTeamOfUserConnected().trainingDay3 != -1) {
            trainingsDay = this.frameworkService.getDaysBetweenDates(this.cacheService.getTeamOfUserConnected().startOfTrainingDate, date, this.cacheService.getTeamOfUserConnected().trainingDay3);
            trainingsDay = trainingsDay.filter(i => 
               this.frameworkService.getDateFormatYyyyMmDd(i) <= this.frameworkService.getDateFormatYyyyMmDd(date) && 
               allMatchs.filter(j => this.frameworkService.getDateFormatYyyyMmDd(j.day) === this.frameworkService.getDateFormatYyyyMmDd(i)).length == 0 && 
               allClosingDays.filter(j => this.frameworkService.getDateFormatYyyyMmDd(j.day) === this.frameworkService.getDateFormatYyyyMmDd(i)).length == 0
            );    
            this.trainingDayCount += trainingsDay.length;
            //this.frameworkService.logInfo(trainingsDay.length, "= (trainingDay3) NORMAL TRAINING_DAY count");
         }
      }
      //this.frameworkService.logInfo(this.trainingDayCount, "= (training) NORMAL TRAINING_DAY count");

      let temp = this.allTrainingPlayersOfCS.filter(i =>
         i.playerSysId == this.frameworkService.getSysIdOfUser(this.cacheService.getCoachOfCurrentLogged()) &&
         this.frameworkService.getMatchTrainingActivitiesType().filter(function (j) { return j.value === i.type }) &&
         (this.frameworkService.getDateFormatYyyyMmDd(i.day) <= this.frameworkService.getDateFormatYyyyMmDd(date)) && 
         allClosingDays.filter(j => this.frameworkService.getDateFormatYyyyMmDd(j.day) === this.frameworkService.getDateFormatYyyyMmDd(i.day)).length == 0);

      //-> Count number of extra trainings in the championship and add them
      this.trainingDayCount += temp.filter(i => i.type == enumActivityType[enumActivityType.EXTRA_TRAINING_DAY]).length;
      //this.frameworkService.logInfo(this.trainingDayCount, "= (training) EXTRA_TRAINING_DAY add Count");

      //-> Count number of matchs officiels (CHAMPIONSHIP, HAINAUT_CUP)
      this.matchDayCount = allMatchs.length;
      //this.frameworkService.logInfo(this.matchDayCount, "= (match) Count");

      this.matchDayCount += temp.filter(i =>
      (i.type == enumActivityType[enumActivityType.FRIENDLY_MATCH_DAY] ||
         i.type == enumActivityType[enumActivityType.TOURNAMENT_DAY])).length;
      //this.frameworkService.logInfo(this.matchDayCount, "= (match) add Count");

      let percentTraining1 = [];
      let percentMatch1 = [];
      let userList1 = [];
      let percentTraining2 = [];
      let percentMatch2 = [];
      let userList2 = [];

      let allUsersOfTeamOfCurrentUser = this.cacheService.getAllUsersOfTeamOfCurrentUser().filter(i => i.firstName != "VAB").sort(function (a, b) {
         if (a.firstName + a.lastName < b.firstName + b.lastName) {
            return -1;
         }
         if (a.firstName + a.lastName > b.firstName + b.lastName) {
            return 1;
         }
         return 0;
      });

      let allCoachs = this.cacheService.getAllCoachsOfCurrentLogged().map(function (user) {
         return user.sysId;
      });
      this.coachsCount = allCoachs.length;
      this.playersCount = allUsersOfTeamOfCurrentUser.length - allCoachs.length;
      //this.frameworkService.logInfo(allUsersOfTeamOfCurrentUser.length , "= allUsersOfTeamOfCurrentUser.length");
      //this.frameworkService.logInfo(allCoachs.length , "= allCoachs.length");

      for (let cpt = 0; cpt < allUsersOfTeamOfCurrentUser.length; cpt++) {
         let allTrainingPlayers = [];
         let allNotPresentForTrainingPlayersOfTraining = [];
         let allOnGuardForTrainingPlayersOfTraining = [];

         //-----------------> TRAINING
         allTrainingPlayers = this.cacheService.getAllTrainingPlayers().filter(i =>
            i.playerSysId == allUsersOfTeamOfCurrentUser[cpt].sysId &&
            this.frameworkService.getDateFormatYyyyMmDd(i.day) <= this.frameworkService.getDateFormatYyyyMmDd(date) &&
            !i.isPresenceNotCounted &&
            (i.type == enumActivityType[enumActivityType.EXTRA_TRAINING_DAY] ||
               (i.type == enumActivityType[enumActivityType.TRAINING_DAY] &&
                  allMatchs.filter(j => this.frameworkService.getDateFormatYyyyMmDd(j.day) === this.frameworkService.getDateFormatYyyyMmDd(i.day)).length == 0) ))
            allTrainingPlayers.forEach(i => {
            // if (allUsersOfTeamOfCurrentUser[cpt].sysId == 158) {
            //    this.frameworkService.logInfo(i.present, "i.present");
            // }
            if (i.present == null) {
               allOnGuardForTrainingPlayersOfTraining.push(i);
            }
            else {
               if (i.present == 0) {
                  allNotPresentForTrainingPlayersOfTraining.push(i);
               }
            }
         });

         //-----------------> MATCH
         let allNotPresentForTrainingPlayersOfMatch = [];
         let allOnGuardForTrainingPlayersOfMatch = [];

         allTrainingPlayers = this.cacheService.getAllTrainingPlayers().filter(i =>
            i.playerSysId == allUsersOfTeamOfCurrentUser[cpt].sysId &&
            this.frameworkService.getDateFormatYyyyMmDd(i.day) <= this.frameworkService.getDateFormatYyyyMmDd(date)  && 
            allClosingDays.filter(j => this.frameworkService.getDateFormatYyyyMmDd(j.day) === this.frameworkService.getDateFormatYyyyMmDd(i.day)).length == 0 &&
            (i.type == enumActivityType[enumActivityType.CHAMPIONSHIP] ||
               i.type == enumActivityType[enumActivityType.HAINAUT_CUP] ||
               i.type == enumActivityType[enumActivityType.FRIENDLY_MATCH_DAY] ||
               i.type == enumActivityType[enumActivityType.TOURNAMENT_DAY] ||
               allMatchs.filter(j => this.frameworkService.getDateFormatYyyyMmDd(j.day) === this.frameworkService.getDateFormatYyyyMmDd(i.day)).length != 0) &&
            !i.isPresenceNotCounted)
         allTrainingPlayers.forEach(i => {
            if (i.present == null)
               allOnGuardForTrainingPlayersOfMatch.push(i);
            else {
               if (i.present == 0)
                  allNotPresentForTrainingPlayersOfMatch.push(i);
            }
         });

         let totalMatch = this.matchDayCount;
         let notPresentOnMatch = allNotPresentForTrainingPlayersOfMatch.length;
         let onGuardOnMatch = allOnGuardForTrainingPlayersOfMatch.length;
         let presentOnMatch = totalMatch - notPresentOnMatch;
         let totalTraining = this.trainingDayCount;
         let notPresentOnTraining = allNotPresentForTrainingPlayersOfTraining.length;
         let onGuardOnTraining = allOnGuardForTrainingPlayersOfTraining.length;
         let presentOnTraining = totalTraining - notPresentOnTraining;

         //  if (allUsersOfTeamOfCurrentUser[cpt].sysId == 129) {
         //    this.frameworkService.logInfo(allUsersOfTeamOfCurrentUser[cpt].lastName + " " + allUsersOfTeamOfCurrentUser[cpt].firstName, "Player");
         //    this.frameworkService.logInfo(totalMatch, "totalMatch");
         //    this.frameworkService.logInfo(notPresentOnMatch, "notPresentOnMatch");
         //    this.frameworkService.logInfo(onGuardOnMatch, "onGuardOnMatch");
         //    this.frameworkService.logInfo(presentOnMatch, "presentOnMatch");
         //    this.frameworkService.logInfo(totalTraining, "totalTraining");
         //    this.frameworkService.logInfo(notPresentOnTraining, "notPresentOnTraining");
         //    this.frameworkService.logInfo(onGuardOnTraining, "onGuardOnTraining");
         //    this.frameworkService.logInfo(presentOnTraining, "presentOnTraining");
         //  }
         
         let percentPresentOnMatch = 0;
         let percentPresentOnTraining = 0;

         if (this.matchDayCount > 0)
            percentPresentOnMatch = Math.round((presentOnMatch / totalMatch) * 100);
         if (this.trainingDayCount > 0)
            percentPresentOnTraining = Math.round((presentOnTraining / totalTraining) * 100);

         let info = new infoPresenceOfUser();
         info.sysId = allUsersOfTeamOfCurrentUser[cpt].sysId;
         info.name = this.frameworkService.formatString("{0} {1}", [allUsersOfTeamOfCurrentUser[cpt].firstName, this.frameworkService.left(allUsersOfTeamOfCurrentUser[cpt].lastName, 1)]);

         info.percentOfPresenceOnTraining = new Array(percentPresentOnTraining, 0);
         info.percentOfPresenceOnMatch = new Array(percentPresentOnMatch, 0);
         info.countOfPresenceOnTraining = presentOnTraining;
         info.countOfPresenceOnMatch = presentOnMatch;

         if (cpt >= allUsersOfTeamOfCurrentUser.length / 2) {
            percentTraining2.push(info.percentOfPresenceOnTraining);
            percentMatch2.push(info.percentOfPresenceOnMatch);
            userList2.push(info.name + " " + Math.round(percentPresentOnTraining) + "/" + Math.round(percentPresentOnMatch));
         }
         else {
            percentTraining1.push(info.percentOfPresenceOnTraining);
            percentMatch1.push(info.percentOfPresenceOnMatch);
            userList1.push(info.name + " " + Math.round(percentPresentOnTraining) + "/" + Math.round(percentPresentOnMatch));
         }
         this.temp.push(info);

         this.playersPresentTotal += presentOnTraining + presentOnMatch;
         this.playersNotPresentTotal += notPresentOnTraining + notPresentOnMatch;
         this.playersOnGuardTotal += onGuardOnTraining + onGuardOnMatch;
      }

      this.barChartLabels1 = userList1;

      this.barChartData1 = [
         { data: percentTraining1, label: this.trainingDayCount + ' entrainements', backgroundColor: '#1e4fbb', hoverBackgroundColor: '#b0b1b0' },
         { data: percentMatch1, label: this.matchDayCount + ' matchs', backgroundColor: '#801ba8', hoverBackgroundColor: '#5e5e5e' }
      ];

      this.barChartLabels2 = userList2;

      this.barChartData2 = [
         { data: percentTraining2, label: this.trainingDayCount + ' entrainements', backgroundColor: '#1e4fbb', hoverBackgroundColor: '#b0b1b0' },
         { data: percentMatch2, label: this.matchDayCount + ' matchs', backgroundColor: '#801ba8', hoverBackgroundColor: '#5e5e5e' }
      ];
      this.allInfoPresenceOfUser = this.temp;

      this.presencePercent = (Number(this.playersPresentTotal) / (Number(this.playersPresentTotal + this.playersNotPresentTotal + this.playersOnGuardTotal))) * 100;
      this.guardPercent = (Number(this.playersOnGuardTotal) / (Number(this.playersPresentTotal + this.playersNotPresentTotal + this.playersOnGuardTotal))) * 100;
      this.notPresencePercent = (Number(this.playersNotPresentTotal) / (Number(this.playersPresentTotal + this.playersNotPresentTotal + this.playersOnGuardTotal))) * 100;

      this.pieChartLabels = [
         [this.frameworkService.formatString("{0}% présent", [this.presencePercent.toString()])],
         [this.frameworkService.formatString("{0}% garde", [this.guardPercent.toString()])],
         [this.frameworkService.formatString("{0}% absent", [this.notPresencePercent.toString()])]
      ];
      this.pieChartData = [this.presencePercent, this.guardPercent, this.notPresencePercent];
   }

  public ngOnInit() {
   }

  public onCancel(): void {
      this.dialogRef.close(false);
   }
}

export class TrainingPlayersStatisticsModel {
  constructor(public date: Date) {
   }
}