import { Component, HostListener, Input, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AlertShowService } from '../../../core/services/alertShow.service';
import { AlertShow, AlertShowType } from '../../models/alertshow/alertshow';

@Component({
   selector: 'app-alertShow',
   templateUrl: './alertshow.component.html',
   styleUrls: ['./alertshow.component.scss']
})

export class AlertShowComponent implements OnInit {
  @Input() public fade = true;
  @Input() public id = 'default-alert';

  public alertSubscription: Subscription;
  public alerts: AlertShow[] = [];
  public isDisplayOnAMobile: boolean;
  public routeSubscription: Subscription;

  constructor(private router: Router, private alertShowService: AlertShowService) {
      this.getScreenSize();
   }

  @HostListener('window:resize', ['$event'])
   public getScreenSize() {
      this.isDisplayOnAMobile = (window.innerWidth <= 960);
   }

  public cssClass(alert: AlertShow) {
      if (!alert) return;

      const classes = ['alert', 'alert-dismissable', 'container'];

      const alertTypeClass = {
         [AlertShowType.Success]: 'alert alert-fill-success',
         [AlertShowType.Error]: 'alert alert-fill-danger',
         [AlertShowType.Info]: 'alert alert-fill-info',
         [AlertShowType.Warning]: 'alert alert-fill-warning'
      }

      classes.push(alertTypeClass[alert.type]);

      if (alert.fade) {
         classes.push('fade');
      }

      return classes.join(' ');
   }

  public ngOnDestroy() {
      // unsubscribe to avoid memory leaks
      this.alertSubscription.unsubscribe();
      this.routeSubscription.unsubscribe();
   }

  public ngOnInit() {
      // subscribe to new alert notifications
      this.alertSubscription = this.alertShowService.onAlert(this.id)
         .subscribe(alert => {
            // clear alerts when an empty alert is received
            if (!alert.message) {
               // filter out alerts without 'keepAfterRouteChange' flag
               this.alerts = this.alerts.filter(x => x.keepAfterRouteChange);

               // remove 'keepAfterRouteChange' flag on the rest
               this.alerts.forEach(x => delete x.keepAfterRouteChange);
               return;
            }

            // add alert to array
            this.alerts.push(alert);

            // auto close alert if required
            if (alert.autoClose) {
               setTimeout(() => this.removeAlert(alert), 3000);
            }
         });

      // clear alerts on location change
      this.routeSubscription = this.router.events.subscribe(event => {
         if (event instanceof NavigationStart) {
            this.alertShowService.clear(this.id);
         }
      });
   }

  public removeAlert(alert: AlertShow) {
      // check if already removed to prevent error on auto close
      if (!this.alerts.includes(alert)) return;

      if (this.fade) {
         // fade out alert
         this.alerts.find(x => x === alert).fade = true;

         // remove alert after faded out
         setTimeout(() => {
            this.alerts = this.alerts.filter(x => x !== alert);
         }, 250);
      } else {
         // remove alert
         this.alerts = this.alerts.filter(x => x !== alert);
      }
   }
}