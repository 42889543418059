<mat-card class="mat-card-color popupDimensionTrainingPlayerStatitics">
    <mat-card-header class="mat-card-header-color">
        <mat-card-title>
            <div>{{title}}</div>
            <div class="flexSpaceBetween">
                <div>
                    {{subTitle}}
                    <br/>
                    {{subTitle2}}
                </div>
                <div class="paddingRight">
                    <app-button icon="close" [class]="'buttonNormalNo'" [text]="'Close' | translate"
                    [isBackgroundOpaque]="true" (onClick)="onCancel()"></app-button>
                </div>
            </div>
        </mat-card-title>
        <mat-card-subtitle class="changeWidth">
            <div class="flexSpaceBetween">
                <div>
                    <span class="present legendAvailability">
                        <div class="legendCenter">{{presencePercent | number:'1.1-2':'fr'}}</div>
                    </span>
                    <span class="guard legendAvailability">
                        <div class="legendCenter">{{guardPercent | number:'1.1-2':'fr'}}</div>
                    </span>
                    <span class="notPresent legendAvailability">
                        <div class="legendCenter">{{notPresencePercent | number:'1.1-2':'fr'}}</div>
                    </span>
                    <span class="verticalCenter">
                        % de l'équipe
                    </span>
                </div>

            </div>
            <div class="chart-global-wrapper">
                <canvas baseChart [colors]="chartColors" [data]="pieChartData" [chartType]="pieChartType"
                    [options]="pieChartOptions" [plugins]="pieChartPlugins">
                </canvas>
            </div>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="mat-card-content-chart">
        <div class="chart">
            <canvas baseChart [datasets]="barChartData1" [labels]="barChartLabels1" [options]="barChartOptions"
                [legend]="barChartLegend" [chartType]="barChartType">
            </canvas>

            <canvas class="chart" baseChart [datasets]="barChartData2" [labels]="barChartLabels2"
                [options]="barChartOptions" [legend]="barChartLegend" [chartType]="barChartType">
            </canvas>
        </div>
    </mat-card-content>
    <mat-card-header>
        <mat-card-title>
            <div>
                <span class="present legendAvailability">
                    <div class="legendCenter">{{playersPresentTotal}}</div>
                </span>
                <span class="guard legendAvailability">
                    <div class="legendCenter">{{playersOnGuardTotal}}</div>
                </span>
                <span class="notPresent legendAvailability">
                    <div class="legendCenter">{{playersNotPresentTotal}}</div>
                </span>
                <span class="verticalCenter smallFont">Total de présences : </span>
                <span class="legendAvailability">
                    <div class="legendCenter smallFont">{{playersPresentTotal + playersOnGuardTotal + playersNotPresentTotal}}
                    </div>
                </span>
            </div>
        </mat-card-title>
    </mat-card-header>
</mat-card>