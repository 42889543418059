//This file is autogenerated by C# GeneratedModels

import { auditFields } from "src/sites/core/models/system/auditFields";
import { metadataModelBase } from "src/sites/core/models/system/metadataModelBase";
import { enumFormValidator } from "../../core/enum/Enum";
import { championship } from "./championship";
import { blob } from "./blob";

@Reflect.metadata('comiteeMeetingBase', 'comiteeMeetingBase')
export class comiteeMeetingBase extends auditFields {

 @Reflect.metadata('date',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    } 
  })
  date? : Date = null;

 @Reflect.metadata('pointsToView',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    } 
  })
  pointsToView : string = "";

 @Reflect.metadata('reportOfMeeting',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    } 
  })
  reportOfMeeting : string = "";

 @Reflect.metadata('isApproved',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    } 
  })
  isApproved : boolean = false;

 @Reflect.metadata('presentMember',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    } 
  })
  presentMember : string = "";

 @Reflect.metadata('location',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    } 
  })
  location : string = "";

 @Reflect.metadata('championshipSysId',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    } 
  })
  championshipSysId : number = 0;

 @Reflect.metadata('championship',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    } 
  })
  championship : championship = new championship();

 @Reflect.metadata('externalDocumentSysId',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    } 
  })
  externalDocumentSysId? : number = null;
}