<div>
   <div *ngIf="sportclub == null">
      <mat-card class="mat-card-center-95P">
         <mat-card-header class="mat-card-header-color">
            <mat-card-title>
               <span class="material-icons matcard-title-icon"
                  style="margin-left:auto">phone</span>{{titleDescriptionDisplay}}
            </mat-card-title>
         </mat-card-header>
         <mat-card-content>
            (Données indisponibles)
         </mat-card-content>
      </mat-card>
   </div>
   <div *ngIf="sportclub != null">
      <mat-card class="mat-card-center-95P">
         <mat-card-header class="mat-card-header-color">
            <mat-card-title>
               <span class="material-icons matcard-title-icon"
                  style="margin-left:auto">phone</span>{{titleDescriptionDisplay}}
            </mat-card-title>
         </mat-card-header>
         <mat-card-content>

            <div class="matcard matcardFloat">
               <app-panel [widthPanel]="'400px'" [isBodyContentCenter]="true">
                  <span panel-title>{{sportclub.buildingName}}</span>
                  <div panel-content>
                     <span>
                        <app-buttonhref [isBackgroundOpaque]="true" [icon]="'place'"
                           [text]="sportclub.socialAddress + '. ' + sportclub.socialZipCode + ' ' + sportclub.socialLocality"
                           [type]="EnumHRefType.GoogleLocation" [href]="sportclub.address + ' ' + sportclub.zipCode + ' ' + sportclub.locality + '&sll=' + sportclub.lat + ',' + sportclub.long"
                           alt="Address"></app-buttonhref>
                     </span>
                     <app-buttonhref [isBackgroundOpaque]="true" [icon]="'phone'"
                        [text]="getPhoneFormat(sportclub.phone)" [type]="EnumHRefType.Phone" [href]="sportclub.phone"
                        alt="Phone"></app-buttonhref>
                  </div>
               </app-panel>
            </div>

            <div class="matcard matcardFloat">
               <ng-container *ngFor="let team of allTeamsFiltered">
                  <app-panel [widthPanel]="'400px'" [isBodyContentCenter]="true">
                     <span panel-title>{{team.teamDisplay}}</span>
                     <div panel-content>
                        <div *ngIf="getTeamContactOfTeam(team.sysId) == null">
                           (Non communiqué)
                        </div>
                        <div *ngIf="getTeamContactOfTeam(team.sysId) != null">
                           {{getTeamContactOfTeam(team.sysId)?.firstName}}&nbsp;{{getTeamContactOfTeam(team.sysId)?.lastName}}
                        </div>
                        <div class="rowDisplay">
                           <app-buttonhref
                              *ngIf="getTeamContactOfTeam(team.sysId)?.phone1PrivacyLevel == EnumPrivacyLevel.Public"
                              [isBackgroundOpaque]="true" [icon]="'phone'"
                              [text]="getPhoneFormat(getTeamContactOfTeam(team.sysId)?.phone1)"
                              [type]="EnumHRefType.Phone" [href]="getTeamContactOfTeam(team.sysId).phone1"
                              alt="Phone"></app-buttonhref>
                           <app-buttonhref
                              *ngIf="getTeamContactOfTeam(team.sysId)?.emailPrivacyLevel == EnumPrivacyLevel.Public"
                              [isBackgroundOpaque]="true" [icon]="'email'"
                              [text]="getTeamContactOfTeam(team.sysId)?.email" [type]="EnumHRefType.Email"
                              [href]="getTeamContactOfTeam(team.sysId)?.email" alt="Mailto"></app-buttonhref>
                        </div>
                     </div>
                  </app-panel>
               </ng-container>
            </div>

            <div class="matcard matcardFloat">
               <ng-container *ngFor="let role of allRolesOfMembers">
                  <div *ngIf="getAllUsersOfRole(role.name)?.length > 0">
                     <app-panel [widthPanel]="'400px'" [isBodyContentCenter]="true"
                        *ngFor="let u of getAllUsersOfRole(role.name)">
                        <span panel-title>{{role.name}}</span>
                        <div panel-content>
                           <ng-container>
                              <div>{{u.firstName}}&nbsp;{{u.lastName}}</div>
                           </ng-container>
                           <div class="rowDisplay">
                              <app-buttonhref *ngIf="u.phone1PrivacyLevel == EnumPrivacyLevel.Public"
                                 [isBackgroundOpaque]="true" [icon]="'phone'" [text]="getPhoneFormat(u.phone1)"
                                 [type]="EnumHRefType.Phone" [href]="u.phone1" alt="Phone"></app-buttonhref>
                              <app-buttonhref *ngIf="u.emailPrivacyLevel == EnumPrivacyLevel.Public"
                                 [isBackgroundOpaque]="true" [icon]="'email'" [text]="u.email"
                                 [type]="EnumHRefType.Email" [href]="u.email" alt="Mailto"></app-buttonhref>
                           </div>
                        </div>
                     </app-panel>
                  </div>
               </ng-container>
            </div>
         </mat-card-content>
      </mat-card>
   </div>
</div>