//This file is autogenerated by C# GeneratedModels

import { jsonIgnore } from "json-ignore";
import { auditFields } from "src/sites/core/models/system/auditFields";
import { metadataModelBase } from "src/sites/core/models/system/metadataModelBase";
import { enumControlType, enumWorkgroup } from "../../core/enum/Enum";
import { championship } from "./championship";
import { team } from "./team";

@Reflect.metadata('buildingTrainingClosingDayBase', 'buildingTrainingClosingDayBase')
export class buildingTrainingClosingDayBase extends auditFields {

   @Reflect.metadata('day',
      <metadataModelBase><unknown>{
         editControlType: enumControlType.DateOnly,
         editVariableValidator: {
         }
      })
   day?: Date = null;

   @Reflect.metadata('reason',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "300"
         }
      })
   reason: string = "";

   @Reflect.metadata('teamSysId',
      <metadataModelBase><unknown>{
         editControlType: enumControlType.Dropdown,
         editDropdownEntityMapping: "team",
         editDropdownPropertiesForDisplay: "teamCategory",
         editDropdownFormatForDisplay: "{0}"
      })
   teamSysId?: number = null;

   @Reflect.metadata('team',
      <metadataModelBase><unknown>{
         editColumnIsVisible: enumWorkgroup[enumWorkgroup.None],
         gridColumnIsVisible: enumWorkgroup[enumWorkgroup.None]
      })
   @jsonIgnore()
   team: team;

   @Reflect.metadata('championshipSysId',
      <metadataModelBase><unknown>{
         editControlType: enumControlType.Dropdown,
         editDropdownEntityMapping: "championship",
         editDropdownPropertiesForDisplay: "yearStarting;yearEnding",
         editDropdownFormatForDisplay: "{0}-{1}"
      })
   championshipSysId: number = 0;

   @Reflect.metadata('championship',
      <metadataModelBase><unknown>{
         editColumnIsVisible: enumWorkgroup[enumWorkgroup.None],
         gridColumnIsVisible: enumWorkgroup[enumWorkgroup.None],
         grisIsInDetailExpanded: true
      })
   @jsonIgnore()
   championship: championship;
}