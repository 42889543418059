//This file is autogenerated by C# GeneratedServices/Angular/ServicesAngularGenerationTool

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs';
import { AccessRightsService } from 'src/sites/core/services/accessRights.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { LogService } from 'src/sites/core/services/log.service';
import { AppConfigService } from 'src/sites/core/services/appconfig.service';
import { BuildingTrainingClosingDayBaseService } from './buildingTrainingClosingDayBase.service';
import { StorageService } from '../authentication/storage.service';
import { EncryptionService } from 'src/sites/core/services/encryption.service';

@Injectable()
export class BuildingTrainingClosingDayService extends BuildingTrainingClosingDayBaseService {
   private _logger: LogService;
   private _appConfigService: AppConfigService;
   private _httpClient: HttpClient;
   private _frameworkService: FrameworkService;
   private _accessRightsService: AccessRightsService;
   private _encryptionService: EncryptionService;
   private _tokenStorageService: StorageService;

   username = "UNKNOWN";

   constructor(
      appConfigService : AppConfigService,
      logger: LogService,
      httpClient: HttpClient,
      frameworkService: FrameworkService,
      accessRightsService: AccessRightsService,
      encryptionService : EncryptionService,
      storageService: StorageService
   ) {
      super(appConfigService, logger, httpClient, frameworkService, accessRightsService, encryptionService, storageService);
      this._appConfigService = appConfigService;
      this._logger = logger;
      this._httpClient = httpClient;
      this._frameworkService = frameworkService;
      this._accessRightsService = accessRightsService;
      this._encryptionService = encryptionService;
      this._tokenStorageService = storageService;
      this.username = !this._accessRightsService.isUserConnected ? "UNKNOWN" : this._tokenStorageService.getUserConnected().username;
   }
}