import { enumCRUDType, enumFormAction } from "../../enum/Enum";

export class ModalFormParam {
    public cRUDType : enumCRUDType;
    public action : enumFormAction;
    public columnsList : any[];
    public formTitle : string;
    public formIcon : string;
    public entity : any;
    public urlApi : string;
    public element : any;

    public constructor(init?:Partial<ModalFormParam>) {
      Object.assign(this, init);
    }
}