import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import 'linq-typed';
import { AccessRightsService } from 'src/sites/core/services/accessRights.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { match } from '../../models/match';
import { trainingPlayer } from '../../models/trainingPlayer';
import { CacheService } from '../../services/cache.service';
import { StorageService } from '../../authentication/storage.service';
import { SubjectService } from 'src/sites/core/services/subject.service';

@Component({
   selector: 'app-match',
   templateUrl: './match.component.html',
   styleUrls: ['./match.component.scss'],
   encapsulation: ViewEncapsulation.None,
   providers: [DatePipe]
})

export class MatchComponent implements OnInit {
  public allMatchs: match[] = [];
  public allTrainingDay: any[] = [];
  public allTrainingPlayersOfCS: trainingPlayer[] = [];
  public isDisplayOnAMobile: boolean = false;
  public titleDescriptionDisplay: string = "";

  constructor(
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private frameworkService: FrameworkService,
      private translateService: TranslateService,
      public accessRightsService: AccessRightsService,
      public storageService: StorageService,
   ) {
      this.getScreenSize();
   }

  @HostListener('window:resize', ['$event'])
   public getScreenSize() {
      this.isDisplayOnAMobile = (window.innerWidth <= 960);
   }

  public getDayNumberOfDate(value: Date): string {
      return this.frameworkService.getDayNumberOfDate(value);
   }

  public getDayOfWeek(value: string) {
      return this.frameworkService.getDayOfWeekFromString(value);
   }

  public getExtraTrainingOfCoachFromDate(date: Date): trainingPlayer {
      return this.allTrainingPlayersOfCS.FirstOrDefault(i =>
         this.frameworkService.getDateFormatYyyyMmDd(i.day) == this.frameworkService.getDateFormatYyyyMmDd(date) &&
         i.type != "" && i.type != null && i.playerSysId == this.frameworkService.getSysIdOfUser(this.cacheService.getCoachOfCurrentLogged()) &&
         this.frameworkService.getMatchTrainingActivitiesType().FirstOrDefault(j => j.value === i.type));
   }

  public getMonthStringOfDate(value: Date): string {
      return this.frameworkService.getMonthStringOfDate(value);
   }

  public getYearOfDate(value: Date): string {
      return this.frameworkService.getYearOfDate(value);
   }

  public initialize() {
      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });

      this.refresh();
   }

  public ngOnInit() {
   }

  public refresh() {
      this.allMatchs = this.cacheService.getAllMatchsOfUserTeamAndClub()
      // Take all training of current championship and of user and coach   
      this.allTrainingPlayersOfCS = this.cacheService.getAllTrainingPlayers().filter(i => (i.playerSysId == this.storageService.getUserConnected().sysId || i.playerSysId == this.frameworkService.getSysIdOfUser(this.cacheService.getCoachOfCurrentLogged())));
   }

  public translatePage() {
   }
}