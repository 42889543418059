//This file is autogenerated by C# GeneratedModels

import { auditFields } from "src/sites/core/models/system/auditFields";
import { metadataModelBase } from "src/sites/core/models/system/metadataModelBase";
import { enumControlType, enumWorkgroup } from "../../core/enum/Enum";
import { team } from "./team";

@Reflect.metadata('championshipBase', 'championshipBase')
export class championshipBase extends auditFields {

 @Reflect.metadata('isCurrent',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    },
    editControlType: enumControlType.Bool2States,
    editColumnIsVisible:  enumWorkgroup[enumWorkgroup.None]
  })
  isCurrent : boolean = false;

 @Reflect.metadata('yearStarting',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    "MaxLength": "4"
    } 
  })
  yearStarting : string = "";

 @Reflect.metadata('yearEnding',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    "MaxLength": "4"
    } 
  })
  yearEnding : string = "";

 @Reflect.metadata('teams',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    } 
  })
  teams : team[];


}