import { enumPrivacyLevel, enumWorkgroup } from "../../enum/Enum";
import { auditFields } from "../system/auditFields";

export class userDTO extends auditFields {

   constructor(
   
   public sysIsActive: boolean,
   
   public username: string,
   public firstName: string,
   public lastName: string,
   public gender: boolean,
   public birthdayDate: Date,
   public nationalNumber: string,
   
   public nationality: string,
   public address: string,
   public zipCode: string,
   public locality: string,

   public email: string,
   public phone1: string,
   public phone2: string,

   public category: string,
   public licenceType: string,
   public licenceNumber: string,

   public teamCategory: string,
   public team2Category: string,

   public jacketNumber: number,
   public jacketSize: string,
   public shortsSize: string,

   public roleInComity: string,
   public roleInClub: string,
   public roleInClubCustom: string,
   public roleInTeam: string,

   public contribution : number,
   public isContributionPayed : boolean,

   public workgroupSysId: number,

   public isUserLoginInactive: boolean,
   public isNotificationByEmailActivate: boolean,
   public isNotificationByPushActivate: boolean,
   public isNotificationActivityReportByEmailActivate: boolean,
   public isNotificationMatchByEmailActivate: boolean,
   public isNotificationMatchOfClubByEmailActivate: boolean,
   public isNotificationComiteeMeetingByEmailActivate: boolean,
   public isNotificationAvailabilityByEmailActivate: boolean,
   public isNotificationAvailabilityReportByEmailActivate: boolean,
   public isNotificationAvailabilityForTeamByEmailActivate: string,
   public emailPrivacyLevel: number,
   public addressPrivacyLevel: number,
   public phone1PrivacyLevel: number,
   public phone2PrivacyLevel: number,
   public birthdayDatePrivacyLevel: number
) {
   super()
}
}