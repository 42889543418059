import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { SubjectService } from 'src/sites/core/services/subject.service';
import { strings } from 'src/sites/vabourlettis/models/strings';
import { CacheService } from 'src/sites/vabourlettis/services/cache.service';
import { VersionningService } from 'src/sites/vabourlettis/services/versionningService';

@Component({
   selector: 'app-versionDialog',
   templateUrl: './versionDialog.component.html',
   styleUrls: ['./versionDialog.component.scss'],
   encapsulation: ViewEncapsulation.None
})
export class VersionDialogComponent implements OnInit {
   public allHistoryVersions: strings[] | undefined;
   public allVersions: strings[] | undefined;
   public isNetworkStatusOnline: boolean = false;
   public title: string;

   constructor(public dialogRef: MatDialogRef<VersionDialogComponent>,
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private frameworkService: FrameworkService,
      private versionningService: VersionningService,
      @Inject(MAT_DIALOG_DATA) public data: VersionDialogModel) {
      this.subjectService.IsNetworkStatusOnlineSubject.subscribe(value => { this.isNetworkStatusOnline = value; });
      this.title = data.title;
   }

   public getFormattedVersion(version: strings) {
      return version.value.replace("#SITE", "").replace("#", "").replace(",", " - ");
   }

   public isSeparator(version: strings) {
      return version.value.startsWith("#");
   }

   public isSeparatorLi(version: strings) {
      return version.value.startsWith("*");
   }

   public ngOnInit() {
      this.allVersions = this.cacheService.getAllVersions();
      this.versionningService.getAllHistoryVersions().subscribe(i => this.allHistoryVersions = i.toString().replace(/\r\n/g, '\n').split('\n').map((x) => {
         return { value: x }
      }))
   }

   public onClose(): void {
      this.dialogRef.close({ result: false });
      this.subjectService.IsButtonInLoadingSubject.next(false);
   }
}

export class VersionDialogModel {
   constructor(public title: string) {
   }
}