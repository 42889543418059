//This file is autogenerated by C# GeneratedServices/Angular/ServicesAngularGenerationTool

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, retry, throwError } from 'rxjs';
import { AppConfigService } from 'src/sites/core/services/appconfig.service';
import { authToken } from './authToken';
import { encryptedValue } from './encryptedValue';
import { StorageService } from './storage.service';
import { AccessRightsService } from 'src/sites/core/services/accessRights.service';

@Injectable()
export class AuthenticationService {
   public username = "UNKNOWN";

   constructor(
      private appConfigService: AppConfigService,
      private httpClient: HttpClient,
      private accessRightsService: AccessRightsService,
      private storageService: StorageService
   ) {
      this.httpClient = httpClient;
      this.storageService = storageService;
      this.username = !this.accessRightsService.isUserConnected ? "UNKNOWN" : this.storageService.getUserConnected().username;
   }

   // Error handling
   public handleError(error) {

      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
      } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      return throwError(() => errorMessage);
   }

   public refreshToken(authToken: authToken) {
      let url = "/Q200400001";
      return this.httpClient.post<encryptedValue>(this.appConfigService.getApiUrl() + 'api/C2004' + url, JSON.stringify(authToken))
         .pipe(retry(1), catchError(this.handleError));
   }
}
