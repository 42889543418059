<div>
   <form [formGroup]="form">
      <mat-card class="mat-card-center-95P">
         <mat-card-header class="mat-card-header-color">
            <mat-card-title>
               <span class="material-icons matcard-title-icon" style="margin-left:auto">sports_volleyball</span>Gestion
               des joueurs
            </mat-card-title>
         </mat-card-header>

         <mat-card-content>
            <div>
               Veuillez sélectionner les joueurs :
               <mat-button-toggle-group class="buttonToggle" [(value)]="selectedMemberIds"
                  #toggleBtn="matButtonToggleGroup" vertical multiple>
                  <mat-button-toggle [value]="member.sysId" *ngFor="let member of getAllMembers()">{{member.lastName}}
                     {{member.firstName}}
                  </mat-button-toggle>
               </mat-button-toggle-group>
            </div>

            <div>
               Veuillez sélectionner l'équipe :
               <mat-select formControlName="teamSelected" class="matSelect">
                  <mat-option *ngFor="let team of getAllTeams()" [value]="team">
                     <ng-container>{{team.teamDisplay}}</ng-container>
                  </mat-option>
               </mat-select>
               <app-button icon="trending_flat" [class]="'spanButtonSeparator'" [text]="'Assigner'"
                  [isBackgroundOpaque]="true" (onClick)="onMovePlayer_click()"></app-button>
            </div>
         </mat-card-content>

      </mat-card>
   </form>
</div>