import { Component, Input, OnInit, SecurityContext } from "@angular/core";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { SubjectService } from "src/sites/core/services/subject.service";

@Component({
   selector: 'app-panel',
   templateUrl: './panel.component.html',
   styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnInit {
   @Input() public globalClass: string = "";
   @Input() public headerClass: string = "";
   @Input() public backgroundImage: string = "";
   @Input() public urlImage: SafeStyle = null;
   @Input() public isImportant: boolean = false;
   @Input() public isBodyContentCenter: boolean = false;
   @Input() public widthPanel: string;
   @Input() public height: string;

   public internalGlobalClass: string = "";
   public internalTitleClass: string = "";
   public internalHeaderClass: string = "";
   public internalWidth: string = "100%";
   public internalHeight: string = "100%";
   public isDisplayOnAMobile = false;

   constructor(private sanitizer: DomSanitizer, 
      private subjectService: SubjectService) {
      this.subjectService.IsWindowResizingSubject.subscribe(value => { this.isDisplayOnAMobile = value; this.resetWidth(); this.resetHeight(); });
   }

   ngOnInit(): void {
      this.internalGlobalClass = this.globalClass + " panel ";
      this.internalTitleClass = "title" + (this.isImportant ? " important" : "");
      this.internalHeaderClass = "panel-header" + (this.headerClass == "" ? " panel-header-color" : " " + this.headerClass);
      this.resetWidth();
      this.resetHeight();
   }

   resetWidth() {
      if (this.isDisplayOnAMobile)
         this.internalWidth = "100%";
      else
         this.internalWidth = (this.widthPanel != "") ? this.widthPanel : "100%";
   }

   resetHeight() {
      if (this.isDisplayOnAMobile)
         this.internalHeight = "fit-content";
      else
         this.internalHeight = (this.height != "") ? this.height : "auto";
   }

   getContentClass() {
      if (this.isBodyContentCenter)
         return "panel-content textAlignCenter";
      else
         return "panel-content ";
   }

   isUrlImageEmpty() {
      if (this.urlImage != null && !this.sanitizer.sanitize(SecurityContext.STYLE, this.urlImage).includes("url()"))
         return false;
      else
         return true;
   }
}
